import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {BotData} from '../../../api/types';
import {webApi} from '../../../api/webApi';
import {AppContext} from '../../../App';
import styles from './TileWide.module.css';
import {LikeBtn} from '../LikeBtn/LikeBtn';
import {rateImages} from '../Tile';
import cn from 'classnames';

type Props = {
	bot: BotData;
	rate: number;
	onClick?: () => void;
};

export const TileWide = ({bot, rate, onClick}: Props) => {
	const navigate = useNavigate();
	const handleTileClick = () => {
		onClick?.();
		navigate(`/bot_${bot.id}/chat`);
	};

	const [isLiked, setIsLiked] = useState(bot.attributes.userLiked);
	const [likesCount, setLikesCount] = useState(bot.attributes.likesCount);

	const {user, setIsLoginOpened} = useContext(AppContext);

	if (rate === undefined) {
		return null;
	}

	const handleLikeClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		if (!user || user.isAnon) {
			setIsLoginOpened(true);
			return;
		}
		if (isLiked) {
			webApi.unlikeBot(bot.id);
			setLikesCount(Math.max(likesCount - 1, 0));
		} else {
			webApi.likeBot(bot.id);
			setLikesCount(likesCount + 1);
		}
		setIsLiked(!isLiked);
	};

	return (
		<div className={styles.tile} onClick={handleTileClick}>
			<div className={cn('bot-tile__avatar', styles.avatar)}>
				<img
					src={bot.attributes.avatarUrl}
					alt={`${bot.attributes.name} - 160 - ${bot.attributes.createdAt}`}
					className={styles.image}
					loading="lazy"
					title={bot.attributes.name}
				/>
				{rateImages[rate] && (
					<img className={styles.rate} src={rateImages[rate]} alt="rate" />
				)}
			</div>
			<div className={styles.texts}>
				<p className={styles.name}>{bot.attributes.name}</p>
				<p className={styles.description}>{bot.attributes.bio || ''}</p>
			</div>

			<LikeBtn
				isLiked={isLiked}
				count={likesCount}
				onClick={handleLikeClick}
				className={styles.likeBtn}
			/>
		</div>
	);
};
