import {Modal} from '@mui/material';
import {InButton} from '../../../InButton/InButton';
import {TextArea} from '../../../TextArea/TextArea';
import {useEffect, useState} from 'react';
import cn from 'classnames';
import './FeedbackModal.css';
import {CloseModalBtn} from '../../../CloseModalBtn/CloseModalBtn';

type Props = {
	open: boolean;
	onClose: () => void;
	onSubmit: (data: {text: string; email: string}) => void;
	onCloseByUser: () => void;
};

const isValidEmail = (email: string) => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

export const DislikeFeebackModal = ({
	open,
	onClose,
	onSubmit,
	onCloseByUser,
}: Props) => {
	const [feedbackText, setFeedbackText] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [email, setEmail] = useState('');
	useEffect(() => {
		if (open) {
			setFeedbackText('');
			setEmail('');
		}
	}, [open]);

	const handleSubmit = () => {
		if (email && !isValidEmail(email)) {
			setIsEmailValid(false);
			return;
		}
		onSubmit({text: feedbackText, email});
		onClose();
	};

	useEffect(() => {
		if (!isEmailValid) {
			setIsEmailValid(true);
		}
	}, [email]);

	const handleCloseClick = () => {
		onCloseByUser();
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose} className="dislike-feedback-modal">
			<div className="dislike-feedback-modal__inner">
				<h2 className="dislike-feedback-modal__title">Write feedback</h2>
				<TextArea
					value={email}
					onChange={setEmail}
					placeholder="Your email"
					maxLength={100}
					className={cn('dislike-feedback-modal__textarea', {
						'dislike-feedback-modal__textarea_error': !isEmailValid,
					})}
				/>
				<TextArea
					value={feedbackText}
					onChange={setFeedbackText}
					placeholder="Your feedback here"
					maxLength={10000}
					className={cn(
						'dislike-feedback-modal__textarea',
						'dislike-feedback-modal__textarea_feedback'
					)}
				/>
				<InButton
					id="submit-feedback"
					isFilled
					onClick={handleSubmit}
					className="dislike-feedback-modal__button"
					isDisabled={!feedbackText || !isEmailValid}
				>
					Send
				</InButton>
				<CloseModalBtn onClose={handleCloseClick} />
			</div>
		</Modal>
	);
};
