import {Product} from '../../../api/types';
import {InButton} from '../../../common-lib/src/components/InButton/InButton';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import {PaywallModalContainer} from '../PWContainer';
import {PWList} from '../PWList';
import {convertPrice} from '../utils';
import styles from './UltraPaywall.module.css';
import cn from 'classnames';
import ultraImg from '../images/ultra.png';
import {useEffect} from 'react';
import {webEventsApi} from '../../../amplitude/webEvents';

type Props = {
	openned: boolean;
	onClose: () => void;
	onSuccess: (selectedPlan: Product) => void;
	loading: boolean;
	loadingProducts: boolean;
	products: Product[];
};

export const UltraPaywall = ({
	openned,
	onClose,
	onSuccess,
	loading,
	products,
	loadingProducts,
}: Props) => {
	const ultraProduct = products[0];

	useEffect(() => {
		if (openned && ultraProduct) {
			webEventsApi.paywallProductSelected({
				product_id: ultraProduct.id,
				product_name: ultraProduct.name,
			});
		}
	}, [openned, ultraProduct]);

	return (
		<PaywallModalContainer
			openned={openned}
			onClose={onClose}
			className={styles.modal}
		>
			<div className={cn('paywall-modal__header', styles.header)}>
				<h2 className="paywall-modal__header-h2">Get</h2>
				<img src={ultraImg} alt="Ultra" className={styles.ultra} />
			</div>
			<PWList
				items={[
					{img: '💖', text: 'Role-playing'},
					{img: '🧠', text: 'Tailor-made AI models'},
					{img: '🖼️', text: 'Photos'},
					{img: '🎙️', text: 'Voices'},
					{img: '💫', text: 'Unlimited messages'},
					{img: '🔒', text: 'Private bots'},
					{img: '🤩', text: 'Exclusive new features'},
				]}
				isUltraSelected
			/>
			{loadingProducts ? (
				<Spinner withLayout={false} />
			) : (
				<div className={styles.priceBlock}>
					<p className={styles.price}>
						${convertPrice(ultraProduct.price)}
						<span className={styles.pricespan}>per year</span>
					</p>
					<p className={styles.subprice}>Auto-renews, cancel at any time</p>
				</div>
			)}
			<InButton
				isFilled
				id="continue-paywall-modal"
				onClick={loading ? () => {} : () => onSuccess(ultraProduct)}
				className={cn('paywall-modal__btn', styles.btn, {
					'paywall-modal__btn-loading': loading,
				})}
			>
				{loading ? <>Loading</> : <>Continue</>}
			</InButton>
		</PaywallModalContainer>
	);
};
