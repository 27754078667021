import styles from './LikeBtn.module.css';
import cn from 'classnames';
import likeImg from './images/like.svg';
import likedImg from './images/liked.svg';

type Props = {
	isLiked: boolean;
	onClick: (e: React.MouseEvent) => void;
	count: number;
	className?: string;
};

export const LikeBtn = ({isLiked, onClick, count, className}: Props) => {
	return (
		<div
			className={cn(styles.likeBtn, {[styles.liked]: isLiked}, className)}
			onClick={onClick}
		>
			<img
				className={styles.likeImg}
				src={isLiked ? likedImg : likeImg}
				alt="like"
			/>
			<span className={styles.count}>{count}</span>
		</div>
	);
};
