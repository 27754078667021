import React from 'react';
import {Modal} from '@mui/material';
import {
	CloseModalBtn,
	InButton,
	Spinner,
} from '../../../../common-lib/src/components';
import {EditTextField} from '../../../CreateBot/CreateBot';
import styles from './ChatPersonaModal.module.css';
import {NamePronoun} from '../../../CreateBot/NamePronoun/NamePronoun';
import personaImg from './images/persona.svg';
import {useChatPersona} from './useChatPersona';
import {AvatarSelector} from '../../../CreateBot/AvatarSelect/AvatarSelector';
import {AiAvatar} from '../../../CreateBot/AiAvatar/AiAvatar';
import {UserPronoun, UserInfo} from '../../../../api/usersApi/usersApi.types';

export type ChatPersonaProps = {
	open: boolean;
	onClose: () => void;
	onSubmit: (data: {
		name: string;
		pronoun: UserPronoun;
		personality: string;
		file: File | null;
	}) => Promise<void>;
	data: Pick<
		UserInfo,
		'personaName' | 'personaPronouns' | 'personaDescription'
	>;
	showPairTooltip: boolean;
};

export const ChatPersonaAddButton: React.FC<{
	onSubmit: () => void;
	disabled: boolean;
}> = ({onSubmit, disabled}) => {
	return (
		<InButton
			id="add-chat-persona"
			isFilled
			onClick={onSubmit}
			className={styles.addButton}
			isDisabled={disabled}
		>
			Save
		</InButton>
	);
};

export const ChatPersonaHeaderBlock = () => {
	return (
		<div className={styles.header}>
			<img src={personaImg} alt="Chat Persona" className={styles.icon} />
			<span className={styles.title}>
				Bots will remember your persona to improve conversations. The
				information provided is private and will not be visible to other users.
			</span>
		</div>
	);
};

export const PairTooltip = () => {
	return (
		<div className={styles.pairTooltip}>
			<p>Upload selfie to get pair photos</p>
		</div>
	);
};

export const ChatPersonaModal: React.FC<ChatPersonaProps> = (props) => {
	const {open, onClose} = props;
	const {
		name,
		pronoun,
		personality,
		handlePronounChange,
		handleSubmit,
		setName,
		setPersonality,
		convertUserPronounToPronoun,
		disabled,
		isAIPopupOpen,
		setIsAIPopupOpen,
		handleImageChange,
		image,
		showPairTooltip,
		isLoading,
	} = useChatPersona(props);

	return (
		<Modal open={open} onClose={onClose} className={styles.modal}>
			<div className={styles.modalContent}>
				{isLoading && <Spinner withLayout />}
				<h3 className={styles.modalTitle}>Chat persona</h3>
				<ChatPersonaHeaderBlock />
				<div className={styles.avatarContainer}>
					<AvatarSelector
						showError={false}
						onGenerateClick={() => setIsAIPopupOpen(true)}
						value={image}
						onChange={handleImageChange}
						loading={false}
						avatarData={null}
						className={styles.avatarSelector}
					/>
					{showPairTooltip && <PairTooltip />}
				</div>
				<div className={styles.namePronoun}>
					<span className={styles.namePronounTitle}>Name* & Pronoun</span>
					<NamePronoun
						name={name}
						title="Name"
						onChangeName={setName}
						pronoun={convertUserPronounToPronoun(pronoun)}
						onChangePronoun={handlePronounChange}
					/>
				</div>

				<EditTextField
					value={personality}
					onChange={setPersonality}
					title="Personality"
					placeholder="Share some details of your personality and background. Which preferences do you have? What makes you unique? Add details to make chats more personal"
					className={styles.personality}
				/>

				<ChatPersonaAddButton onSubmit={handleSubmit} disabled={disabled} />
				<CloseModalBtn onClose={onClose} />
				<AiAvatar
					openned={isAIPopupOpen}
					onClose={() => setIsAIPopupOpen(false)}
					onChange={handleImageChange}
					oneStyle
				/>
			</div>
		</Modal>
	);
};
