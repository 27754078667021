import {getAdminApiUrl} from '../common/constants';
import {
	AccessLevel,
	BotData,
	GetContextualImageResponse,
	LlmData,
	ReactionMessageReqBody,
	VoteMessageReqBody,
} from './Api.types';
import {USERS_API_TOKEN_KEY, XAuthName} from './constants';
import {TooManyRequestsError} from './Errors';
import {botDataMock} from './mocks';

const MAX_CHAT_REQUESTS = 15;
const MAX_TH_REQUESTS = 10;

export const generateUUID = () => {
	let d = new Date().getTime();
	if (
		typeof performance !== 'undefined' &&
		typeof performance.now === 'function'
	) {
		d += performance.now(); // use high-precision timer if available
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export type MediaType = 'image' | 'video' | null;

export type MediaResponse = {
	media_id: string | null;
	media_type: MediaType;
	media_url: string | null;
	media_resolution: null | number[];
	nsfw?: boolean | null;
	prompt?: string | null;
};

export type Message = {
	id?: string;
	turn: string;
	message: string;
	image?: string | null;
	video?: string | null;
	base64_image?: string;
	media_response?: MediaResponse | null;
	blured?: boolean;
	split?: string;
	edited?: boolean;
	timestamp?: number | null;
	prevResponses?: Message[];
	isDeleted?: boolean;
	reaction?: string | null;
	isSexting?: boolean;
};

export type Response = {
	response: string;
};

export type ChatRequestOptions = {
	user_id?: string;
	strapi_bot_id?: number;
	name: string;
	persona_facts?: string[];
	response_emotion?: string;
	predefined: boolean;
	context: Partial<Message>[];
	lipsync?: boolean;
	send_photo?: boolean;
	voice_name?: string;
	idle_url?: string;
	experience_id?: string;
	user_pronoun?: string;
	user_name?: string;
	bot_pronoun?: string;
	prev_responses?: string[];
	is_retry?: boolean;
	animation_pipeline?: string;
	access_level?: AccessLevel;
	ultra_llm_id?: string;
	media_retry_support?: boolean;
};

export type BotDataForRequest = {
	name: string;
	voice_name?: string;
	idle_url?: string;
	persona_facts: string[];
};

const DEFAULT_TOKEN = '';

export type ChatResponse = {
	base64_image?: string | null;
	base64_video?: string | null;
	contains_photo_request: boolean;
	is_user_msg_sexting?: boolean | null;
	response: string;
	split?: string;
	target_language?: string | null;
	media_response?: MediaResponse | null;
};

const ROOT_URL = 'https://api.exh.ai';

class Api {
	static readonly previousUidKey = 'pud';

	url: string;
	chatBotV3Url: string;
	token: string;
	underageUrl: string;
	emailUrl: string;
	mediaUrl: string;
	userUrl: string;
	videoUrl: string;
	constructor() {
		this.url = ROOT_URL + '/chatbot/v1';
		this.chatBotV3Url = ROOT_URL + '/chatbot/v3';
		this.emailUrl = ROOT_URL + '/mail/v1/collect_email';
		this.underageUrl = ROOT_URL + '/underage_clf/v1/detect_underage';
		this.mediaUrl = ROOT_URL + '/chat_images_manager/v1/get_media_response';
		this.videoUrl = ROOT_URL + '/chat_media_manager/v2/retrieve_video_response';
		this.userUrl = getAdminApiUrl();
		this.token = DEFAULT_TOKEN;
	}

	setToken(token: string) {
		this.token = token;
	}

	setTokenCookie(token: string) {
		this.setCookie('token', token, 7);
	}

	getTokenFromCookies(): string {
		return this.getCookie('token') || '';
	}

	getUserIdOrNull() {
		return this.getCookie('user_id') || null;
	}

	getUserId(onInit?: () => void) {
		const uid = this.getCookie('user_id');
		if (!uid) {
			onInit?.();
			const newUid = generateUUID();
			this.setCookie('user_id', newUid, 365);
			return newUid;
		}
		return uid;
	}

	setUserIdCookie(uid: string, withCopyPrevUid = false) {
		if (withCopyPrevUid) {
			this.copyPrevUid();
		}
		this.setCookie('user_id', uid, 365);
	}

	copyPrevUid() {
		const prevUid = this.getUserId();
		this.setCookie(Api.previousUidKey, prevUid, 365);
	}

	getPrevUid() {
		return this.getCookie(Api.previousUidKey) || this.getUserId();
	}

	getCookie(name: string) {
		const matches = document.cookie.match(
			new RegExp(
				'(?:^|; )' +
					name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
					'=([^;]*)'
			)
		);

		if (!matches) {
			return localStorage.getItem(name) || undefined;
		}

		return decodeURIComponent(matches[1]);
	}

	setCookie(name: string, value: string | number, days?: number) {
		const d = days || 365;
		const options = {
			path: '/',
			samesite: 'lax',
			secure: true,
			expires: new Date(Date.now() + 60 * 60 * 24 * d * 1000).toUTCString(),
		};

		value = encodeURIComponent(value);
		let updatedCookie = `${name}=${value}`;
		for (const propName in options) {
			updatedCookie += `; ${propName}`;

			//@ts-ignore
			const propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += `=${propValue}`;
			}
		}
		document.cookie = updatedCookie;

		localStorage.setItem(name, value);
	}

	private setCounter(k: string, v: number) {
		const nv = '7!sds_sdnf022_plo!dd=dd'
			.split('')
			.map((x, i) => (i === 15 ? v : x))
			.join('');
		this.setCookie(k, nv);
	}

	private getCounter(k: string) {
		const v = this.getCookie(k);
		if (!v) {
			return 0;
		}
		return parseInt(v.slice(15));
	}

	private updateCounter(key: string, max: number) {
		const counter = this.getCounter(key);
		if (counter >= max) {
			return false;
		}

		this.setCounter(key, counter + 1);
		return true;
	}

	private getHeaders(noContentType = false) {
		const xauthToken = api.getCookie(USERS_API_TOKEN_KEY);

		const headers: HeadersInit = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${this.token}`,
		};

		if (noContentType) {
			delete headers['Content-Type'];
		}

		if (xauthToken) {
			headers[XAuthName] = xauthToken;
		}

		return headers;
	}

	chatFromSmartReply(
		name: string,
		context: Message[],
		skipLimit = false,
		signal?: AbortSignal
	) {
		if (!skipLimit && !this.updateCounter('jh7df_ds3', MAX_CHAT_REQUESTS)) {
			return this.rejectTooManyRequests();
		}

		return this.chat(name, context, {}, signal);
	}

	async chatfromDigitalHumans(
		name: string,
		context: Message[],
		skipLimit: boolean,
		options: Partial<ChatRequestOptions>,
		signal?: AbortSignal
	): Promise<ChatResponse> {
		if (
			!skipLimit &&
			!this.updateCounter('md2_kli7ch!-dff', MAX_CHAT_REQUESTS)
		) {
			return this.rejectTooManyRequests();
		}

		let data = {} as ChatResponse;
		let isAborted = false;
		try {
			data = await this.chat(name, context, options, signal).catch((e) => {
				console.error(e);
				if (e.code === 20) {
					isAborted = true;
					return {} as ChatResponse;
				}
				console.warn('retry');
				return this.chat(name, context, options, signal);
			});
		} catch (e) {
			//@ts-ignore
			if (e.code === 20) {
				return {} as ChatResponse;
			}
			console.error(e);
			throw e;
		}

		if (!data?.response && !isAborted) {
			throw new Error('Empty response');
		}

		return data;
	}

	private rejectTooManyRequests() {
		return Promise.reject(new TooManyRequestsError());
	}

	getVideoMessage(media_id: string): Promise<MediaResponse> {
		return fetch(`${this.videoUrl}`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				media_id,
			}),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			throw new Error(res.statusText);
		});
	}

	getMediaMessage(
		media_id: string,
		avatar_url: string
	): Promise<MediaResponse> {
		return fetch(`${this.mediaUrl}`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				media_id,
				avatar_url,
				access_level: 'premium',
			}),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			if (res.status === 403) {
				throw new Error(res.status.toString());
			}
			throw new Error(res.statusText);
		});
	}

	chatFromEditBot(bot: BotData, context: Message[], signal?: AbortSignal) {
		return this.chatWithCustomBot(bot, context, !!bot.videoUrl, false, signal);
	}

	chatText(bot: BotData, context: Message[], signal?: AbortSignal) {
		return this.chatWithCustomBot(bot, context, false, false, signal);
	}

	public chatWithCustomBot(
		bot: BotDataForRequest,
		context: Message[],
		lipsync: boolean,
		send_photo: boolean,
		signal?: AbortSignal
	) {
		const body: any = {
			name: bot.name,
			user_id: this.getUserId(),
			context: this.prepareContext(context),
			predefined: false,
			lipsync: lipsync,
			send_photo: send_photo,
			persona_facts: bot.persona_facts,
		};
		if (lipsync) {
			body.voice_name = bot.voice_name;
			body.idle_url = bot.idle_url;
		}
		return fetch(`${this.url}/get_response`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(body),
			signal,
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			if (res.status === 403) {
				throw new Error(res.status.toString());
			}
			throw new Error(res.statusText);
		});
	}

	private prepareContext(context: Message[]) {
		return context
			.filter(
				({message, turn, media_response}) =>
					(!!message || !!media_response?.media_url) && !!turn
			)
			.filter((message) => !message.isDeleted)
			.map(({message, turn, timestamp, media_response}) => ({
				message,
				turn,
				timestamp: timestamp || null,
				media_id: media_response?.media_id || null,
			}));
	}

	private chat(
		name: string,
		context: Message[],
		options: Partial<ChatRequestOptions>,
		signal?: AbortSignal
	): Promise<ChatResponse> {
		const {lipsync, send_photo, ...rest} = options;
		const body: ChatRequestOptions = {
			name,
			user_id: this.getUserId(),
			context: this.prepareContext(context),
			predefined: true,
			lipsync: !!lipsync,
			send_photo: !!send_photo,
			...rest,
		};

		return fetch(`${this.url}/get_response`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(body),
			signal,
		}).then((res) => res.json());
	}

	smartReply(name: string, context: Message[], signal?: AbortSignal) {
		const body = {
			name,
			user_id: this.getUserId(),
			context: this.prepareContext(context),
			predefined: true,
			candidates_num: 2,
		};
		return fetch(`${this.url}/get_smart_replies`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(body),
			signal,
		}).then((res) => res.json());
	}

	private getLastMessageText(context: Message[]): string {
		return context.length ? context[context.length - 1].message : 'init';
	}

	chatMock(name: string, context: Message[]): Promise<Response> {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({response: `Answer to ${this.getLastMessageText(context)}`});
			}, 2000);
		});
	}

	generateLipsync(
		message: string,
		name: string,
		voice_name?: string,
		idle_url?: string,
		pipe?: string
	) {
		const body: any = {
			text: message,
		};
		if (voice_name) {
			body['voice_name'] = voice_name;
		}
		if (idle_url) {
			body['idle_url'] = idle_url;
		}

		body['animation_pipeline'] = pipe || 'optimal';

		return fetch('https://api.exh.ai/animations/v3/generate_lipsync', {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(body),
		}).then((res) => {
			if (res.ok) {
				return res.blob();
			}
			if (res.status === 403) {
				throw new Error(res.status.toString());
			}
			throw new Error(res.statusText);
		});
	}

	getFile(message: string, name: string) {
		if (!this.updateCounter('aasvds3-22!lo', MAX_TH_REQUESTS)) {
			return this.rejectTooManyRequests();
		}

		return this.generateLipsync(message, name);
	}

	contactInfo({
		name,
		email,
		companyName = '',
		reasons = [],
	}: {
		name: string;
		email: string;
		companyName?: string;
		reasons?: string[];
	}) {
		const body = {
			email_address: email,
			user_name: name,
			company_name: companyName,
			reasons,
		};
		return fetch(this.emailUrl, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(body),
		});
	}

	getBotInfo(id: string): Promise<BotData> {
		return new Promise((resolve) => {
			setTimeout(() => resolve(botDataMock), 1000);
		});
	}

	async isUnderAge(messages: string[]): Promise<boolean[]> {
		const body = {
			messages,
		};
		const data: {is_underage: boolean[]} = await fetch(this.underageUrl, {
			method: 'POST',
			body: JSON.stringify(body),
		}).then((res) => res.json());

		return data.is_underage;
	}

	voteMessage(data: VoteMessageReqBody) {
		return fetch(`https://api.exh.ai/reactions/v2/add_reaction`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				...data,
				context: this.prepareContext(
					data.context.slice(0, data.context.length - 1)
				),
				user_id: this.getUserId(),
			}),
		});
	}

	addFeedback(data: VoteMessageReqBody & {text_feedback: string}) {
		return fetch(`https://api.exh.ai/reactions/v1/add_text_feedback`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				...data,
				context: this.prepareContext(
					data.context.slice(0, data.context.length - 1)
				),
				user_id: this.getUserId(),
			}),
		});
	}

	messageEdited(data: ReactionMessageReqBody) {
		return fetch(`https://api.exh.ai/reactions/v1/add_edited_message`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				...data,
				context: this.prepareContext(data.context),
				prev_context: this.prepareContext(data.prev_context),
				user_id: this.getUserId(),
			}),
		});
	}

	generateAvatarFromText(text: string, style: 'realistic' | 'anime') {
		return fetch(`https://api.exh.ai/image_generation/v2/generate_avatar`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				prompt: text,
				n: 1,
				style,
			}),
		}).then((res) => {
			return res.json();
		});
	}

	generateIdle(file: File): Promise<{idle_url: string; image_url: string}> {
		const formData = new FormData();
		formData.append('image', file);
		return fetch('https://api.exh.ai/animations/v2/create_bot', {
			method: 'POST',
			headers: this.getHeaders(true),
			body: formData,
		}).then((res) => res.json());
	}

	generateAnimation(
		file: File
	): Promise<{idle_url?: string; image_url: string; message?: string}> {
		const formData = new FormData();
		formData.append('image', file);
		return fetch('https://api.exh.ai/animations/v1/generate_idle_video', {
			method: 'POST',
			headers: this.getHeaders(true),
			body: formData,
		}).then((res) => res.json());
	}

	checkBot(data: {
		bot_name?: string;
		bot_facts?: string[];
		description?: string;
		bio?: string;
		appearance?: string;
		greeting?: string;
	}): Promise<{is_inappropriate_bot: boolean; is_sexual_bot: boolean}> {
		const requestData = {
			bot_name: data.bot_name || null,
			description:
				data.description || data.bot_facts?.map((x) => x).join(',') || null,
			bio: data.bio || null,
			appearance: data.appearance || null,
			greeting: data.greeting || null,
		};

		return fetch('https://api.exh.ai/moderation/v2/detect_inappropriate_bot', {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(requestData),
		}).then((res) => res.json());
	}

	sendContactForm(data: object) {
		return fetch(`${this.userUrl}/user/contact`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		});
	}

	validateMessageText(
		text: string
	): Promise<{is_inappropriate_message: boolean}> {
		return fetch(
			'https://api.exh.ai/moderation/v1/detect_inappropriate_message',
			{
				method: 'POST',
				headers: this.getHeaders(),
				body: JSON.stringify({
					message: text,
				}),
			}
		).then((res) => res.json());
	}

	generateSpeech(text: string, voice_name: string) {
		return fetch('https://api.exh.ai/tts/v1/generate_speech', {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				text,
				voice_name,
			}),
		}).then((res) => {
			if (res.ok) {
				return res.blob();
			}
			if (res.status === 403) {
				throw new Error(res.status.toString());
			}
			throw new Error(res.statusText);
		});
	}

	getLlmList(access_level: AccessLevel): Promise<{ultra_llms: LlmData[]}> {
		return fetch('https://api.exh.ai/ultra_llms/v1/list', {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				access_level,
				user_id: this.getUserId(),
			}),
		}).then((res) => res.json());
	}

	get cookieHelper() {
		return {
			getUtmCookies: () => {
				const utmCookies = this.getCookie('utm-cookies');
				return utmCookies ? JSON.parse(utmCookies) : {};
			},
			setUtmCookies: (utmParams: Record<string, string>) => {
				const utmCookiesRaw = this.getCookie('utm-cookies');
				let utmCookies = {};
				try {
					utmCookies = utmCookiesRaw ? JSON.parse(utmCookiesRaw) : {};
				} catch (e) {
					console.error('Failed to parse utm cookies:', e);
				}
				this.setCookie(
					'utm-cookies',
					JSON.stringify({...utmCookies, ...utmParams})
				);
			},
		};
	}

	removeMemory(botId: number) {
		return fetch(`${this.url}/remove_memory`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				user_id: api.getUserId(),
				bot_id: botId,
			}),
		})
			.then((res) => res.json())
			.catch((e) => {
				console.error(e);
				return null;
			});
	}

	getContextualImage(
		strapiBotId: string,
		context: Message[]
	): Promise<GetContextualImageResponse> {
		return fetch(`${this.chatBotV3Url}/botify/contextual_image`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				strapi_bot_id: strapiBotId,
				user_id: this.getUserId(),
				context: this.prepareContext(context),
			}),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			throw new Error(res.statusText);
		});
	}
}

export const api = new Api();
