import {useState} from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@mui/material';
import styles from './ConfirmDialog.module.css';
import cn from 'classnames';
import {CloseModalBtn} from '../../components';

interface UseConfirmationProps {
	title: string;
	text: string;
	okButtonText: string;
	onSubmit: () => void;
	className?: string;
}

export const useConfirmation = ({
	title,
	text,
	okButtonText,
	onSubmit,
	className,
}: UseConfirmationProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	const handleConfirm = () => {
		onSubmit();
		close();
	};

	const ConfirmationDialog = () => (
		<Dialog
			open={isOpen}
			onClose={close}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={cn(styles.dialog, className)}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={close}
					className={cn(styles.dialogButton, styles.dialogButtonCancel)}
				>
					Cancel
				</Button>
				<Button
					onClick={handleConfirm}
					autoFocus
					color="error"
					className={cn(styles.dialogButton, styles.dialogButtonDelete)}
				>
					{okButtonText}
				</Button>
				<CloseModalBtn onClose={close} />
			</DialogActions>
		</Dialog>
	);

	return {open, close, ConfirmationDialog};
};
