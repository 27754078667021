import styles from './AiAvatarMobile.module.css';
import {AiAvatarProps, useAiAvatar} from '../AiAvatar';
import {MobileDialog} from '../../CreateBotMobile/MobileDialog/MobileDialog';
import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import placeholder from '../../AvatarSelect/images/avatarPlaceholder.svg';
import refreshImg from '../images/Refresh.svg';
import {AiName} from '../AIName/AiName';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import cn from 'classnames';
import checkImg from '../images/check.svg';

export const AiAvatarMobile = ({
	openned,
	onClose,
	onChange,
	oneStyle,
}: AiAvatarProps) => {
	const {
		image,
		image2,
		isLoading,
		text,
		handleGenerateClick,
		handleChangeText,
		handleRefreshClick,
		handleDialogClose,
		selectedImage,
		handleSelectFirstImage,
		handleSelectSecondImage,
	} = useAiAvatar({
		openned,
		onClose,
		onChange,
	});

	return (
		<MobileDialog
			openned={openned}
			onClose={onClose}
			title="Generate with AI"
			contentClassName={styles.container}
		>
			<>
				<div className={styles.avatarContainer}>
					{isLoading && (
						<div className={styles.avatarSpinnerContainer}>
							<Spinner withLayout={false} />
						</div>
					)}
					{!isLoading && (
						<>
							<div
								className={cn(styles.imageContainer, {
									[styles.selected]: selectedImage && selectedImage === image,
								})}
							>
								<img
									src={image || placeholder}
									alt="avatar"
									className={cn(styles.placeholder)}
									onClick={handleSelectFirstImage}
								/>
								{selectedImage && selectedImage === image && (
									<img src={checkImg} alt="check" className={styles.check} />
								)}
							</div>
							{!oneStyle && (
								<div
									className={cn(styles.imageContainer, {
										[styles.selected]:
											selectedImage && selectedImage === image2,
									})}
								>
									<img
										src={image2 || placeholder}
										alt="avatar"
										className={cn(styles.placeholder)}
										onClick={handleSelectSecondImage}
									/>
									{selectedImage && selectedImage === image2 && (
										<img src={checkImg} alt="check" className={styles.check} />
									)}
								</div>
							)}
						</>
					)}
				</div>
				<AiName
					value={text}
					onChange={handleChangeText}
					className={styles.textArea}
				/>
				{(!image || isLoading) && (
					<InButton
						id="generate"
						isFilled
						className={styles.buttonG}
						onClick={handleGenerateClick}
						isDisabled={!text}
						loading={isLoading}
					>
						{isLoading ? <>Loading</> : <>Generate</>}
					</InButton>
				)}
				{selectedImage && !isLoading && (
					<div className={styles.btnsRow}>
						<InButton
							id="regenerate"
							className={styles.buttonRefresh}
							onClick={handleRefreshClick}
							isDisabled={isLoading}
						>
							<img
								src={refreshImg}
								alt="refresh"
								className={styles.refreshImg}
							/>
							<p>Regenerate</p>
						</InButton>
						<InButton
							id="save"
							isFilled
							className={styles.buttonG}
							onClick={handleDialogClose}
							isDisabled={isLoading}
							loading={isLoading}
						>
							Done
						</InButton>
					</div>
				)}
			</>
		</MobileDialog>
	);
};
