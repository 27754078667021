import {TimeLimitInfo} from '../../../../../chatStore/ChatStorage';
import styles from '../DesktopBotPage.module.css';

type Props = {
	data: TimeLimitInfo;
};

export const LimitInfoText = ({data}: Props) => {
	const {hours, minutes, sec, hoursStr, minutesStr, secStr} = data;

	if (hours === 0 && minutes === 0 && sec === 0) {
		return null;
	}
	return (
		<div className={styles.limitInfo}>
			<p className={styles.limitInfoYellow}>
				wait{' '}
				<span
					className={styles.limitInfoYellowBold}
				>{`${hoursStr}:${minutesStr}:${secStr}`}</span>
			</p>
			<p className={styles.limitInfoRegular}>or</p>
		</div>
	);
};
