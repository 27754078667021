import {useNavigate} from 'react-router-dom';
import {BotData} from '../../api/types';
import {MessagesQty} from '../../common-lib/src/components/BotTile/MessagesQty';
import cn from 'classnames';
import styles from './Tile.module.css';

type Props = {
	bot: BotData;
	className?: string;
	onlyPhoto?: boolean;
	onClick?: () => void;
};

export const MobileTile = ({
	bot,
	className,
	onlyPhoto = false,
	onClick,
}: Props) => {
	const navigate = useNavigate();
	const handleTileClick = () => {
		onClick?.();
		navigate(`/bot_${bot.id}/chat`);
	};

	return (
		<div className={styles.mobileTile} onClick={handleTileClick}>
			<img
				src={bot.attributes.avatarUrl}
				alt={`${bot.attributes.name} - 100 ${bot.attributes.createdAt}`}
				loading="lazy"
				className={styles.mobileImg}
				title={bot.attributes.name}
			/>
			<div className={styles.mobileTexts}>
				<h2 className={styles.mobileName}>{bot.attributes.name}</h2>
				<p className={styles.mobileDescription}>{bot.attributes.bio || ''}</p>
			</div>
			<MessagesQty
				messagesQty={bot.attributes.messagesCount}
				className={cn('bot-tile__messages-qty', styles.qty)}
			/>
		</div>
	);
};
