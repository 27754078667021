import {Modal} from '@mui/material';
import './LimitModal.css';
import {CloseModalBtn, InButton} from '../../common-lib/src/components';
import {chatStorage} from '../../chatStore/ChatStorage';

type Props = {
	openned: boolean;
	onClose: () => void;
	onSuccess: (data: {email: string; token: string}) => void;
};

export const LimitModal = ({openned, onClose, onSuccess}: Props) => {
	const limitInfo = chatStorage.limitTimeLeft();

	return (
		<Modal className="limit-modal" open={openned} onClose={onClose}>
			<div className="column limit-modal__inner">
				<h2 className="limit-modal__title">You've reached messages limit</h2>

				<InButton
					isFilled
					id="continue-limit-modal"
					onClick={onSuccess}
					className="login-modal__btn"
				>
					Continue right now
				</InButton>
				<p className="limit-modal__wait">
					or wait{' '}
					<span>
						{limitInfo.hoursStr}:{limitInfo.minutesStr}:{limitInfo.secStr}
					</span>
				</p>
				<CloseModalBtn onClose={onClose} />
			</div>
		</Modal>
	);
};
