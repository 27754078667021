import './MessagesQty.css';
import {getMessagesQty} from './MessagesQty.utils';
import bubble from './images/bubble.svg';
import like from './images/like.svg';
type Props = {
	messagesQty?: number;
	className?: string;
	type?: 'help' | 'message';
};

export const MessagesQty = ({
	messagesQty,
	className,
	type = 'message',
}: Props) => {
	return (
		<div className={`messages-qty ${className}`}>
			<img
				src={type === 'message' ? bubble : like}
				alt="bubble"
				className="messages-qty__bubble"
			/>
			<p className="messages-qty__text">{getMessagesQty(messagesQty)}</p>
		</div>
	);
};
