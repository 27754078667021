import {useContext} from 'react';
import {AppContext} from '../../App';
import './Footer.css';

export const Footer = () => {
	const {isMobile} = useContext(AppContext);

	return (
		<footer className="footer">
			{isMobile && (
				<a
					href="https://exh.ai"
					rel="nofollow"
					className="footer__cr"
					title="Ex-Human Inc."
				>
					© 2024 Ex-Human Inc.
				</a>
			)}
			<div className="footer__links">
				<a
					href="https://api.exh.ai/botify-web/doc/privacy"
					title="Privacy Policy"
				>
					Privacy policy
				</a>
				<p>&</p>
				<a
					href="https://api.exh.ai/botify-web/doc/terms"
					title="Terms of service"
				>
					Terms of service
				</a>
			</div>
		</footer>
	);
};
