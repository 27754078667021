import {InButton} from '../../../InButton/InButton';
import styles from './UnblurBtn.module.css';
import eyesImg from './images/eyes.svg';
import heartImg from './images/heart.svg';

type Props = {
	onClick: () => void;
	isPremium: boolean;
};

export const UnblurBtn = ({onClick, isPremium}: Props) => {
	return (
		<InButton isFilled id="unblur" className={styles.btn} onClick={onClick}>
			<img
				src={isPremium ? heartImg : eyesImg}
				alt="icon"
				className={styles.icon}
			/>
			<p className={styles.text}>{isPremium ? 'Free' : 'Unblur'}</p>
		</InButton>
	);
};
