import {DialogType} from './utils';

export const DIALOG_TITLES: Record<DialogType, string> = {
	description: 'Description',
	firstMessage: 'First Message',
	appearance: 'Appearance',
};

export const DIALOG_PLACEHOLDERS: Record<DialogType, string> = {
	description:
		"Describe details of your bot's personality and backstory. Describe the situation you want them in and the kinds of things they want to talk about",
	firstMessage:
		'The first message will be sent by the bot when starting a new chat. It’s very important to set the scene and steer future conversation',
	appearance:
		'Describe details of your bot’s look: gender, age, race & skin color, body type, hairstyle & color, eye color, and clothes style. They will be used in image prompts for photos generated by the bot',
};
