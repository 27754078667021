import './ChatMain.css';
import {ChatMessage, MessageWithAvatar} from './ChatMessage';
import {
	Actions,
	ChatProps,
	CommonActions,
	PropsWithLazyLoad,
	UserActions,
} from './ChatMain.types';
import {useEffect, useState} from 'react';
import {useIsVisible} from '../../../hooks/useIsVisible';
import {Spinner} from '../../Spinner/Spinner';

export const ChatMainWithLazyLoad = ({
	pagination,
	...rest
}: PropsWithLazyLoad) => {
	const {onLoadMore, hasMore, loading} = pagination;
	const [topRef, setTopRef] = useState<HTMLDivElement | null>(null);
	const isVisible = useIsVisible({el: topRef});

	const handleLoadMore = async () => {
		if (isVisible && hasMore && !loading) {
			await onLoadMore();
		}
	};

	useEffect(() => {
		handleLoadMore();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, isVisible, hasMore, loading]);

	return (
		<>
			{hasMore && !loading && (
				<div ref={setTopRef} style={{width: '1px', height: '1px'}} />
			)}
			{loading && <Spinner withLayout={false} />}
			<ChatMain {...rest} />
		</>
	);
};

export const ChatMain = ({
	isLoading,
	messages,
	person,
	withPhoto = false,
	actions,
	userActions,
}: ChatProps) => {
	if (withPhoto && !person) {
		throw new Error('Person is required for chat with photo');
	}

	return (
		<>
			{messages.map((message, i) => {
				const isDeleted = !!message.isDeleted;
				let passedActions = {} as Actions;
				const isBotMessage = message.turn === 'bot' && !isLoading;
				const isLastMessageOfBot = i === messages.length - 1 && isBotMessage;
				const isLastMessageOfUser =
					i === messages.length - 1 && message.turn === 'user' && !isLoading;
				const hasMedia = message.image || message.video;
				const onlyMediaMessage = !message.message && hasMedia;
				const isMuted = actions?.muted ?? true;

				//set bot message actions
				if (isLastMessageOfBot && actions && !isDeleted) {
					passedActions.like = actions?.like || (() => Promise.resolve(false));
					passedActions.dislike = actions?.dislike || (() => {});
					passedActions.disabledLike = actions?.disabledLike || false;
					passedActions.vote = actions?.vote || (() => {});
					if (actions?.retry) {
						passedActions.retry = actions?.retry || (() => {});
					}
					if (actions?.prevMessage && message.prevResponses?.length) {
						passedActions.prevMessage = actions?.prevMessage || (() => {});
					}
					if (actions?.regenerateDisabled) {
						passedActions.regenerateDisabled = actions.regenerateDisabled || (() => false);
					}

					passedActions.isEditable = false;
					passedActions.scrollChat = actions?.scrollChat || (() => {});

					if (actions?.shouldShowRetryFeedback) {
						passedActions.shouldShowRetryFeedback =
							actions.shouldShowRetryFeedback;
						passedActions.handleCloseRetryFeedback =
							actions.handleCloseRetryFeedback;
					}
					if (actions.shouldShowVoteNotification) {
						passedActions.shouldShowVoteNotification =
							actions.shouldShowVoteNotification;
						passedActions.handleCloseVoteNotification =
							actions.handleCloseVoteNotification;
					}

					//TODO: add if magic action and hasPremium and no photo -also move logic on level up
					if (actions.magic && !hasMedia) {
						passedActions.magic = actions.magic;
					}

					passedActions.muted = isMuted;
				}

				//set blur/unblur actions
				if (message.blured && actions?.unblur && !isDeleted) {
					passedActions.unblur = () =>
						actions?.unblur?.(message) || Promise.resolve(true);
					passedActions.hasPremium = actions?.hasPremium || false;
				}

				//set user message actions
				let userPassedActions = undefined as UserActions | undefined;
				if (isLastMessageOfUser && userActions && !isDeleted) {
					userPassedActions = {
						onErrorClick: userActions.onErrorClick,
					};
				}

				let commonActions = undefined as CommonActions | undefined;
				if (actions?.handleDeleteMessage && !isDeleted) {
					commonActions = {
						handleDeleteMessage: actions.handleDeleteMessage,
					};

					if (!onlyMediaMessage) {
						commonActions.copy = actions.copy;
					}

					if (!onlyMediaMessage) {
						commonActions.edit = actions.edit;
						commonActions.isEditable = actions.isEditable;
					}
				}

				const showTyping =
					isLastMessageOfBot &&
					!message.message &&
					!message.image &&
					!message.video &&
					!isLoading &&
					!message.isDeleted;

				if (showTyping) {
					return (
						<div className="chat__message chat__message_bot chat__message_bot_typing">
							<div className="dot-flashing"></div>
						</div>
					);
				}

				return !withPhoto || message.turn === 'user' || !person ? (
					<ChatMessage
						message={message}
						key={message.message + message.timestamp + i + 'simple'}
						actions={
							Object.keys(passedActions).length ? passedActions : undefined
						}
						userActions={userPassedActions}
						commonActions={commonActions}
					/>
				) : (
					<MessageWithAvatar
						message={message}
						person={person}
						key={message.message + i + 'photo'}
					/>
				);
			})}
			{isLoading && (
				<div className="chat__message chat__message_bot chat__message_bot_typing">
					<div className="dot-flashing"></div>
				</div>
			)}
		</>
	);
};
