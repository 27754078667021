export abstract class AbstractStorage {
	abstract init(): Promise<boolean>;
	abstract get(key: string | number): Promise<string | number | null>;
	abstract set(key: string, value: string): void;
}

export class LocalStorage extends AbstractStorage {
	init() {
		return Promise.resolve(true);
	}
	get(key: string | number): Promise<string | number | null> {
		const data = localStorage.getItem(key.toString());
		if (data) {
			return Promise.resolve(data);
		}
		return Promise.resolve(null);
	}

	set(key: string | number, value: string) {
		localStorage.setItem(key.toString(), value);
	}
}

export class IndexedDBStorage extends AbstractStorage {
	name: string;
	version: number;
	constructor(name: string, version: number) {
		super();
		this.name = name;
		this.version = version;
	}
	init(): Promise<boolean> {
		return new Promise((resolve) => {
			try {
				const request = indexedDB.open(this.name, this.version);

				request.onerror = () => {
					console.warn('Open IndexedDB error');
					resolve(false);
				};

				request.onupgradeneeded = (event: any) => {
					const db = event.target.result;
					if (!db.objectStoreNames.contains('data')) {
						const objectStore = db.createObjectStore('data', {keyPath: 'id'});
						objectStore.createIndex('id', 'id', {unique: true});
					}
				};

				request.onsuccess = () => {
					resolve(true);
				};
			} catch (e) {
				console.warn(e);
				resolve(false);
			}
		});
	}

	private ensureObjectStore(db: IDBDatabase): Promise<IDBDatabase> {
		return new Promise((resolve) => {
			if (!db.objectStoreNames.contains('data')) {
				db.close();
				const request = indexedDB.open(this.name, this.version + 1);

				request.onupgradeneeded = (event: any) => {
					const newDb = event.target.result;
					if (!newDb.objectStoreNames.contains('data')) {
						const objectStore = newDb.createObjectStore('data', {
							keyPath: 'id',
						});
						objectStore.createIndex('id', 'id', {unique: true});
					}
				};

				request.onsuccess = (event: any) => {
					this.version += 1;
					resolve(event.target.result);
				};

				request.onerror = (event: any) => {
					console.warn('Error upgrading database:', event.target.error);
					resolve(db);
				};
			} else {
				resolve(db);
			}
		});
	}

	set(key: string | number, value: string | number) {
		try {
			const request = indexedDB.open(this.name, this.version);
			request.onsuccess = async (event: any) => {
				const db = await this.ensureObjectStore(event.target.result);
				const transaction = db.transaction(['data'], 'readwrite');
				const objectStore = transaction.objectStore('data');
				objectStore.put({
					id: key.toString(),
					value: JSON.stringify({data: value}),
				});
			};
		} catch (e) {
			console.warn(e);
		}
	}

	async get(key: string | number): Promise<string | number | null> {
		return new Promise((resolve) => {
			const request = indexedDB.open(this.name, this.version);
			request.onerror = () => {
				return resolve(null);
			};
			request.onsuccess = async (event: any) => {
				const db = await this.ensureObjectStore(event.target.result);
				const transaction = db.transaction(['data'], 'readonly');
				const objectStore = transaction.objectStore('data');
				const request2 = objectStore.get(key.toString());
				request2.onsuccess = (event: any) => {
					const v = event.target.result?.value;
					if (!v) {
						return resolve(null);
					}
					return resolve(JSON.parse(v)?.data);
				};
				request2.onerror = () => {
					return resolve(null);
				};
			};
		});
	}
}
