import {Modal} from '@mui/material';
import styles from './PhotoModal.module.css';
import {useContext} from 'react';
import {AppContext} from '../../App';
import {CloseModalBtn} from '../../common-lib/src/components/CloseModalBtn/CloseModalBtn';

type Props = {
	opened: boolean;
	src: string | null;
};

export const PhotoModal = ({opened, src}: Props) => {
	const {handlePhotoModalOpened} = useContext(AppContext);
	if (!opened || !src) {
		return null;
	}

	const handleClose = () => {
		handlePhotoModalOpened(false);
	};

	return (
		<Modal open={opened} onClose={handleClose} className={styles.modal}>
			<div className={styles.modalContent}>
				<img src={src} alt="Full screen" className={styles.image} />
				<CloseModalBtn onClose={handleClose} />
			</div>
		</Modal>
	);
};
