import {backImg, otherImg, reportImg, editImg, shareImg} from '../images';
import logo from '../../../../../images/header-logo.svg';
import '../BotPage.css';
import styles from '../DesktopBotPage.module.css';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {SideBar} from '../Sidebar/SideBar';
import {SharingModal} from '../SharingModal/SharingModal';
import {BotProfileProps} from '../BotPage.types';
import {FeedbackModal} from '../../../../Feedback/Feedback';
import st from './DesktopBotProfile.module.css';
import {InButton} from '../../../../../common-lib/src/components/InButton/InButton';
import {LikeBtn} from '../../../../Tile/LikeBtn/LikeBtn';
import {Spinner} from '../../../../../common-lib/src/components/Spinner/Spinner';
import {useBotProfile} from './useBotProfile';
import {ScrollContainer} from '../../../../ScrollButtons/ScrollBtns';

const BotProfileContent = (props: BotProfileProps) => {
	const {bot, handleGoChat} = props;
	const {
		isLiked,
		likesCount,
		handleLikeClick,
		handleShareClick,
		isSimilarLoading,
		similarBots,
		isPhotosLoading,
		photos,
		scrollerRef,
		getBotClickLink,
		handlePhotoClick,
	} = useBotProfile(props);

	return (
		<div className={st.content}>
			<div className={st.botRow}>
				<img
					src={bot.attributes.avatarUrl}
					alt={bot.attributes.name}
					className={st.avatar}
				/>
				<div className={st.descriptionCol}>
					<h2 className={st.name}>{bot.attributes.name}</h2>
					{bot.attributes.username && (
						<Link
							to={`/profile/${bot.attributes.username}`}
							className={st.username}
						>
							@{bot.attributes.username}
						</Link>
					)}
					<p className={st.description}>{bot.attributes.bio || ''}</p>
					<div className={st.tags}>
						{bot.attributes.tags.data.map((tag, index) => (
							<Link
								to={`/category_${tag.attributes.slug}`}
								key={index}
								className={st.tag}
							>
								{tag.attributes.name}
							</Link>
						))}
					</div>
				</div>
				<div className={st.botExtra}>
					<div className={st.botExtraRow}>
						<LikeBtn
							className={st.likeBtn}
							isLiked={isLiked}
							count={likesCount}
							onClick={handleLikeClick}
						/>
						<div className={st.shareBtn} onClick={handleShareClick}>
							<img src={shareImg} alt="share" />
						</div>
					</div>
					<InButton
						isFilled
						id="start-chat"
						onClick={handleGoChat}
						className={st.startChat}
					>
						Start Chat
					</InButton>
				</div>
			</div>
			{(isSimilarLoading || !!similarBots.length) && (
				<div className={st.similarRow}>
					<h3 className={st.rowTitle}>Similar bots</h3>
					{isSimilarLoading ? (
						<Spinner withLayout={false} />
					) : (
						<ScrollContainer className={st.scrollBtn}>
							<div className={st.similarBots}>
								{similarBots.map((bot, index) => (
									<a
										key={index}
										className={st.similarBot}
										href={getBotClickLink(bot.id)}
									>
										<img
											src={bot.attributes.avatarUrl}
											alt={bot.attributes.name}
											className={st.similarAvatar}
										/>
										<h4 className={st.similarName}>{bot.attributes.name}</h4>
									</a>
								))}
							</div>
						</ScrollContainer>
					)}
				</div>
			)}
			{(isPhotosLoading || !!photos.length) && (
				<div className={st.similarRow}>
					<h3 className={st.rowTitle}>Photos</h3>
					<div className={st.photos}>
						{photos.map((photo, index) => (
							<img
								key={index}
								src={photo.url}
								alt={`${bot.attributes.name}`}
								className={st.photo}
								onClick={() => handlePhotoClick(photo.url)}
							/>
						))}
						<div ref={scrollerRef} />
					</div>
					{isPhotosLoading && <Spinner withLayout={false} />}
				</div>
			)}
		</div>
	);
};

export const DesktopBotProfile = (props: BotProfileProps) => {
	const {
		isSharingOpened,
		setIsSharingOpened,
		isOtherActionsOpened,
		handleOpen,
		handleEditClick,
		setIsFeedbackOpened,
		bot,
		isFeedbackOpened,
		handleSubmitReport,
		handleGoChat,
	} = props;

	return (
		<div className="mobile-bot-page">
			<div className={cn('header', styles.header)}>
				<div className={styles.logoContainer}>
					<Link to="/" title="Home page">
						<img src={logo} alt="logo" className="logo" />
					</Link>
				</div>
				<div className={styles.headerContainer}>
					<img
						src={backImg}
						alt="back"
						className="back"
						onClick={handleGoChat}
					/>
					<div className="profile">
						<div className="texts">
							<h1 className="name">{bot.attributes.name}</h1>
						</div>
					</div>
					<div className="actions">
						<div className="action" id="bot-actions" onClick={handleOpen}>
							<img src={otherImg} alt="share" />
							{isOtherActionsOpened && (
								<div className="actions__box">
									{bot.attributes.isOwner && (
										<div className="action__row" onClick={handleEditClick}>
											<img src={editImg} alt="edit bot" />
											<p>Edit Bot</p>
										</div>
									)}
									<div
										className="action__row"
										onClick={() => setIsFeedbackOpened(true)}
									>
										<img src={reportImg} alt="report bot" />
										<p className="action__row_text-red">Report Bot</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.container}>
				<SideBar hasDiscoverBotsBtn />
				<div className={cn(styles.chat, st.pageContainer)}>
					<BotProfileContent {...props} />
				</div>
			</div>
			<SharingModal
				isOpened={isSharingOpened}
				onClose={() => setIsSharingOpened(false)}
				link={window.location.href}
			/>
			<FeedbackModal
				isOpen={isFeedbackOpened}
				onClose={() => setIsFeedbackOpened(false)}
				onSubmit={handleSubmitReport}
			/>
		</div>
	);
};
