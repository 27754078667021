import {useContext} from 'react';
import plusImg from './images/plus.svg';
import './CreateCharacterBtn.css';
import cn from 'classnames';
import {AppContext, SHOULD_OPEN_CREATE_AFTER_LOGIN} from '../../App';
import {InButton} from '../../common-lib/src/components';

export const CreateCharacterBtn = ({
	className = '',
}: React.HTMLAttributes<HTMLDivElement>) => {
	const {setIsCreateOpened, user, setIsLoginOpened} = useContext(AppContext);

	const handleClick = () => {
		if (user && !user.isAnon) {
			setIsCreateOpened(true);
		} else {
			setIsLoginOpened(true);
			sessionStorage.setItem(SHOULD_OPEN_CREATE_AFTER_LOGIN, 'true');
		}
	};
	return (
		<InButton
			id="create-bot"
			isFilled
			className={cn('create-btn', className)}
			onClick={handleClick}
		>
			<img src={plusImg} alt="plus" />
			<p>Create AI Character</p>
		</InButton>
	);
};
