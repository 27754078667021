import googleImg from './images/btn-playmarket.png';
import appleImg from './images/btn-appstore.png';
import './StoreBtns.css';
import {ampliApi} from '../../ampli/amplitudeApi';

type Props = {
	className?: string;
	noAppStore?: boolean;
	noGooglePlay?: boolean;
	onClick?: () => void;
};

export const StoreBtns = ({
	className = '',
	noAppStore = false,
	noGooglePlay = false,
	onClick,
}: Props) => {
	const handleAppleClick = (e: any) => {
		onClick?.();
		e.preventDefault();

		//@ts-ignore
		if (window?.twq && typeof window.twq === 'function') {
			//@ts-ignore
			window.twq('event', 'tw-obsxm-obu3q', {});
		}
		ampliApi.appLink(false);
		window.open(
			'https://apps.apple.com/us/app/botify-ai-create-chat-bot/id1566710178',
			'_blank'
		);
	};

	const handleGoogleClick = (e: any) => {
		e.preventDefault();
		ampliApi.appLink(true);
		window.open(
			'https://play.google.com/store/apps/details?id=ai.botify.app',
			'_blank'
		);
	};

	return (
		<div className={`store-btns ${className}`}>
			{!noAppStore && (
				<a
					className="store-btn"
					href="https://apps.apple.com/us/app/botify-ai-create-chat-bot/id1566710178"
				>
					<img
						src={appleImg}
						alt="apple store"
						className="store-btn__image"
						onClick={handleAppleClick}
					/>
				</a>
			)}
			{!noGooglePlay && (
				<a
					className="store-btn"
					href="https://play.google.com/store/apps/details?id=ai.botify.app"
				>
					<img
						src={googleImg}
						alt="google play"
						className="store-btn__image"
						onClick={handleGoogleClick}
					/>
				</a>
			)}
		</div>
	);
};
