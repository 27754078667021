import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styles from './CategoriesSwitch.module.css';
import {AppContext, FEATURED_CATEGORY_NAME, PERIOD_CATEGORIES} from '../../App';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import cn from 'classnames';
import arrowIcon from './images/arrow.svg';
import {resetTitleAndDescription} from '../../utils';

export const CategoriesSwitch = () => {
	const {categories} = useContext(AppContext);
	const location = useLocation();
	const navigate = useNavigate();

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
	const [scrollRightVisible, setScrollRightVisible] = useState(true);
	const [title, setTitle] = useState('Explore Characters');

	const isTouchDevice = 'ontouchstart' in window;

	const categoriesNames = useMemo(() => {
		const names = Object.values(categories)
			.filter((cat) => !cat.hideInSwitch)
			.sort((a, b) => a.data.attributes.sort - b.data.attributes.sort)
			.map((category) => category.data.attributes.name);

		return names;
	}, [categories]);

	const [selectedCategory, setSelectedCategory] = useState(() => {
		let category =
			location.pathname.includes('category_') &&
			location.pathname.split('_')[1];

		if (!category) {
			category = FEATURED_CATEGORY_NAME;
		}
		const data = Object.values(categories).find(
			(cat) => cat.data.attributes.name === category
		);
		if (!data) {
			return FEATURED_CATEGORY_NAME;
		}
		return data.data.attributes.name;
	});

	useEffect(() => {
		const categorySlug =
			location.pathname.includes('category_') &&
			location.pathname.split('_')[1];
		if (!categorySlug) {
			setSelectedCategory(FEATURED_CATEGORY_NAME);
			return;
		}
		const data = Object.values(categories).find(
			(cat) => cat.data.attributes.slug === categorySlug
		);
		if (!data) {
			setSelectedCategory(FEATURED_CATEGORY_NAME);
			navigate('/');
			return;
		}
		setSelectedCategory(data.data.attributes.name);
	}, [location.pathname, categories]);

	const getCategorySlugByName = (name: string) => {
		const data = Object.values(categories).find(
			(cat) => cat.data.attributes.name === name
		);
		if (!data) {
			return;
		}
		return data.data.attributes.slug;
	};

	useEffect(() => {
		setTitle(selectedCategory);
	}, [selectedCategory]);

	useEffect(() => {
		document.title = `${title} - list of characters - Botify AI`;

		document
			.querySelector('meta[name="description"]')
			?.setAttribute(
				'content',
				`AI characters on the theme ${title} | Botify AI`
			);

		return () => {
			resetTitleAndDescription();
		};
	}, [title]);

	const handleScrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollLeft += 200;
		}
	};

	const handleScrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollLeft -= 200;
		}
	};

	useEffect(() => {
		if (scrollContainerRef.current) {
			const container = scrollContainerRef.current;
			const check = () => {
				if (container.scrollLeft > 0) {
					setScrollLeftVisible(true);
				} else {
					setScrollLeftVisible(false);
				}
				if (
					container.scrollLeft <
					container.scrollWidth - container.clientWidth - 10
				) {
					setScrollRightVisible(true);
				} else {
					setScrollRightVisible(false);
				}
			};
			container.addEventListener('scroll', () => {
				check();
			});
			check();
		}
	}, [scrollContainerRef]);

	const isPeriod = PERIOD_CATEGORIES.includes(selectedCategory.toLowerCase());

	if (isPeriod) {
		return (
			<div className={styles.containerForScroll}>
				<h1 className={styles.title}>{title}</h1>
				<div className={styles.breadcrumbs}>
					<Link to="/" className={styles.backLink} title="Home page">
						Home
					</Link>
					<p>{'>'}</p>
					{selectedCategory}
				</div>
			</div>
		);
	}

	return (
		<div className={styles.containerForScroll}>
			<h1 className={styles.title}>{title}</h1>
			<div className={styles.scroll} ref={scrollContainerRef}>
				<div className={styles.container}>
					{categoriesNames.map((category) => (
						<Link
							title={`Go to ${category} category`}
							to={
								category === FEATURED_CATEGORY_NAME
									? '/'
									: `/category_${getCategorySlugByName(category)}`
							}
							key={category}
							className={cn(styles.category, {
								[styles.active]: category === selectedCategory,
							})}
						>
							{category}
						</Link>
					))}
				</div>
			</div>
			{scrollLeftVisible && !isTouchDevice && (
				<button
					className={cn(styles.scrollButton, styles.scrollButtonLeft)}
					onClick={handleScrollLeft}
				>
					<img src={arrowIcon} alt="scroll left" />
				</button>
			)}
			{scrollRightVisible && !isTouchDevice && (
				<button
					className={cn(styles.scrollButton, styles.scrollButtonRight)}
					onClick={handleScrollRight}
				>
					<img src={arrowIcon} alt="scroll right" />
				</button>
			)}
		</div>
	);
};
