import {init, logEvent, identify, Identify} from '@amplitude/analytics-browser';
import {AMPLIRUDE_KEY_B2B, AMPLITUDE_KEY} from './constants';
import {convertBotName} from '../common/utils';

class AmplitudeApi {
	isB2B?: boolean;

	private init(isB2B: boolean) {
		const key = isB2B ? AMPLIRUDE_KEY_B2B : AMPLITUDE_KEY;
		init(key);
	}

	setMode(isB2B: boolean) {
		this.init(isB2B);

		this.isB2B = isB2B;
		const event = new Identify().set(
			'web_platform',
			this.isB2B ? 'b2b_landing' : 'b2c_landing'
		);

		if (!isB2B) {
			const ver = this.getVerCookie() || 'v1';
			event.set('abLandingVersion', ver);
		}
		identify(event);
	}

	getVerCookie = (): string | null => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; fsd_pok=`);
		if (parts.length === 2) {
			const data = parts.pop()?.split(';').shift();
			if (data) {
				return decodeURIComponent(data);
			}
		}
		return null;
	};

	message(
		bot: string,
		type: 'common' | 'sexting',
		source: 'smart_reply' | 'user',
		split_response: string,
		split_smart_replies: string
	) {
		const data = {
			chat_id: convertBotName(bot),
			message_type: type,
			source,
			split_response,
			split_smart_replies,
		};

		logEvent('Message_by_user', data);
	}

	appLink(isGoogle: boolean) {
		if (isGoogle) {
			logEvent('download_android_app_pressed');
		} else {
			logEvent('download_ios_app_pressed');
		}
	}

	toB2BPressed() {
		logEvent('b2b_link_pressed');
	}

	changeChat(bot: string) {
		const data = {
			chat_id: convertBotName(bot),
		};

		logEvent('open_chat_pressed', data);
	}
}

export const ampliApi = new AmplitudeApi();
