import {useContext} from 'react';
import styles from './GetPremium.module.css';
import cn from 'classnames';
import crown from './images/crown.svg';
import {AppContext} from '../../App';
import {InButton} from '../../common-lib/src/components/InButton/InButton';

export const GetPremiumBtn = ({
	className = '',
}: React.HTMLAttributes<HTMLDivElement>) => {
	const {handleLoginOrPaywall} = useContext(AppContext);

	return (
		<InButton
			id="premium-bot"
			isFilled
			className={cn(styles.btn, className)}
			onClick={handleLoginOrPaywall}
		>
			<img src={crown} alt="plus" />
			<p>Get Premium</p>
		</InButton>
	);
};
