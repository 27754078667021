import {api, Message} from '../../common-lib/src/api/Api';
import {
	ChatFirebase,
	ChatFirebaseCreate,
	MessageFirebase,
	MessageFirebaseCreate,
} from './usersApi.types';

export type IncomeChat = {
	botId: number;
	botName: string;
	chatId: string;
	messages: Message[];
};

type MessageConverterInput = {
	botId: number;
	botName: string;
	userId: string;
	message: Message;
};

export class FrbConverter {
	private static hasMedia = (message: MessageFirebaseCreate) =>
		!!message.mediaResponse?.mediaId;
	private static isBlured = (message: MessageFirebaseCreate) =>
		this.hasMedia(message) && !message.mediaResponse?.mediaUrl;
	private static getImg = (message: MessageFirebaseCreate) => {
		if (this.isBlured(message)) {
			const rand = Math.floor(Math.random() * 5) + 1;
			return `/blur ${rand}.webp`;
		}
		return message.mediaResponse?.mediaType === 'image'
			? message.mediaResponse.mediaUrl
			: undefined;
	};
	private static getVideo = (message: MessageFirebaseCreate) => {
		if (this.isBlured(message)) {
			return `/blur.webm`;
		}
		return message.mediaResponse?.mediaType === 'video'
			? message.mediaResponse.mediaUrl
			: undefined;
	};
	private static extractTimestamp = (message: MessageFirebase) => {
		//@ts-ignore
		const ts = message.createdAt || message.timeInterval;
		if (!ts) {
			return Date.now();
		}

		const tsStr = ts.toString();
		const splited = tsStr.split('.');
		if (splited.length === 1) {
			return parseInt(tsStr);
		}
		const sec = splited[0];
		let ms = splited[1];
		if (ms.length > 3) {
			ms = ms.slice(-3);
		}
		const finStr = `${sec}${ms}`;
		return parseInt(finStr);
	};
	static toMessage = (message: MessageFirebase): Message => {
		return {
			id: message.id,
			turn: message.type,
			message: message.text || '',
			image: this.getImg(message),
			video: this.getVideo(message),
			blured: this.isBlured(message),
			media_response: message.mediaResponse
				? {
						media_id: message.mediaResponse.mediaId,
						media_resolution: message.mediaResponse.mediaResolution,
						media_type: message.mediaResponse.mediaType,
						media_url: message.mediaResponse.mediaUrl,
				  }
				: undefined,
			split: message.split,
			edited: message.isEdited,
			timestamp: this.extractTimestamp(message),
			isDeleted: message.isDeleted,
			reaction: message.reaction,
			isSexting: message.isSexting,
		};
	};
	static toFrbMessage = ({
		botId,
		botName,
		userId,
		message,
	}: MessageConverterInput): MessageFirebaseCreate => {
		return {
			botName,
			mediaResponse: message.media_response
				? {
						mediaId: message.media_response.media_id,
						mediaResolution: message.media_response.media_resolution,
						mediaType: message.media_response.media_type,
						mediaUrl: message.media_response.media_url,
				  }
				: undefined,
			split: message.split,
			text: message.message || '',
			createdAt: (message.timestamp || Date.now()) / 1000,
			type: message.turn,
			isEdited: !!message.edited,
			isDeleted: !!message.isDeleted,
			reaction: message.reaction,
			isSexting: !!message.isSexting,
			senderId: message.turn === 'user' ? userId : botId.toString(),
			senderName: message.turn === 'user' ? null : botName,
		};
	};
	static toContext = (chat: ChatFirebase): Message[] => {
		return chat.messages.map(this.toMessage);
	};
	static toFrbChat = (chats: IncomeChat[]): ChatFirebaseCreate[] => {
		const userId = api.getUserId();
		return chats.map(({chatId, botId, botName, messages}) => {
			const chatMessages = messages.map((message) =>
				this.toFrbMessage({
					userId,
					botId,
					botName,
					message,
				})
			);
			const data = {
				id: chatId,
				messages: chatMessages,
			};
			return data;
		});
	};
}
