import {useEffect, useState} from 'react';

type UseIsVisibleProps = {
	el?: HTMLElement | null;
};

export const useIsVisible = ({el}: UseIsVisibleProps) => {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{
				threshold: 0,
				rootMargin: `0px`,
			}
		);
		if (el) {
			observer.observe(el);
		}
		return () => {
			if (el) {
				observer.unobserve(el);
				setIntersecting(false);
			}
		};
	}, [el]);
	return isIntersecting;
};
