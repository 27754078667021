import {Modal} from '@mui/material';
import styles from './StorePopup.module.css';
import closeImg from '../../images/close.svg';
import promoImg from './images/promo.webp';
import checkImg from './images/check.svg';
import qrImg from './images/qr.webp';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {StoreBtns} from '../../common-lib/src/components/StoreBtns/StoreBtns';
import {isAndroid, isIOS} from '../../common-lib/src/utils';
import {useContext} from 'react';
import {AppContext} from '../../App';
import {GtagApi} from '../../api/gtagApi';

type Props = {
	openned: boolean;
	onClose: () => void;
};

export const StorePopup = ({openned, onClose}: Props) => {
	const {isMobile} = useContext(AppContext);

	const handleBtnClick = () => {
		GtagApi.appBannerLinkClick();
		if (isAndroid) {
			window.open(
				'https://play.google.com/store/apps/details?id=ai.botify.app',
				'_blank'
			);
		} else if (isIOS) {
			window.open(
				'https://apps.apple.com/us/app/botify-ai-create-chat-bot/id1566710178',
				'_blank'
			);
		}
	};

	const handleLinkClick = () => {
		GtagApi.appBannerLinkClick();
	};

	return (
		<Modal open={openned} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.container}>
					<img src={promoImg} alt="promo" className={styles.image} />
					<div className={styles.content}>
						<h2 className={styles.title}>Get the App</h2>
						<ul className={styles.list}>
							<li className={styles.li}>
								<img src={checkImg} alt="check" />
								<p className={styles.p}>Available for free</p>
							</li>
							<li className={styles.li}>
								<img src={checkImg} alt="check" />
								<p className={styles.p}>Proactive messages from bots</p>
							</li>
							<li className={styles.li}>
								<img src={checkImg} alt="check" />
								<p className={styles.p}>Messages and pics history</p>
							</li>
						</ul>
						{(isAndroid || isIOS) && isMobile ? (
							<InButton
								id="get-app"
								isFilled
								className={styles.button}
								onClick={() => handleBtnClick()}
							>
								Get for free
							</InButton>
						) : (
							<div className={styles.storeBtns}>
								{!isMobile && (
									<img src={qrImg} alt="qr" className={styles.qr} />
								)}
								<StoreBtns onClick={handleLinkClick} />
							</div>
						)}
					</div>
					<img
						src={closeImg}
						alt="close"
						className={styles.close}
						onClick={() => onClose()}
					/>
				</div>
			</div>
		</Modal>
	);
};
