import {PWListProps} from './types';
import cn from 'classnames';
import ultraImg from './images/ultra.png';

export const PWList = ({items, isUltraSelected}: PWListProps) => {
	return (
		<ul className="paywall-modal__list">
			{items.map((item, index) => (
				<li
					key={index}
					className={cn('paywall-modal__list-row', {
						'paywall-modal__list-row_opacity': item.forUlta && !isUltraSelected,
					})}
				>
					{/* <img
						src={item.img}
						alt="check"
						className="paywall-modal__list-icon"
					/> */}
					<p className="paywall-modal__list-text-img">{item.img}</p>
					<p className="paywall-modal__list-text">{item.text}</p>
					{item.forUlta && (
						<img
							src={ultraImg}
							alt="Ultra label"
							className="paywall-modal__list-ultra"
						/>
					)}
				</li>
			))}
		</ul>
	);
};
