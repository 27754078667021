import {Link, useNavigate} from 'react-router-dom';
import {CategoryAndBots} from '../../api/apiHelper';
import {MainContainer} from '../Main/MainContainer';
import styles from './styles.Category.module.css';
import {useContext, useEffect, useState} from 'react';
import {BotData, BotDataWithLastMessage} from '../../api/types';
import {AppContext} from '../../App';

type Props = {
	category: CategoryAndBots;
};

export const VisitedCategory = ({category}: Props) => {
	const {data} = category;
	const [botsWithMessage, setBotsWithMessage] = useState<
		BotDataWithLastMessage[]
	>([]);
	const {visitedBots, loading} = useContext(AppContext);

	const navigate = useNavigate();
	const handleTileClick = (bot: BotData) => {
		navigate(`/bot_${bot.id}/chat`);
	};

	useEffect(() => {
		console.log(visitedBots);
		console.log(loading);
		if (visitedBots.length === 0 && !loading) {
			navigate('/');
		}

		setBotsWithMessage(visitedBots);
	}, [loading, visitedBots]);

	return (
		<MainContainer
			headerContent={
				<div className={styles.headerContent}>
					<Link to="/" className={styles.backLink} title="Home page">
						Home
					</Link>
					<p>{'>'}</p>
					{data.attributes.name}
				</div>
			}
			isLoading={loading}
		>
			<div className={styles.visited}>
				<h2 className={styles.visitedHeader}>{data.attributes.name}</h2>
				{botsWithMessage.map((bot) => (
					<div
						key={bot.id}
						className={styles.visitedRow}
						onClick={() => handleTileClick(bot)}
					>
						<img
							src={bot.attributes.avatarUrl}
							alt="bot avatar"
							className={styles.visitedAvatar}
						/>
						<div className={styles.visitedText}>
							<p className={styles.visitedName}>{bot.attributes.name}</p>
							{bot.last_message && (
								<p className={styles.visitedMessage}>
									{bot.last_message?.text}
								</p>
							)}
						</div>
					</div>
				))}
			</div>
		</MainContainer>
	);
};
