import {BotData} from '../../../../api/types';
import {UserInfo} from '../../../../api/usersApi/usersApi.types';
import {Message, ChatResponse} from '../../../../common-lib/src/api/Api';
import {AccessLevel} from '../../../../common-lib/src/api/Api.types';
import {convertBase64ToImage} from '../../../../common-lib/src/utils';

export const replaceMultipleNewlines = (str: string) => {
	return str.replace(/\n{3,}/g, '\n\n');
};

export const getChatType = (bot: BotData) => {
	if (!bot.attributes.firebaseUserId) {
		return 'default';
	}
	if (bot.attributes.isOwner) {
		return 'custom';
	}
	return 'custom_shared';
};

export const getSplit = (context: Message[]) => {
	return [...context].reverse().find((m) => m.turn === 'bot')?.split;
};

export const extractImageFromResponse = (res: ChatResponse) => {
	if (res.base64_image) {
		return convertBase64ToImage(res);
	}
	if (res.media_response?.media_type === 'image') {
		if (res.media_response.media_url) {
			return res.media_response.media_url;
		}
		if (res.media_response.media_id) {
			const rand = Math.floor(Math.random() * 5) + 1;
			return `/blur ${rand}.webp`;
		}
	}
	return null;
};

export const extractVideoFromResponse = (res: ChatResponse) => {
	if (res.media_response?.media_type === 'video') {
		if (res.media_response.media_url) {
			return res.media_response.media_url;
		}
		if (res.media_response.media_id) {
			return `/blur.webm`;
		}
	}
	return null;
};

const isBlured = (res: ChatResponse) => {
	return !res.base64_image && !res.media_response?.media_url;
};

export const prepareMessageFromResponse = (res: ChatResponse) => {
	const image = extractImageFromResponse(res);
	const video = extractVideoFromResponse(res);
	const message: Message = {
		message: res.response,
		turn: 'bot',
		split: res.split,
		timestamp: Date.now(),
		isSexting: !!res.is_user_msg_sexting,
		reaction: null,
	};
	if (image) {
		message.image = image;
		message.base64_image = res.base64_image!;
		message.blured = isBlured(res);
	}
	if (video) {
		message.video = video;
		message.blured = isBlured(res);
	}
	if (res.media_response?.media_id) {
		message.media_response = res.media_response;
	}

	return message;
};

export const checkAcessLevel = (user: UserInfo | null, level: AccessLevel) => {
	const levels: AccessLevel[] = ['basic', 'premium', 'ultra'];
	const userLevel = user?.accessLevel || 'basic';
	const userAccessLevelIndex = levels.indexOf(userLevel);
	const llmAccessLevelIndex = levels.indexOf(level);
	return userAccessLevelIndex >= llmAccessLevelIndex;
};
