import {VoiceDataType, voicesData} from '../../../data/voicesData';
import './VoiceSelectorRow.css';
import {useVoiceSelector} from './useVoiceSelector';
import arrowImg from '../images/arrow.svg';
import arrowDark from '../images/arrow-dark.svg';
import {useEffect, useState} from 'react';
import {VoiceRow} from '../../VoiceRow/VoiceRow';
import {DoubleSwitcher} from '../../DoubleSwitcher/DoubleSwitcher';
import cn from 'classnames';
import wave from '../images/wave2.svg';

type VoiceSelectorProps = {
	onSelect: (voice: VoiceDataType) => void;
	selectedVoice: VoiceDataType | null;
	withIcon?: boolean;
	position?: 'top' | 'bottom';
};

export const VoiceSelectorRow = ({
	onSelect,
	selectedVoice,
	withIcon = false,
	position = 'bottom',
}: VoiceSelectorProps) => {
	const [opened, setOpened] = useState(false);
	const {playing, setPlaying} = useVoiceSelector();
	const [isFemine, setIsFemine] = useState<boolean>(
		selectedVoice?.isFemale || true
	);
	const [isForceChanged, setIsForceChanged] = useState<boolean>(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		//@ts-ignore
		if (!e.target.closest('.voice-selector-row__container')) {
			setOpened(!opened);
		}
	};

	const handleClose = (e: MouseEvent) => {
		//@ts-ignore
		if (
			!(e.target as HTMLElement)?.closest('.voice-selector-row') &&
			!(e.target as HTMLElement)?.classList?.contains('voice-select')
		) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, [opened]);

	useEffect(() => {
		if (isForceChanged) {
			onSelect(
				voicesData.find((voice) => voice.isFemale === isFemine) as VoiceDataType
			);
		}
	}, [isFemine]);

	const text = withIcon ? 'Voice' : 'Voice:';

	return (
		<div className="voice-selector-row" onClick={handleClick}>
			{withIcon && (
				<img src={wave} alt="wave" className="voice-selector-row__icon" />
			)}
			<span className="voice-selecotr-row__label">{text}</span>
			<p
				className={cn(
					'voice-selector-row__name',
					withIcon ? 'voice-selector-row__name_right' : ''
				)}
			>
				{selectedVoice?.name}
			</p>
			<img
				src={withIcon ? arrowDark : arrowImg}
				alt="arrow"
				className={cn(
					`voice-selector-row__arrow`,
					opened ? 'voice-selector-row__arrow_revert' : '',
					withIcon ? 'voice-selector-row__arrow_right' : ''
				)}
			/>
			{opened && (
				<div
					className={cn(
						'voice-selector-row__container',
						position === 'top'
							? 'voice-selector-row__container_top'
							: 'voice-selector-row__container_bottom'
					)}
				>
					<DoubleSwitcher
						left="Femine"
						right="Masculine"
						isLeft={isFemine}
						onSwitch={(isLeft) => {
							setIsFemine(isLeft);
							setIsForceChanged(true);
						}}
						className="voice-type-switcher"
					/>
					{voicesData
						.filter((voice) => voice.isFemale === isFemine)
						.map((voice) => {
							return (
								<VoiceRow
									key={voice.name}
									voice={voice}
									selected={selectedVoice?.name === voice.name}
									onSelect={() => onSelect(voice)}
									isPlaying={playing?.name === voice.name}
									onPlay={setPlaying}
								/>
							);
						})}
				</div>
			)}
		</div>
	);
};
