import {isLocalEnv} from './utils';

type Config = {
	adminBackend: string;
	bothubBackend: string;
	b2bUrl: string;
	thUrl: string;
	adminFrontend: string;
	stripeToken: string;
};
const DevConfig: Config = {
	adminBackend: 'https://api.exh.ai/admin/dev',
	bothubBackend: 'https://api.exh.ai/bothub/dev',
	b2bUrl: 'https://exh-dev.web.app',
	thUrl: 'https://heads-exh.web.app/',
	adminFrontend: 'https://exh-admin-dev.web.app',
	stripeToken:
		'pk_test_51LPxbYJINvIZ8ooBvRMspBX2E6kamb75XBqwDOU291IlZDwRFHI18r3P9FiF0apB7TxyYGZvD8JmpJtaslPYGxgR00HMWB3Ti4',
};

const LocalConfig: Config = {
	adminBackend: 'http://localhost:3210',
	bothubBackend: 'http://localhost:3310',
	b2bUrl: 'http://localhost:3000',
	thUrl: 'http://localhost:3006',
	adminFrontend: 'http://localhost:3001',
	stripeToken:
		'pk_test_51LPxbYJINvIZ8ooBvRMspBX2E6kamb75XBqwDOU291IlZDwRFHI18r3P9FiF0apB7TxyYGZvD8JmpJtaslPYGxgR00HMWB3Ti4',
};

const ProdConfig: Config = {
	adminBackend: 'https://api.exh.ai/admin',
	bothubBackend: 'https://api.exh.ai/bothub',
	b2bUrl: 'https://exh.ai',
	thUrl: 'https://talkinghead.ai',
	adminFrontend: 'https://admin.exh.ai',
	stripeToken:
		'pk_live_51LPxbYJINvIZ8ooBkdaB9PBSREAPS1HisHYWNhdESerH0j1oRP5zzHqapHg8WjQc4YwFsKlmOZtIggfgnzdIQvdc00BnLlLmmv',
};

export const getAdminApiUrl = () => {
	if (isLocalEnv()) {
		return LocalConfig.adminBackend;
	}
	if (
		window.location.href.includes(DevConfig.adminFrontend) ||
		window.location.href.includes(DevConfig.thUrl) ||
		window.location.href.includes(DevConfig.b2bUrl)
	) {
		return DevConfig.adminBackend;
	}
	return ProdConfig.adminBackend;
};

export const getBothubApiUrl = () => {
	if (isLocalEnv()) {
		return LocalConfig.bothubBackend;
	}
	return ProdConfig.bothubBackend;
};

export const getB2BSiteUrl = () => {
	if (isLocalEnv()) {
		return LocalConfig.b2bUrl;
	}
	if (window.location.href.includes(DevConfig.adminFrontend)) {
		return DevConfig.b2bUrl;
	}
	return ProdConfig.b2bUrl;
};

export const getTHSiteUrl = () => {
	if (isLocalEnv()) {
		return LocalConfig.thUrl;
	}
	if (window.location.href.includes(DevConfig.adminFrontend)) {
		return DevConfig.thUrl;
	}
	return ProdConfig.thUrl;
};

export const getAdminSiteUrl = () => {
	if (isLocalEnv()) {
		return LocalConfig.adminFrontend;
	}
	if (
		window.location.href.includes(DevConfig.b2bUrl) ||
		window.location.href.includes(DevConfig.thUrl)
	) {
		return DevConfig.adminFrontend;
	}
	return ProdConfig.adminFrontend;
};

export const getStripeToken = () => {
	if (isLocalEnv()) {
		return LocalConfig.stripeToken;
	}
	if (window.location.href.includes(DevConfig.adminFrontend)) {
		return DevConfig.stripeToken;
	}
	return ProdConfig.stripeToken;
};
