import {Dialog, Slide} from '@mui/material';
import styles from './TextAreaDialog.module.css';
import {TransitionProps} from 'notistack';
import React, {useEffect, useState} from 'react';
import backImg from '../images/back.svg';
import {TextArea} from '../../../../common-lib/src/components/TextArea/TextArea';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {DialogType, countPercantageByType} from '../utils';
import cn from 'classnames';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
	openned: boolean;
	onClose: () => void;
	isLoading: boolean;
	title: string;
	placeholder: string;
	onSubmit: (text: string) => void;
	value: string;
	type: DialogType;
	error: boolean;
};

export const TextAreaDialog = ({
	openned,
	onClose,
	isLoading,
	title,
	placeholder,
	onSubmit,
	value,
	type,
	error,
}: Props) => {
	const [innerValue, setInnerValue] = useState(value || '');
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		setShowError(error);
	}, [error]);

	useEffect(() => {
		setInnerValue(value);
	}, [value]);

	const percentage = countPercantageByType(type, innerValue.length);

	const handleSubmitClick = () => {
		onSubmit(innerValue);
	};

	const handleChangeValue = (v: string) => {
		setInnerValue(v);
		setShowError(false);
	};

	return (
		<Dialog
			fullScreen
			open={openned}
			onClose={onClose}
			TransitionComponent={Transition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				boxSizing: 'border-box',
				width: '100%',
			}}
			className={styles.dialog}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<img
						src={backImg}
						alt="back"
						onClick={onClose}
						className={styles.back}
					/>
					<div className={styles.headerCol}>
						<h2 className={styles.title}>{title}</h2>
						<div className={styles.box}>
							<div className={styles.progress}>
								<div
									style={{width: `${percentage}%`}}
									className={styles.rectangle}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.content}>
					<TextArea
						maxLength={10000}
						placeholder={placeholder}
						className={styles.textArea}
						onChange={handleChangeValue}
						value={innerValue}
						showError={showError}
						disabled={isLoading}
					/>
					{showError && (
						<p className={styles.error}>
							Inappropriated content. Please, change text
						</p>
					)}
					<InButton
						className={cn(styles.btn)}
						id="dialog-submit"
						isDisabled={!innerValue || showError}
						onClick={handleSubmitClick}
						loading={isLoading}
					>
						{isLoading ? <>Loading</> : <>Done</>}
					</InButton>
				</div>
			</div>
		</Dialog>
	);
};
