import styles from './Header.module.css';
import logo from '../../images/header-logo.svg';
import searchImg from '../../images/search.svg';
import searchDeskImg from '../../images/search-desk.svg';
import qrImg from './images/qr.svg';
import {useNavigate} from 'react-router-dom';
import {useContext, useState} from 'react';
import {SearchPage} from '../SearchPage/SearchPage';
import {AppContext} from '../../App';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {Link} from 'react-router-dom';
import {ProfileOptionsList} from '../ProfileOptionsList/ProfileOptionsList';
import {GtagApi} from '../../api/gtagApi';

export const HeaderContainer = ({
	children,
	nologo,
}: React.PropsWithChildren<{nologo?: boolean}>) => {
	const navigate = useNavigate();
	const navigateToHome = () => {
		navigate('/');
	};

	return (
		<header className={styles.header}>
			{!nologo && (
				<Link to="/" title="Home page" className={'header__logo'}>
					<img
						src={logo}
						alt="Botify Ai"
						onClick={navigateToHome}
						className={styles.logo}
					/>
				</Link>
			)}
			{children}
		</header>
	);
};

export const Header = () => {
	const [isSearchOpened, setIsSearchOpened] = useState(false);
	const {isMobile} = useContext(AppContext);
	return (
		<HeaderContainer>
			<>
				{isMobile ? (
					<MobileSearch setIsSearchOpened={setIsSearchOpened} />
				) : (
					<DesktopSearch setIsSearchOpened={setIsSearchOpened} />
				)}
				<SearchPage
					isOpened={isSearchOpened}
					onClose={() => setIsSearchOpened(false)}
				/>
			</>
		</HeaderContainer>
	);
};

type SearchProps = {
	setIsSearchOpened: (v: boolean) => void;
};

const DesktopSearch = ({setIsSearchOpened}: SearchProps) => {
	const {setIsPromoOpened, setIsLoginOpened, user, isLoadingUser} =
		useContext(AppContext);

	const handleClick = () => {
		GtagApi.appBannerAppear();
		setIsPromoOpened(true);
	};

	const shouldShowSignIn = !user || user.isAnon;
	return (
		<div className={styles.row}>
			<div className={styles.searchRow} onClick={() => setIsSearchOpened(true)}>
				<img className={styles.search} src={searchDeskImg} alt="search" />
				<p className={styles.searchText}>Search by name</p>
			</div>
			<InButton
				id="getApp"
				isFilled
				className={styles.getApp}
				onClick={handleClick}
			>
				<img className={styles.getAppImg} src={qrImg} alt="qr" />
				Get App
			</InButton>
			{shouldShowSignIn && !isLoadingUser && (
				<InButton
					id="signIn"
					className={styles.signIn}
					onClick={() => setIsLoginOpened(true)}
				>
					Sign In
				</InButton>
			)}
			{!shouldShowSignIn && !isLoadingUser && <ProfileOptionsList />}
		</div>
	);
};

const MobileSearch = ({setIsSearchOpened}: SearchProps) => {
	const {setIsLoginOpened, user, isLoadingUser} = useContext(AppContext);
	const shouldShowSignIn = !user || user.isAnon;
	return (
		<div className={styles.row}>
			<img
				className={styles.search}
				src={searchImg}
				alt="search"
				onClick={() => setIsSearchOpened(true)}
			/>
			{shouldShowSignIn && !isLoadingUser && (
				<InButton
					id="signIn"
					className={styles.signInMobile}
					onClick={() => setIsLoginOpened(true)}
				>
					Sign In
				</InButton>
			)}
			{!shouldShowSignIn && !isLoadingUser && <ProfileOptionsList />}
		</div>
	);
};
