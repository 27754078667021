import {styled, Theme} from '@mui/material/styles';
import Switch, {SwitchProps} from '@mui/material/Switch';

const getStyles = (theme: Theme, withLabel = false) => {
	const widht = withLabel ? 52 : 42;
	const transform = withLabel ? 'translateX(26px)' : 'translateX(16px)';
	const styles: any = {
		width: widht,
		height: 26,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform,
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: '#5A34F0',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#5A34F0',
				border: '6px solid #fff',
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 22,
			height: 22,
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			backgroundColor: '#ABA3BC',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	};
	if (withLabel) {
		styles['& .MuiSwitch-track'] = {
			...styles['& .MuiSwitch-track'],
			'&:before, &:after': {
				content: '""',
				position: 'absolute',
				top: '11px',
				transform: 'translateY(-50%)',
				width: 16,
				height: 16,
				color: 'white',
			},
			'&:before': {
				content: '"on"',
				left: 6,
			},
			'&:after': {
				content: '"off"',
				right: 8,
			},
		};
	}
	return styles;
};

export const Switcher = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => getStyles(theme));

export const SwitcherWithLabel = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => getStyles(theme, true));
