import React, {useEffect, useState} from 'react';

import styles from './ProfilePageBots.module.css';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {webApi} from '../../api/webApi';
import {BotData} from '../../api/types';
import {TilesPlate} from '../TilesRow/TilesRow';
import cn from 'classnames';

const startPage = 1;

type Props = {
	userId: string;
};

export const ProfilePageBots: React.FC<Props> = ({userId}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState(startPage);
	const [isEnd, setIsEnd] = useState(false);
	const [elRef, setElRef] = useState<HTMLDivElement | null>(null);
	const [bots, setBots] = useState<BotData[]>([]);
	const [selectedTab, setSelectedTab] = useState<'bots' | 'favorites'>('bots');

	const fetchData = async (page: number) => {
		try {
			setIsLoading(true);
			if (page === startPage) {
				setBots([]);
			}
			const newBots = await webApi.getUserBots(userId, page);
			if (!newBots.data.length) {
				setIsEnd(true);
				return;
			}

			if (page === startPage) {
				setBots(newBots.data);
			} else {
				setBots((prev) => [...prev, ...newBots.data]);
			}
		} catch (e) {
			console.error('Ошибка при загрузке ботов:', e);
		} finally {
			setIsLoading(false);
		}
	};

	const nextPage = async () => {
		setPage(page + 1);
	};

	useEffect(() => {
		if (page > startPage && !isEnd) {
			fetchData(page);
		}
	}, [page]);

	useEffect(() => {
		if (!bots.length) {
			return;
		}
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					nextPage();
				}
			},
			{threshold: 1}
		);

		if (elRef) {
			observer.observe(elRef);
		}

		return () => {
			if (elRef) {
				observer.unobserve(elRef);
			}
		};
	}, [elRef]);

	useEffect(() => {
		setPage(startPage);
		fetchData(startPage);
	}, []);

	return (
		<div>
			<div className={styles.tabs}>
				<div
					className={cn(styles.tab, {
						[styles.active]: selectedTab === 'bots',
					})}
				>
					Bots
				</div>
			</div>
			<div className={styles.botsContainer}>
				<TilesPlate
					bots={bots}
					categoryData={{
						bots: [],
						data: {
							id: '',
							attributes: {
								name: '',
								sort: 0,
								createdAt: '',
								updatedAt: '',
								publishedAt: '',
								locale: '',
								showBanner: false,
								showOnWeb: false,
								slug: '',
							},
						},
						hideInSwitch: undefined,
					}}
				/>
				{isLoading ? <Spinner withLayout={false} noTopMargin /> : null}
				{!!bots.length && !isEnd && !isLoading && <div ref={setElRef}></div>}
			</div>
		</div>
	);
};
