import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import {AvatarSelectorProps, useAvatarSelector} from '../AvatarSelector';
import styles from './AvatarSelectMobile.module.css';
import cn from 'classnames';
import avatarPlaceholder from '../images/avatarPlaceholder.svg';
import editImg from '../images/edit.svg';

export const AvatarSelectMobile = ({
	showError,
	onGenerateClick,
	value,
	onChange,
	loading,
	avatarData,
	onClick,
}: AvatarSelectorProps & {
	onClick: () => void;
}) => {
	const {handleAvatrInputChange, idleAvatar, fileInputRef} = useAvatarSelector({
		showError,
		onGenerateClick,
		value,
		onChange,
		loading,
		avatarData,
	});

	if (loading) {
		return (
			<div className={styles.avatarSelector}>
				<Spinner withLayout={false} />
			</div>
		);
	}

	return (
		<div className={styles.avatarSelector} onClick={onClick}>
			<div className="upload-photo-modal__outer-container">
				<div className="upload-photo-modal__inner-container">
					{idleAvatar || (
						<img
							src={value || avatarPlaceholder}
							alt="avatar"
							className={cn(styles.avatar, {[styles.avatarError]: showError})}
						/>
					)}
				</div>
				<img src={editImg} alt="edit" className={styles.edit} />
				<input
					type="file"
					className={styles.fileInput}
					ref={fileInputRef}
					accept="image/png, image/jpeg"
					onChange={handleAvatrInputChange}
				/>
			</div>
		</div>
	);
};
