import {Modal} from '@mui/material';
import {useEffect} from 'react';
import {GtagApi} from '../../api/gtagApi';
import {CloseModalBtn} from '../../common-lib/src/components/CloseModalBtn/CloseModalBtn';
import {PWPrivacy} from './PWPrivacy';
import {ContaienerProps} from './types';
import cn from 'classnames';

export const PaywallModalContainer = ({
	openned,
	onClose,
	children,
	className,
}: ContaienerProps) => {
	useEffect(() => {
		if (openned) {
			GtagApi.paywallAppear();
		}
	}, [openned]);

	return (
		<Modal
			className={cn('paywall-modal', className)}
			open={openned}
			onClose={onClose}
		>
			<div className="column paywall-modal__inner">
				{children}
				<PWPrivacy />
				<CloseModalBtn onClose={onClose} />
			</div>
		</Modal>
	);
};
