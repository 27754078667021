import {useRef, useState, useEffect} from 'react';

type Props = {
	showOnTouch?: boolean;
};

export const useScrollBtns = ({showOnTouch}: Props) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
	const [scrollRightVisible, setScrollRightVisible] = useState(true);
	const isTouchDevice = 'ontouchstart' in window;
	const handleScrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollLeft += 200;
		}
	};

	const handleScrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollLeft -= 200;
		}
	};

	useEffect(() => {
		if (scrollContainerRef.current) {
			const container = scrollContainerRef.current;
			const check = () => {
				if (container.scrollLeft > 0) {
					setScrollLeftVisible(true);
				} else {
					setScrollLeftVisible(false);
				}
				if (
					container.scrollLeft <
					container.scrollWidth - container.clientWidth - 10
				) {
					setScrollRightVisible(true);
				} else {
					setScrollRightVisible(false);
				}
			};
			container.addEventListener('scroll', () => {
				check();
			});
			check();
		}
	}, [scrollContainerRef]);

	const canShow = !isTouchDevice || showOnTouch;

	return {
		scrollContainerRef,
		scrollLeftVisible: scrollLeftVisible && canShow,
		scrollRightVisible: scrollRightVisible && canShow,
		handleScrollRight,
		handleScrollLeft,
	};
};
