import backImg from '../images/back-desk.svg';
import otherImg from '../images/other.svg';
import reportImg from '../images/report.svg';
import editImg from '../images/edit.svg';
import shareImg from '../images/share.svg';
import {BotProfileProps} from '../BotPage.types';
import {useBotProfile} from './useBotProfile';
import '../BotPage.css';
import {FeedbackModal} from '../../../../Feedback/Feedback';
import {SharingModal} from '../SharingModal/SharingModal';
import st from './DesktopBotProfile.module.css';
import cn from 'classnames';
import {Spinner} from '../../../../../common-lib/src/components/Spinner/Spinner';
import {InButton} from '../../../../../common-lib/src/components/InButton/InButton';
import {LikeBtn} from '../../../../Tile/LikeBtn/LikeBtn';
import {Link} from 'react-router-dom';
import {ScrollContainer} from '../../../../ScrollButtons/ScrollBtns';

export const MobileBotProfile = (props: BotProfileProps) => {
	const {
		isSharingOpened,
		setIsSharingOpened,
		isOtherActionsOpened,
		handleOpen,
		handleEditClick,
		setIsFeedbackOpened,
		bot,
		isFeedbackOpened,
		handleSubmitReport,
		handleGoChat,
	} = props;

	const {
		isLiked,
		likesCount,
		handleLikeClick,
		handleShareClick,
		isSimilarLoading,
		similarBots,
		isPhotosLoading,
		photos,
		getBotClickLink,
		scrollerRef,
		handlePhotoClick,
	} = useBotProfile(props);

	return (
		<div className={cn('mobile-bot-page', st.mobilePage)}>
			<div className="header">
				<img src={backImg} alt="back" className="back" onClick={handleGoChat} />
				<div className="profile">
					<div className="texts">
						<h1 className="name">Profile</h1>
					</div>
				</div>
				<div className="actions">
					<div className="action" onClick={handleShareClick}>
						<img src={shareImg} alt="share" />
					</div>
					<div className="action" id="bot-actions" onClick={handleOpen}>
						<img src={otherImg} alt="share" />
						{isOtherActionsOpened && (
							<div className="actions__box">
								{bot.attributes.isOwner && (
									<div className="action__row" onClick={handleEditClick}>
										<img src={editImg} alt="edit bot" />
										<p>Edit Bot</p>
									</div>
								)}
								<div
									className="action__row"
									onClick={() => setIsFeedbackOpened(true)}
								>
									<img src={reportImg} alt="report bot" />
									<p className="action__row_text-red">Report Bot</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={st.content}>
				<div className={st.descriptionBlock}>
					<div className={st.row}>
						<img
							src={bot.attributes.avatarUrl}
							alt={bot.attributes.name}
							className={st.avatar}
						/>
						<div className={st.nameBlock}>
							<h2 className={st.name}>{bot.attributes.name}</h2>
							{bot.attributes.username && (
								<Link
									to={`/profile/${bot.attributes.username}`}
									className={st.username}
								>
									@{bot.attributes.username}
								</Link>
							)}
						</div>
					</div>
					<p className={st.description}>{bot.attributes.bio || ''}</p>
					<div className={st.tags}>
						{bot.attributes.tags.data.map((tag, index) => (
							<Link
								to={`/category_${tag.attributes.slug}`}
								key={index}
								className={st.tag}
							>
								{tag.attributes.name}
							</Link>
						))}
					</div>
					<div className={st.botExtra}>
						<LikeBtn
							className={st.likeBtn}
							isLiked={isLiked}
							count={likesCount}
							onClick={handleLikeClick}
						/>
						<InButton
							isFilled
							id="start-chat"
							onClick={handleGoChat}
							className={st.startChat}
						>
							Start Chat
						</InButton>
					</div>
				</div>
				{(isSimilarLoading || !!similarBots.length) && (
					<div className={st.similarRow}>
						<h3 className={cn(st.rowTitle, st.rowTitleMg)}>Similar bots</h3>
						{isSimilarLoading ? (
							<Spinner withLayout={false} />
						) : (
							<ScrollContainer className={st.scrollBtn}>
								<div className={st.similarBots}>
									{similarBots.map((bot, index) => (
										<a
											key={index}
											className={st.similarBot}
											href={getBotClickLink(bot.id)}
										>
											<img
												src={bot.attributes.avatarUrl}
												alt={bot.attributes.name}
												className={st.similarAvatar}
											/>
											<h4 className={st.similarName}>{bot.attributes.name}</h4>
										</a>
									))}
								</div>
							</ScrollContainer>
						)}
					</div>
				)}
				{(isPhotosLoading || !!photos.length) && (
					<div className={cn(st.similarRow, st.similarRowPad)}>
						<h3 className={st.rowTitle}>Photos</h3>
						<div className={st.photos}>
							{photos.map((photo, index) => (
								<img
									key={index}
									src={photo.url}
									alt={`${bot.attributes.name}`}
									className={st.photo}
									onClick={() => handlePhotoClick(photo.url)}
								/>
							))}
							<div ref={scrollerRef} />
						</div>
						{isPhotosLoading && <Spinner withLayout={false} />}
					</div>
				)}
			</div>

			<SharingModal
				isOpened={isSharingOpened}
				onClose={() => setIsSharingOpened(false)}
				link={window.location.href}
			/>
			<FeedbackModal
				isOpen={isFeedbackOpened}
				onClose={() => setIsFeedbackOpened(false)}
				onSubmit={handleSubmitReport}
			/>
		</div>
	);
};
