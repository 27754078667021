import {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../App';
import {MainContainer} from '../Main/MainContainer';
import {placeholder} from './images';
import {ProfileHeader} from './ProfileHeader/ProfileHeader';
import styles from './ProfilePage.module.css';
import {getMessagesQty} from '../../common-lib/src/components/BotTile/MessagesQty.utils';
import {ProfilePageBots} from './ProfilePageBots';
import {webEventsApi} from '../../amplitude/webEvents';
import {usersApi} from '../../api/usersApi/usersApi';
import {UserPublicInfo} from '../../api/usersApi/usersApi.types';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Spinner} from '../../common-lib/src/components';
import {enqueueSnackbar} from 'notistack';

export const ProfilePageMe = () => {
	const {user, isLoadingUser} = useContext(AppContext);

	return <ProfilePage user={user} loading={isLoadingUser} />;
};

type ProfilePageProps = {
	user: UserPublicInfo | null;
	loading: boolean;
};

export const ProfilePageContainer = () => {
	const {user: userMe, isLoadingUser} = useContext(AppContext);
	const location = useLocation();
	const username = location.pathname.split('/profile/')[1];
	const [user, setUser] = useState<UserPublicInfo | null>(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const getUser = async () => {
		try {
			setLoading(true);
			const user = await usersApi.getPublicProfile(username);
			setUser(user);
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Failed to get user profile', {variant: 'error'});
			navigate('/');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!isLoadingUser) {
			if (username === userMe?.username) {
				setUser(userMe);
				setLoading(false);
				navigate(`/profile`);
			} else {
				getUser();
			}
		}
	}, [isLoadingUser, username]);

	return <ProfilePage user={user} loading={isLoadingUser || loading} />;
};

export const ProfilePage = ({user, loading}: ProfilePageProps) => {
	const {user: userMe} = useContext(AppContext);
	const navigate = useNavigate();

	const isCurrentUser = userMe?.id === user?.id;

	useEffect(() => {
		if (loading) {
			return;
		}

		const shouldGoToMain = !user || (userMe?.isAnon && isCurrentUser) || !user;

		if (shouldGoToMain) {
			navigate('/404');
			return;
		}

		webEventsApi.profileAppear();
		webEventsApi.publicProfileAppear({
			is_current_user: userMe?.id === user?.id,
		});
	}, [user, loading]);

	return (
		<MainContainer header={<ProfileHeader user={user} />}>
			{loading && <Spinner withLayout />}
			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.profileContainer}>
						<div className={styles.avatarContainer}>
							<img
								className={styles.avatar}
								alt="User avatar"
								src={user?.avatar || placeholder}
							/>
						</div>
						<div className={styles.infoContainer}>
							{isCurrentUser ? (
								<Link to={`/profile/${user?.username}`} className={styles.name}>
									@{user?.username}
								</Link>
							) : (
								<div className={styles.name}>@{user?.username}</div>
							)}
							<p className={styles.description}>
								{user?.description || 'User description'}
							</p>
						</div>
					</div>
					<div className={styles.statContainer}>
						<div className={styles.statCol}>
							<div className={styles.statTitle}>
								{getMessagesQty(user?.userMessagesCount || 0)}
							</div>
							<div className={styles.statDescription}>Messages sent</div>
						</div>
						<div className={styles.statCol}>
							<div className={styles.statTitle}>
								{getMessagesQty(user?.userBotsCount || 0)}
							</div>
							<div className={styles.statDescription}>Bots created</div>
						</div>
						<div className={styles.statCol}>
							<div className={styles.statTitle}>
								{getMessagesQty(user?.userLikesCount || 0)}
							</div>
							<div className={styles.statDescription}>Likes</div>
						</div>
					</div>
					{!loading && <ProfilePageBots userId={user?.sourceSystemId || ''} />}
				</div>
			</div>
		</MainContainer>
	);
};
