import styles from './CreateBotMobile.module.css';
import checkImg from './images/check.svg';
import arrowImg from './images/arrow.svg';
import cn from 'classnames';

type SettingsRowProps = {
	icon: string;
	text: string;
	onClick: () => void;
	percentage: number;
	showEmptyText?: boolean;
	className?: string;
};

const PercentProgress = ({percentage}: {percentage: number}) => {
	const maxPercentage = Math.min(percentage, 100);
	const valInPercent = 122 * maxPercentage * 0.01;

	return (
		<div className={styles.progressContainer}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="22 22 44 44"
				fill="none"
				style={{transform: 'rotate(-90deg)'}}
				className={styles.circle}
			>
				<circle
					cx="44"
					cy="44"
					r="19.5"
					stroke="black"
					strokeWidth="5"
					strokeLinecap="round"
					fill="none"
				/>
				<circle
					cx="44"
					cy="44"
					r="19.5"
					stroke="url(#radial_stroke)"
					strokeWidth="5"
					strokeLinecap="round"
					fill="none"
					style={{
						strokeDasharray: 122,
						strokeDashoffset: `${122 - valInPercent}px`,
					}}
				/>
				<defs>
					<radialGradient
						id="radial_stroke"
						cx="50%"
						cy="50%"
						r="100%"
						fx="50%"
						fy="50%"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="20%" stopColor="#C139F1" />
						<stop offset="100%" stopColor="#5137F1" />
					</radialGradient>
				</defs>
			</svg>
			{maxPercentage === 100 && (
				<img src={checkImg} alt="check" className={styles.check} />
			)}
		</div>
	);
};

export const SettingsRow = ({
	icon,
	text,
	onClick,
	percentage,
	showEmptyText = false,
	className,
}: SettingsRowProps) => {
	const shouldShowEmptyText = showEmptyText && percentage === 0;
	return (
		<div className={cn(styles.settingsRow, className)} onClick={onClick}>
			<img src={icon} alt="icon" className={styles.settingsIcon} />
			<p className={styles.settingsText}>{text}</p>
			{!shouldShowEmptyText && <PercentProgress percentage={percentage} />}
			{shouldShowEmptyText && <p className={styles.emptyText}>Empty</p>}
			<img src={arrowImg} alt="go" className={styles.arrow} />
		</div>
	);
};
