import {CircularProgress} from '@mui/material';
import {useEffect, useState} from 'react';
import './Spinner.css';

type Props = {
	withLayout?: boolean;
	noTopMargin?: boolean;
};

export const Spinner = ({withLayout = true, noTopMargin = false}: Props) => {
	const [minHeight, setMinHeight] = useState(1000);
	useEffect(() => {
		setMinHeight(document.documentElement.offsetHeight);
	}, []);

	const className = noTopMargin ? 'spinner spinner_no-margin' : 'spinner';
	const innerSpinner = (
		<div className={className}>
			<CircularProgress size={80} color="secondary" />
		</div>
	);
	if (!withLayout) {
		return innerSpinner;
	}

	return (
		<div className="spinner-layout" style={{minHeight}}>
			{innerSpinner}
		</div>
	);
};
