import './CopyTextArea.css';
import copyImg from './images/copy.svg';
import {copyToClipboard} from './utils';

type Props = {
	name?: string;
	value: string;
	className?: string;
	code?: boolean;
};

export const CopyTextArea = ({value, name, className = '', code}: Props) => {
	return (
		<div className={`copy-text-area ${className}`}>
			{name && <p className="copy-text-area__name">{name}:</p>}
			{!code && <p className="copy-text-area__value">{value}</p>}
			{code && (
				<div
					className="copy-text-area__code"
					dangerouslySetInnerHTML={{__html: value}}
				></div>
			)}
			<img
				className="copy-text-area__icon"
				src={copyImg}
				alt="copy"
				onClick={() => copyToClipboard(!!code, value)}
			/>
		</div>
	);
};
