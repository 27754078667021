import {USE_COOKIES_CONSENT_KEY} from './constants';

type CookieType = {
	isAgreed: boolean;
	date: string;
};

export const updateCookie = (key: string, isAgreed = true) => {
	const options = {
		path: '/',
		samesite: 'lax',
		secure: true,
		expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 1000).toUTCString(),
	};

	const value: CookieType = {
		isAgreed,
		date: new Date().toISOString(),
	};

	const valueEn = encodeURIComponent(JSON.stringify(value));
	let updatedCookie = `${key}=${valueEn}`;
	for (const propName in options) {
		updatedCookie += `; ${propName}`;

		//@ts-ignore
		const propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += `=${propValue}`;
		}
	}
	document.cookie = updatedCookie;

	localStorage.setItem(key, JSON.stringify(value));
};

export const getCookie = (key: string): CookieType | null => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${key}=`);
	if (parts.length === 2) {
		const data = parts.pop()?.split(';').shift();
		if (data) {
			return JSON.parse(decodeURIComponent(data));
		}
	}

	const localStorageValue = localStorage.getItem(key);
	if (localStorageValue) {
		try {
			return JSON.parse(localStorageValue);
		} catch (e) {
			console.log(e);
		}
	}

	return null;
};

export const checkIsAgreed = () => {
	const data = getCookie(USE_COOKIES_CONSENT_KEY);
	if (data) {
		return data.isAgreed;
	}
	return false;
};
