import {Message} from '../common-lib/src/api/Api';
import {
	LocalStorage,
	IndexedDBStorage,
	AbstractStorage,
} from '../common-lib/src/storage/Storage';

const MAX_MESSAGE = 1000000;
export const RESTORE_TIME_MIN = 0;
const MAX_RECENT_CHATS = 100;

export type TimeLimitInfo = {
	hours: number;
	minutes: number;
	sec: number;
	hoursStr: string;
	minutesStr: string;
	secStr: string;
};

class ChatStorage {
	chatStorage: Record<string, Message[]>;
	visitedStorage: string[];
	firstMessageStart: Date | null;
	messageCount: number;
	messageCountSession: number;
	storage: AbstractStorage;
	messageLimit: number;
	messagesWithoutAuthLimit: number;
	restoreTime: number;
	chatMigrated: boolean;
	freeRegenerations: number;
	regenerationByMessage: Record<string, number>;
	constructor(isLocal: boolean) {
		this.chatStorage = {};
		this.visitedStorage = [];
		this.firstMessageStart = null;
		this.messageCount = 0;
		this.messageCountSession = 0;
		this.freeRegenerations = 0;
		this.chatMigrated = false;
		this.regenerationByMessage = {};
		if (isLocal) {
			this.storage = new LocalStorage();
		} else {
			this.storage = new IndexedDBStorage('botify', 2);
		}

		this.messageLimit = MAX_MESSAGE;
		this.messagesWithoutAuthLimit = 0;
		this.restoreTime = RESTORE_TIME_MIN;

		this.init();
	}

	init = async () => {
		const result = await this.storage.init();
		if (!result) {
			console.warn('Failed to init storage');
			this.storage = new LocalStorage();
			await this.storage.init();
		}
		this.load();
	};

	setLimit = (limit: number) => {
		this.messageLimit = limit;
	};

	setRestoreTime = (time: number) => {
		this.restoreTime = time;
	};

	setMessagesWithoutAuthLimit = (limit: number) => {
		this.messagesWithoutAuthLimit = limit;
	};

	setFreeRegenerations = (limit: number) => {
		this.freeRegenerations = limit;
	};

	getFreeRegenerations = () => {
		return this.freeRegenerations;
	};

	load = async () => {
		const [chat, visited, fmt, count, countSession, chatMigrated, regenerationByMessage] =
			await Promise.all([
				this.storage.get('chat'),
				this.storage.get('visited'),
				this.storage.get('fmt'),
				this.storage.get('count'),
				this.storage.get('countSession'),
				this.storage.get('chatMigrated'),
				this.storage.get('regenerationByMessage'),
			]);
		if (chat) {
			this.chatStorage = JSON.parse(chat as string);
		}

		if (visited) {
			this.visitedStorage = JSON.parse(visited as string);
		}

		if (regenerationByMessage) {
			this.regenerationByMessage = JSON.parse(regenerationByMessage as string);
		}

		if (fmt) {
			this.firstMessageStart = new Date(fmt);
		}

		const countFromChat = Object.values(this.chatStorage).reduce(
			(acc, chat: Message[]) => {
				return acc + chat.filter((x) => x.turn === 'bot').length;
			},
			0
		);

		if (count) {
			const countFromStorage = parseInt(count as string);
			this.messageCount = Math.max(countFromChat, countFromStorage);
		} else {
			this.messageCount = countFromChat;
		}

		if (countSession) {
			this.messageCountSession = parseInt(countSession as string);
		} else {
			this.messageCountSession = 0;
		}

		this.chatMigrated = chatMigrated === 'true';
	};

	save = () => {
		this.storage.set('chat', JSON.stringify(this.chatStorage));
		this.storage.set('visited', JSON.stringify(this.visitedStorage));
		if (this.firstMessageStart) {
			this.storage.set('fmt', this.firstMessageStart.toISOString());
		}
		this.storage.set('count', this.messageCount.toString());
		this.storage.set('countSession', this.messageCountSession.toString());
		this.storage.set('chatMigrated', this.chatMigrated.toString());
		this.storage.set(
			'regenerationByMessage',
			JSON.stringify(this.regenerationByMessage)
		);
	};

	get = (key: string | number) => {
		return this.chatStorage[key.toString()];
	};

	getKeys = () => {
		return Object.keys(this.chatStorage);
	};

	set = (key: string | number, value: any) => {
		this.chatStorage[key.toString()] = value;
		this.save();
	};

	setMessages = (key: string | number, value: Message[]) => {
		this.set(
			key,
			value.map((message) => {
				const newM: Message = {
					...message,
				};
				if (newM.image && newM.base64_image) {
					delete newM.image;
				}
				return newM;
			})
		);
	};

	getChatVisited = () => {
		return this.visitedStorage;
	};

	removeChats = () => {
		this.visitedStorage = this.visitedStorage.slice(0, MAX_RECENT_CHATS);
		Object.keys(this.chatStorage).forEach((key) => {
			if (!this.visitedStorage.includes(key)) {
				delete this.chatStorage[key];
			}
		});
		this.save();
	};

	deleteChat = (id: string) => {
		delete this.chatStorage[id];
		this.visitedStorage = this.visitedStorage.filter((v) => v !== id);
		this.save();
	};

	setChatVisited = (id: string) => {
		if (this.visitedStorage.includes(id)) {
			if (this.visitedStorage[0] === id) {
				return;
			}
			this.visitedStorage = this.visitedStorage.filter((v) => v !== id);
		}
		this.visitedStorage.unshift(id);
		if (this.visitedStorage.length > MAX_RECENT_CHATS) {
			this.removeChats();
		}
		this.save();
	};

	isChatVisited = (id: string) => {
		return this.visitedStorage.includes(id);
	};

	setChatMigrated = () => {
		this.chatMigrated = true;
		this.save();
	};

	restoreChats = (ids: string[]) => {
		this.chatStorage = ids.reduce((acc, id) => {
			acc[id] = this.get(id) || ([] as Message[]);
			return acc;
		}, {} as Record<string, Message[]>);
		this.save();
	};

	message = () => {
		this.messageCount++;
		this.messageCountSession++;

		if (!this.firstMessageStart) {
			this.firstMessageStart = new Date();
			this.save();
			return true;
		} else {
			const now = new Date();
			const diff = now.valueOf() - this.firstMessageStart.valueOf();
			const minutes = diff / (1000 * 60);
			if (minutes >= this.restoreTime) {
				this.messageCountSession = 1;
				this.firstMessageStart = new Date();
				this.save();
				return true;
			}
			if (this.messageCountSession >= this.messageLimit) {
				return false;
			}
			return true;
		}
	};

	isLimitReached = () => {
		if (!this.firstMessageStart) {
			return false;
		}
		const now = new Date();
		const diff = now.valueOf() - this.firstMessageStart.valueOf();
		const minutes = diff / (1000 * 60);

		if (minutes >= this.restoreTime) {
			return false;
		}
		return this.messageCountSession >= this.messageLimit;
	};

	isWithoutAuthLimitReached = () => {
		console.log({
			messageCount: this.messageCount,
			messagesWithoutAuthLimit: this.messagesWithoutAuthLimit,
		});
		return this.messageCount >= this.messagesWithoutAuthLimit;
	};

	getLastMessage = (key: string | number) => {
		const messages = this.get(key);
		if (!messages?.length) {
			return null;
		}
		return messages[messages.length - 1]?.message;
	};

	minutesLeft = () => {
		if (!this.firstMessageStart) {
			return 0;
		}
		const now = new Date();
		const diff = now.valueOf() - this.firstMessageStart.valueOf();
		const minutes = diff / (1000 * 60);
		return this.restoreTime - minutes;
	};

	limitTimeLeft = (): TimeLimitInfo => {
		if (!this.firstMessageStart) {
			return {
				hours: 0,
				minutes: 0,
				sec: 0,
				hoursStr: '00',
				minutesStr: '00',
				secStr: '00',
			};
		}
		const now = new Date();
		const diff = now.valueOf() - this.firstMessageStart.valueOf();
		const seconds = (this.restoreTime * 60 * 1000 - diff) / 1000;
		if (seconds <= 0) {
			return {
				hours: 0,
				minutes: 0,
				sec: 0,
				hoursStr: '00',
				minutesStr: '00',
				secStr: '00',
			};
		}
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const sec = Math.floor(seconds % 60);
		return {
			hours,
			minutes,
			sec,
			hoursStr: hours.toString().padStart(2, '0'),
			minutesStr: minutes.toString().padStart(2, '0'),
			secStr: sec.toString().padStart(2, '0'),
		};
	};

	deleteMessage = (key: number, message: Message) => {
		const messages = this.get(key);
		messages.forEach((m: Message) => {
			if (m.message === message.message && m.timestamp === message.timestamp) {
				m.isDeleted = true;
				m.message = '';
				m.image = undefined;
				m.media_response = undefined;
			}
		});
		this.save();
	};

	setReaction = (key: number, message: Message, reaction: string) => {
		const messages = this.get(key);
		messages.forEach((m: Message) => {
			if (m.message === message.message && m.timestamp === message.timestamp) {
				m.reaction = reaction;
			}
		});
		this.save();
	};

	_setChatStorage = (data: Record<string, Message[]>) => {
		this.chatStorage = data;
		this.visitedStorage = Object.keys(data);
		this.save();
	};

	_getStorage = () => {
		return this.chatStorage;
	};

	_getStorageFromBackup = async () => {
		try {
			const res = await this.storage.get('_chat');
			console.log(JSON.parse(res as string));
			this.chatStorage = JSON.parse(res as string);
			this.visitedStorage = Object.keys(this.chatStorage);
			this.chatMigrated = false;
			this.save();
		} catch (e) {
			console.error(e);
		}
	};

	_resetMigrated = () => {
		this.chatMigrated = false;
		this.save();
	};

	reserveCopyChatStorage = () => {
		this.storage.set('_chat', JSON.stringify(this.chatStorage));
	};

	clearChatStorage = () => {
		this.visitedStorage = [];
		this.chatStorage = {};
		this.save();
	};

	incrementRegenerationByMessage = (messageId?: string) => {
		if (!messageId) {
			return;
		}
		this.regenerationByMessage[messageId] =
			(this.regenerationByMessage[messageId] || 0) + 1;
		this.save();
	};

	getRegenerationByMessage = (messageId?: string) => {
		if (!messageId) {
			return 0;
		}
		return this.regenerationByMessage[messageId] || 0;
	};
}

export const chatStorage = new ChatStorage(false);

//@ts-ignore
window.scs = chatStorage._setChatStorage;
//@ts-ignore
window.gcs = chatStorage._getStorage;
//@ts-ignore
window.restoreChats = chatStorage._getStorageFromBackup;
