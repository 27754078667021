import {getB2BSiteUrl, getTHSiteUrl} from '../common/constants';
import {descriptionSuggestions} from '../data/suggestionsData';

export const redirectToLogin = () => {
	const from = localStorage.getItem('from');
	if (from === 'th') {
		window.location.href = `${getTHSiteUrl()}/#logout`;
	} else {
		window.location.href = `${getB2BSiteUrl()}/#logout`;
	}
};

export const isDevB2B = () =>
	window.location.href.includes('botify-ai') ||
	window.location.href.includes('exh-dev');

export const isDevAdmin = () => window.location.href.includes('b2b-admin');

export const isProdB2B = () =>
	window.location.href.includes('exh.ai') &&
	!window.location.href.includes('admin');

export const isLocalEnv = () => window.location.href.includes('localhost');

export const isBothubEnv = () =>
	window.location.href.includes('localhost:3005') ||
	window.location.href.includes('exh-b2b-v2');

export const isMobileDevice = () => {
	const isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	const isMobileWidht = window.innerWidth < 768;
	return isMobile || isMobileWidht;
};

export const convertBotName = (bot: string) => {
	return (
		bot
			.split(' ')
			.map((x) => x.toLowerCase())
			.join('_') + '_chat_id'
	);
};

export const getShuffledSuggestions = (except = [''], size = 12) =>
	Object.keys(descriptionSuggestions)
		.sort(() => 0.5 - Math.random())
		.filter((key) => !except.includes(key))
		.slice(0, size);

const emailProvidersBlacklist = [
	'gmail.com',
	'yahoo.com',
	'hotmail.com',
	'outlook.com',
	'icloud.com',
	'aol.com',
	'zoho.com',
	'protonmail.com',
	'yahoo.co.uk',
	'yahoo.co.in',
	'yahoo.co.id',
	'yahoo.co.jp',
	'yahoo.co.kr',
	'yahoo.co.th',
	'yahoo.co.za',
	'yahoo.com.ar',
	'yahoo.com.au',
	'yahoo.com.br',
	'yahoo.com.cn',
	'yahoo.com.hk',
	'yahoo.com.mx',
	'yahoo.com.my',
	'yahoo.com.ph',
	'yahoo.com.sg',
	'yahoo.com.tr',
	'yahoo.com.tw',
	'yahoo.com.vn',
	'yahoo.de',
	'yahoo.es',
	'yahoo.fr',
	'yahoo.in',
	'yahoo.it',
	'yahoo.jp',
	'yahoo.ru',
	'yahoo.se',
	'yahoo.uk',
	'yahoo.vn',
	'yahoo.net',
	'yahoo.org',
	'yahoo.edu',
	'yahoo.gov',
	'yahoo.biz',
	'yahoo.info',
	'yahoo.mil',
	'yahoo.name',
	'yahoo.me',
	'yahoo.us',
	'yahoo.eu',
	'yahoo.asia',
	'yahoo.tv',
	'vk.com',
	'mail.ru',
	'yandex.ru',
	'yandex.com',
	'yandex.by',
	'mailfence.com',
	'qq.com',
	'263.com',
	'21cn.com',
	'fun.21cn.com',
];

export const isEmail = (email: string) => {
	return (
		/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
		!emailProvidersBlacklist.includes(email.split('@')[1])
	);
};
