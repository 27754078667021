import cn from 'classnames';
import styles from './AvatarDialog.module.css';
import {MobileDialog} from '../MobileDialog/MobileDialog';
import {
	AvatarSelectorProps,
	useAvatarSelector,
} from '../../AvatarSelect/AvatarSelector';
import avatarPlaceholder from '../../AvatarSelect/images/avatarPlaceholder.svg';
import stick from '../../AvatarSelect/images/stick.svg';
import pics from '../../AvatarSelect/images/pics.svg';
import {Spinner} from '../../../../common-lib/src/components/Spinner/Spinner';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {ChatPhotoStyle} from '../../../../common-lib/src/api/Api.types';

type Props = {
	openned: boolean;
	onClose: () => void;
	onChange: (
		file: File | null,
		value: string | null,
		style: ChatPhotoStyle
	) => void;
} & AvatarSelectorProps;
export const AvatarDialog = ({
	openned,
	onClose,
	onChange,
	showError,
	onGenerateClick,
	loading,
	avatarData,
	value,
}: Props) => {
	const {
		handleAvatarEditClick,
		handleAvatrInputChange,
		idleAvatar,
		fileInputRef,
	} = useAvatarSelector({
		showError,
		onGenerateClick,
		value,
		onChange,
		loading,
		avatarData,
	});

	return (
		<MobileDialog
			title="Avatar"
			openned={openned}
			onClose={onClose}
			contentClassName={styles.content}
		>
			{!loading && (
				<div className={styles.avatarSelector}>
					<div className="upload-photo-modal__outer-container">
						<div className="upload-photo-modal__inner-container">
							{idleAvatar || (
								<img
									src={value || avatarPlaceholder}
									alt="avatar"
									className={cn(styles.avatar, {
										[styles.avatarError]: showError,
									})}
								/>
							)}
						</div>
						<input
							type="file"
							className={styles.fileInput}
							ref={fileInputRef}
							accept="image/png, image/jpeg"
							onChange={handleAvatrInputChange}
						/>
					</div>
					<div className={styles.btnsRow}>
						<div className={styles.block} onClick={handleAvatarEditClick}>
							<img src={pics} alt="chose a file" className={styles.img} />
							<p className={styles.blockText}>Choose a file</p>
						</div>
						<div className={styles.block} onClick={onGenerateClick}>
							<img src={stick} alt="generate-with-ai" className={styles.img} />
							<p className={styles.blockText}>Generate with AI</p>
						</div>
					</div>
					{avatarData && (
						<InButton
							id="select-avatar"
							onClick={onClose}
							className={styles.doneBtn}
							isFilled
						>
							Done
						</InButton>
					)}
				</div>
			)}
			{loading && (
				<div className={styles.avatarSelector}>
					<Spinner withLayout={false} />
				</div>
			)}
		</MobileDialog>
	);
};
