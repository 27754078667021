export const TARGET_LENGTH: Record<DialogType, number> = {
	description: 500,
	appearance: 350,
	firstMessage: 200,
};

export type DialogType = 'description' | 'appearance' | 'firstMessage';

export const countPercantage = (target: number, value: number) => {
	return Math.min((value / target) * 100, 100);
};

export const countDescriptionPercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.description, value);
};
export const countAppearancePercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.appearance, value);
};
export const countFirstMessagePercantage = (value: number) => {
	return countPercantage(TARGET_LENGTH.firstMessage, value);
};
export const countPercantageByType = (type: DialogType, value: number) => {
	switch (type) {
		case 'description':
			return countDescriptionPercantage(value);
		case 'appearance':
			return countAppearancePercantage(value);
		case 'firstMessage':
			return countFirstMessagePercantage(value);
		default:
			throw new Error('Unknown type');
	}
};
