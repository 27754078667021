import {useContext} from 'react';
import {AppContext} from '../../../../../App';
import {BotData} from '../../../../../api/types';
import {useBotPage} from '../hooks/useBotPage';
import {useBotPageMeta} from '../hooks/useBotPageMeta';
import {DesktopBotProfile} from './DesktopBotProfile';
import {BotProfileProps} from '../BotPage.types';
import {MobileBotProfile} from './MobileBotProfile';

type Props = {
	bot: BotData;
};

export const BotProfile = ({bot}: Props) => {
	const {
		handleGoBack,
		handleShareClick,
		isSharingOpened,
		setIsSharingOpened,
		isOtherActionsOpened,
		setIsOtherActionsOpened,
		handleOpen,
		handleEditClick,
		setIsFeedbackOpened,
		isFeedbackOpened,
		handleSubmitReport,
		handleGoChat,
	} = useBotPage(bot);

	useBotPageMeta(bot);

	const {isMobile} = useContext(AppContext);

	const props: BotProfileProps = {
		handleGoBack,
		handleShareClick,
		isSharingOpened,
		setIsSharingOpened,
		isOtherActionsOpened,
		setIsOtherActionsOpened,
		handleOpen,
		handleEditClick,
		setIsFeedbackOpened,
		isFeedbackOpened,
		handleSubmitReport,
		bot,
		handleGoChat,
	};

	return isMobile ? (
		<MobileBotProfile {...props} />
	) : (
		<DesktopBotProfile {...props} />
	);
};
