import './InCheckbox.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

type Props = {
	checked: boolean;
	onChange: (checked: boolean) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export const InCheckbox = ({checked, onChange, ...rest}: Props) => {
	return (
		<div className="incheckbox" onClick={() => onChange(!checked)} {...rest}>
			{checked && (
				<CheckBoxIcon className="incheckbox__icon incheckbox__icon_check" />
			)}
			{!checked && <CheckBoxOutlineBlankIcon className="incheckbox__icon" />}
		</div>
	);
};
