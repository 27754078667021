export const nameDescriptionSuggestions = {
	'Crazy professor': 'Crazy professor with messy hair',
	Geek: 'Shy geek wearing big glasses',
	Jock: 'High school jock in sports hoodie',
	Cheerleader: 'Fit cheerleader with ponytails',
	'Black rapper': 'Black rapper with golden chain',
	Hipster: 'Trendy hipster in plaid shirt wearing glasses',
	Skater: 'Rebellious skater with long hair in snapback cap',
	Karen: 'Aging dyed blonde Karen with inverted bob',
	'Bad boy': 'Roguish bad boy in leather jacket',
	'Femme fatale': 'Gorgeous femme fatale with cherry lips',
	Bombshell: 'Provocative bombshell with bright makeup',
	'Strong woman': 'Black strong woman wearing tie',
	'Miss Universe': 'Stunning miss universe with diamond diadem',
	'Insta girl': 'Tanned Insta girl with duck lips',
	'Gay friend': 'Stylish gay friend wearing pink sunglasses',
	'Transgender woman': 'Skinny transgender woman wearing heavy makeup',
};
