import {CheckCircle} from '@mui/icons-material';
import {InButton} from '../InButton/InButton';
import './SuccessPaymentPage.css';
import {useEffect} from 'react';

type Props = {
	onClick: () => void;
	text: string;
	mainText?: string;
	isContact?: boolean;
	onLoad?: () => void;
};

export const SuccessPaymentPage = ({
	onClick,
	text,
	mainText = 'You have successfully subscribed!',
	isContact = false,
	onLoad,
}: Props) => {
	useEffect(() => {
		try {
			onLoad && onLoad();
		} catch (e) {}
	}, []);
	return (
		<div className="success-payment">
			<p className="success-paymnet__text">{mainText}</p>
			<CheckCircle className="success-payment__check" />
			<InButton
				onClick={onClick}
				className="success-payment__button"
				id="success-payment-go-back"
			>
				{text}
			</InButton>
		</div>
	);
};
