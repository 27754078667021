import {createContext, useCallback, useEffect, useRef, useState} from 'react';
import './App.css';
import {CategoriesInfo} from './api/apiHelper';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {CategoryScreen} from './components/Category/Category';
import {BotData, BotDataWithLastMessage, Product} from './api/types';
import {BotPageContainer} from './components/BotPageContainer/BotPageContainer';
import {VisitedCategory} from './components/Category/VisitedCategory';
import {webApi} from './api/webApi';
import {useDisplaySize} from './common-lib/src/hooks/useDisplaySize';
import {StorePopup} from './components/StorePopup/StorePopup';
import {CreateBotPopup} from './components/CreateBot/CreateBot';
import {SnackbarProvider, enqueueSnackbar} from 'notistack';
import {WebLoginModal} from './components/WebLoginModal/WebLoginModal';
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from 'firebase/remote-config';
import {getApp} from 'firebase/app';
import {chatStorage} from './chatStore/ChatStorage';
import {PaywallModal} from './components/PaywallModal/PaywallModal';
import {UserInfo} from './api/usersApi/usersApi.types';
import {getAuth, signInAnonymously, User} from 'firebase/auth';
import {SuccessPaymentPage} from './common-lib/src/components/SuccessPaymentPage/SuccessPaymentPage';
import {api, Message} from './common-lib/src/api/Api';
import {
	isDevb2c,
	saveUtmParams,
	updateCanonicalUrl,
	updateHreflang,
} from './utils';
import {FOF} from './components/FOF/FOF';
import {
	addVisitedCategory,
	combineCategories,
	compareTags,
	getFeaturedBots,
	removeVisitedCategory,
} from './data/utils';
import {PREDEFINED_TAGS} from './data/predefinedTags';
import {GtagApi} from './api/gtagApi';
import {PWType} from './components/PaywallModal/types';
import {CookieBar} from './components/CookieBar/CookieBar';
import {webEventsApi} from './amplitude/webEvents';
import {isLocalEnv} from './common-lib/src/common/utils';
import {PhotoModal} from './components/PhotoModal/PhotoModal';
import {IncomeChat, FrbConverter} from './api/usersApi/usersApi.utils';
import {MAX_RETRY_TIME_KEY} from './common-lib/src/components/ChatCommon/ChatMain/ChatMediaMessage';
import {LimitModal} from './components/LimitModal/LimitModal';
import {Spinner} from './common-lib/src/components';
import {
	ProfilePageContainer,
	ProfilePageMe,
} from './components/ProfilePage/ProfilePage';
import {EditProfilePage} from './components/ProfilePage/EditProfilePage/EditProfilePage';
import {usersApi} from './api/usersApi/usersApi';

export const SHOULD_OPEN_CREATE_AFTER_LOGIN = 'shouldOpenCreateAfterLogin';

type AppContextType = {
	categories: CategoriesInfo;
	isMobile: boolean;
	isPromoOpened: boolean;
	setIsPromoOpened: (value: boolean) => void;
	featuredBots: BotData[];
	isCreateOpened: boolean;
	setIsCreateOpened: (value: boolean) => void;
	loading: boolean;
	setIsLoginOpened: (value: boolean) => void;
	isLoginOpened: boolean;
	user: UserInfo | null;
	setUser: (value: UserInfo | null) => void;
	isLimitModalOpened: boolean;
	setIsLimitModalOpened: (value: boolean) => void;
	isPaywallModalOpened: boolean;
	setIsPaywallModalOpened: (value: boolean) => void;
	handleLoginOrPaywall: () => void;
	handleLogout: (withReset?: boolean) => Promise<void>;
	setBotToEdit: (value: BotData) => void;
	paywallType: PWType;
	setPaywallType: (value: PWType) => void;
	isPhotoModalOpened: boolean;
	handlePhotoModalOpened: (value: boolean, src?: string) => void;
	isLoadingUser: boolean;
	visitedBots: BotDataWithLastMessage[];
	setVisitedBots: (value: BotDataWithLastMessage[]) => void;
	handleUpdateUser: (data: Partial<UserInfo>) => void;
};

export const AppContext = createContext<AppContextType>({
	categories: {},
	isMobile: true,
	isPromoOpened: false,
	setIsPromoOpened: () => {},
	featuredBots: [],
	isCreateOpened: false,
	setIsCreateOpened: () => {},
	loading: true,
	setIsLoginOpened: () => {},
	isLoginOpened: false,
	user: null,
	setUser: () => {},
	handleUpdateUser: () => {},
	isLimitModalOpened: false,
	setIsLimitModalOpened: () => {},
	isPaywallModalOpened: false,
	setIsPaywallModalOpened: () => {},
	handleLoginOrPaywall: () => {},
	handleLogout: () => Promise.resolve(),
	setBotToEdit: () => {},
	paywallType: 'regular',
	setPaywallType: () => {},
	isPhotoModalOpened: false,
	handlePhotoModalOpened: () => {},
	isLoadingUser: true,
	visitedBots: [],
	setVisitedBots: () => {},
});

export const VISITED_CHAD_ID = 'visited';
export const MAIN_CHAT_ID = -999;
// export const MAIN_CATEGORY_NAME = 'All';
export const FEATURED_CATEGORY_NAME = '🌟 Featured';
export const NEW_CATEGORY_NAME = '🔥 New';
export const MOBILE_MAX_WIDTH = 960;
export const PERIOD_CATEGORIES = ['week', 'month'];
export const FEATURED_CATEGORY_ID = 37;

function App() {
	const {width} = useDisplaySize();
	const isMobile = width < MOBILE_MAX_WIDTH;
	const [categories, setCategories] = useState<CategoriesInfo>(
		combineCategories(PREDEFINED_TAGS, isMobile)
	);
	const [isLoading, setIsLoading] = useState(true);
	const [isPaymentLoading, setIsPaymentLoading] = useState(false);
	const [featuredBots, setFeaturedBots] = useState<BotData[]>([]);
	const {pathname} = useLocation();
	const navigate = useNavigate();
	const location = useLocation();
	const [isPromoOpened, setIsPromoOpened] = useState(false);
	const [isCreateOpened, setIsCreateOpened] = useState(false);
	const [isLoginOpened, setIsLoginOpened] = useState(false);
	const [isLimitModalOpened, setIsLimitModalOpened] = useState(false);
	const [isPaywallModalOpened, setIsPaywallModalOpened] = useState(false);
	const [paywallType, setPaywallType] = useState<PWType>('regular');
	const [user, _su] = useState<UserInfo | null>(null);
	const [isLoadingUser, setIsLoadingUser] = useState(true);
	const [forceCloseLogin, setForceCloseLogin] = useState(false);
	const [isLayoutLoading, setIsLayoutLoading] = useState(false);
	const [botToEdit, setBotToEdit] = useState<BotData | null>(null);
	const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
	const [productsLoading, setProductsLoading] = useState(false);
	const [isPhotoModalOpened, setIsPhotoModalOpened] = useState(false);
	const [photoModalSrc, setPhotoModalSrc] = useState<string | null>(null);
	const [visitedBots, setVisitedBots] = useState<BotData[]>([]);
	const [isChatsLoading, setIsChatsLoading] = useState(true);
	const anonymousUserRef = useRef<User | null>(null);

	const setUser = (user: UserInfo | null) => {
		_su(user);
	};

	useEffect(() => {
		if (!isCreateOpened) {
			setBotToEdit(null);
		}
	}, [isCreateOpened]);

	useEffect(() => {
		updateCanonicalUrl();
		updateHreflang();
	}, [location]);

	const handleUpdateUser = async (data: Partial<UserInfo>) => {
		const updatedUser = {...user} as UserInfo;

		Object.entries(data).forEach(([key, value]) => {
			if (updatedUser[key as keyof UserInfo] !== value) {
				// @ts-ignore
				updatedUser[key as keyof UserInfo] = value as UserInfo[keyof UserInfo];
			}
		});

		setUser(updatedUser);
	};

	const getComponent = useCallback(() => {
		if (isLoadingUser) {
			return <Spinner />;
		}

		if (
			pathname === '/' ||
			pathname.startsWith('&') ||
			pathname === '/search'
		) {
			const mainCategory = Object.values(categories).find(
				(category) => category.data.id === FEATURED_CATEGORY_ID
			);
			if (!mainCategory) {
				return <Spinner />;
			}
			return <CategoryScreen category={mainCategory} />;
		}
		if (pathname.startsWith('/bot_')) {
			return <BotPageContainer />;
		}

		if (isLoading) {
			return <Spinner />;
		}

		if (pathname.startsWith('/category_')) {
			const categorySlug = pathname.split('_')[1];

			const categoryData = Object.values(categories).find(
				(category) => category.data.attributes.slug === categorySlug
			);

			if (!categoryData) {
				navigate('404');
				return null;
			}

			if (categoryData.data.attributes.slug === VISITED_CHAD_ID) {
				console.log('redirect to visited');
				return <VisitedCategory category={categoryData} />;
			}

			return <CategoryScreen category={categoryData} />;
		}

		if (pathname === '/profile') {
			return <ProfilePageMe />;
		}

		if (pathname.startsWith('/profile/')) {
			return <ProfilePageContainer />;
		}

		if (pathname === '/profile-edit') {
			return <EditProfilePage />;
		}

		navigate('404');
		return null;
	}, [categories, pathname, isLoadingUser, isLoading]);

	const fetchProducts = async () => {
		setProductsLoading(true);
		try {
			const response = await usersApi.getProducts();
			setAvailableProducts(response);
		} catch (e) {
			console.error(e);
		} finally {
			setProductsLoading(false);
		}
	};

	const fetchChats = async () => {
		if (!user || !usersApi.getToken()) {
			return;
		}

		setIsChatsLoading(true);

		try {
			const botsResponse = await webApi.chats();
			const bots =
				botsResponse
					?.map((d) => d?.bots?.[0])
					.filter(Boolean)
					.reverse() || [];
			handleSetVisitedBots(bots);
		} catch (e) {
			enqueueSnackbar('Failed to fetch chats', {variant: 'error'});
			console.error(e);
		} finally {
			setIsChatsLoading(false);
		}
	};

	const handleSetVisitedBots = (bots: BotDataWithLastMessage[]) => {
		setVisitedBots(bots);
		chatStorage.restoreChats(bots.map((b) => b.id.toString()));
	};

	const handlePhotoModalOpened = (isOpened: boolean, src?: string) => {
		setIsPhotoModalOpened(isOpened);
		if (src) {
			setPhotoModalSrc(src);
		} else {
			setPhotoModalSrc(null);
		}
	};

	useEffect(() => {
		if (!user) {
			setAvailableProducts([]);
			if (!isLoadingUser && chatStorage.chatMigrated) {
				chatStorage.clearChatStorage();
				removeVisitedCategory(categories);
				setIsChatsLoading(true);
				setTimeout(() => {
					setIsChatsLoading(false);
				}, 0);
			}
		} else {
			fetchProducts();
			fetchChats();
		}
	}, [user]);

	const fetchRemoteConfig = async () => {
		try {
			const app = getApp();
			const remoteConfig = getRemoteConfig(app);
			await fetchAndActivate(remoteConfig);

			try {
				const cdVal = getValue(remoteConfig, 'ios_abMessagesCooldownTimer');
				const cooldown = parseInt(cdVal.asString()) || 0;
				if (cooldown > 0) {
					chatStorage.setRestoreTime(cooldown);
				}
			} catch (e) {
				console.warn('Failed to get cooldown timer:', e);
			}

			try {
				const limVal = getValue(remoteConfig, 'ios_abMessagesLimit');
				const limit = parseInt(limVal.asString());
				if (limit > 0) {
					chatStorage.setLimit(limit);
				}
			} catch (e) {
				console.warn('Failed to get messages limit:', e);
				chatStorage.setLimit(75);
			}

			try {
				const minTimeVal = getValue(
					remoteConfig,
					'web_min_time_between_sessions'
				);
				const minTime = minTimeVal.asNumber();

				if (minTime > 0) {
					webEventsApi.setMinTimeBetweenSession(minTime);
				}
			} catch (e) {
				console.warn('Failed to get min time between sessions:', e);
				webEventsApi.setMinTimeBetweenSession(5);
			}

			try {
				const maxRetryTimeVal = getValue(remoteConfig, 'media_retries_timeout');
				const maxRetryTime = maxRetryTimeVal.asNumber();
				if (maxRetryTime > 0) {
					sessionStorage.setItem(MAX_RETRY_TIME_KEY, maxRetryTime.toString());
				}
			} catch (e) {
				console.warn('Failed to get media retries timeout:', e);
				sessionStorage.setItem(MAX_RETRY_TIME_KEY, '60');
			}

			try {
				const messagesWithoutAuthVal = getValue(
					remoteConfig,
					'messages_without_auth'
				);
				const messagesWithoutAuth = messagesWithoutAuthVal.asNumber();
				if (messagesWithoutAuth > 0) {
					chatStorage.setMessagesWithoutAuthLimit(messagesWithoutAuth);
				}
			} catch (e) {
				console.warn('Failed to get messages without auth:', e);
				chatStorage.setMessagesWithoutAuthLimit(10);
			}

			try {
				const freeRegenerationsVal = getValue(
					remoteConfig,
					'number_of_free_regenerations'
				);
				const freeRegenerations = freeRegenerationsVal.asNumber();
				if (freeRegenerations > 0) {
					chatStorage.setFreeRegenerations(freeRegenerations);
				}
			} catch (e) {
				console.warn('Failed to get number of free regenerations:', e);
				chatStorage.setFreeRegenerations(3);
			}

			webEventsApi.logSession();
		} catch (e) {
			console.error('Failed to initialize Remote Config:', e);
		}
	};

	const fetchUser = async () => {
		try {
			setIsLoadingUser(true);
			const user = await usersApi.me();

			setUser(user);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(`Get user error: ${JSON.stringify(e)}`, {
				variant: 'error',
			});
			webEventsApi.loginError({
				error: '/me error',
			});
		} finally {
			setIsLoadingUser(false);
		}
	};

	useEffect(() => {
		if (visitedBots.length) {
			chatStorage.restoreChats(visitedBots.map((b) => b.id.toString()));
			if (isMobile) {
				setCategories(
					addVisitedCategory(
						categories,
						visitedBots.map((b) => b.id.toString())
					)
				);
			}
		}
	}, [visitedBots, isMobile]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			setIsLoadingUser(true);
			try {
				const userApiToken = usersApi.getToken();
				if (!userApiToken) {
					await handleAnonymouslyLogin();
				}

				await webApi.session();
				setIsLoading(false);

				getFeaturedBots(isMobile).then((response) => {
					setFeaturedBots(response.data ?? []);
				});

				const getTags = () => {
					const fn = () =>
						webApi.getCategoriesPreview().then((response) => {
							const shouldShowVisited =
								isMobile && !user && chatStorage.getChatVisited().length > 0;
							const newCategories = combineCategories(
								response,
								shouldShowVisited
							);
							const isSame = compareTags(newCategories, categories);

							if (!isSame) {
								setCategories(newCategories);
							}
						});
					if (location.pathname !== '/') {
						return fn();
					}
					fn();
					return Promise.resolve();
				};

				await Promise.all([fetchUser(), fetchRemoteConfig(), getTags()]);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
				setIsLoadingUser(false);
			}
		};

		saveUtmParams();
		fetchData();
	}, []);

	const handleContinueClick = () => {
		setIsLimitModalOpened(false);
		if (user && !user.isAnon) {
			setIsPaywallModalOpened(true);
		} else {
			setIsLoginOpened(true);
			sessionStorage.setItem('shouldOpenPaywallAfterLogin', 'true');
		}
	};

	const handleAnonymouslyLogin = async ({
		withAfterLogin = false,
	}: {withAfterLogin?: boolean} = {}) => {
		const auth = getAuth();
		setIsLoadingUser(true);
		await signInAnonymously(auth)
			.then(async (anonLoginUserData) => {
				if (anonLoginUserData.user.uid) {
					api.setUserIdCookie(anonLoginUserData.user.uid);
					anonymousUserRef.current = anonLoginUserData.user;
					localStorage.setItem('logged_anonymously', 'true');
				}
				// @ts-ignore
				if (anonLoginUserData.user?.accessToken) {
					await Promise.all([
						// @ts-ignore
						usersApi.login(anonLoginUserData.user.accessToken, {
							isAnonymous: true,
						}),
						withAfterLogin && webApi.session(),
					]);
					if (withAfterLogin) {
						await usersApi.me();
					}
				}
				setVisitedBots([]);
			})
			.finally(() => {
				setIsLoadingUser(false);
			});
	};

	const handleLogout = async (withReset = true) => {
		setUser(null);
		usersApi.resetToken();
		webApi.resetToken();
		const auth = getAuth();
		await auth.signOut();
		setForceCloseLogin(true);
		setTimeout(() => {
			setForceCloseLogin(false);
		}, 500);
		if (withReset) {
			setVisitedBots([]);
			chatStorage.clearChatStorage();
		}
		await handleAnonymouslyLogin({withAfterLogin: true});
	};

	const handleSuccessLogin = async (data: {email: string; token: string}) => {
		let errorType = '';
		try {
			setIsLayoutLoading(true);
			setIsLoadingUser(true);
			const isAuth = await usersApi.login(data.token).catch((e) => {
				errorType = 'login';
				console.error(e);
				throw e;
			});
			if (!isAuth) {
				enqueueSnackbar('Something went wrong', {variant: 'error'});
				return;
			}

			const meUserData = await usersApi.me().catch((e) => {
				errorType = 'me';
				console.error(e);
				throw e;
			});

			if (
				meUserData &&
				!meUserData.isWebHistorySynced &&
				!chatStorage.chatMigrated
			) {
				const localChatsIds = chatStorage.getKeys();
				const visitedBotsResponse = await webApi.getBotsByIds(localChatsIds);
				const visitedBots = visitedBotsResponse.data;
				const findBotById = (id: string) => {
					const nId = parseInt(id, 10);
					return visitedBots.find((bot) => bot.id === nId);
				};
				const localChats = localChatsIds
					.map((id) => {
						const bot = findBotById(id);
						if (!bot) {
							return null;
						}
						return {
							chatId: bot.attributes.firebaseChatId,
							messages: (chatStorage.get(id) || []) as Message[],
							botId: parseInt(id, 10),
							botName: bot.attributes.name,
						};
					})
					.filter(Boolean) as IncomeChat[];

				const converted = FrbConverter.toFrbChat(localChats);

				try {
					chatStorage.reserveCopyChatStorage();
					await usersApi.migrateHistory(converted);
					chatStorage.setChatMigrated();
				} catch (e) {
					console.error(e);
				}
			}

			await webApi.session().catch((e) => {
				errorType = 'session';
				console.error(e);
				throw e;
			});

			setUser(meUserData);

			if (!meUserData) {
				setIsLoginOpened(true);
				return;
			}

			await webApi.migrate().catch((e) => {
				errorType = 'migrate';
				console.error(e);
			});

			if (
				sessionStorage.getItem('shouldOpenPaywallAfterLogin') === 'true' &&
				!meUserData.isPaid
			) {
				setIsPaywallModalOpened(true);
			} else if (
				sessionStorage.getItem(SHOULD_OPEN_CREATE_AFTER_LOGIN) === 'true'
			) {
				setIsCreateOpened(true);
			}

			api.setCookie('isLogged', 'true');
			sessionStorage.removeItem('shouldOpenPaywallAfterLogin');
			sessionStorage.removeItem(SHOULD_OPEN_CREATE_AFTER_LOGIN);
		} catch (e) {
			webEventsApi.loginError({
				error: errorType,
			});
			switch (errorType) {
				case 'login':
					enqueueSnackbar('Login to service error', {variant: 'error'});
					break;
				case 'me':
					enqueueSnackbar('Get user info error', {variant: 'error'});
					break;
				case 'session':
					enqueueSnackbar('Create session error', {variant: 'error'});
					break;
				case 'migrate':
					enqueueSnackbar('Migrate history error', {variant: 'error'});
					break;
				default:
					enqueueSnackbar(`Login error: ${JSON.stringify(e)}`, {
						variant: 'error',
					});
					break;
			}

			handleLogout(false);
		} finally {
			setIsLayoutLoading(false);
			setIsLoadingUser(false);
		}
	};

	const handleSelectPlan = async (selectedPlan: Product) => {
		try {
			GtagApi.paywallPurchaseStarted();
			setIsPaymentLoading(true);
			const {id, name, currency, price} = selectedPlan;
			webEventsApi.paywallPurchaseStarted({
				product_id: id,
				product_name: name,
			});
			const successUrl = `${
				window.location.origin
			}/success?cur=${currency}&rev=${price / 100}&name=${name}&id=${id}`;
			const decoded = decodeURIComponent(successUrl);
			const {checkoutUrl} = await usersApi.checkout({
				name,
				id,
				successUrl: decoded,
			});
			window.location.href = checkoutUrl;
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		} finally {
			setIsPaymentLoading(false);
		}
	};

	const handleSetPwType = (type = 'regular' as PWType) => {
		setPaywallType(type);
	};

	useEffect(() => {
		if (isCreateOpened || isPaywallModalOpened) {
			sessionStorage.removeItem('shouldOpenPaywallAfterLogin');
			sessionStorage.removeItem(SHOULD_OPEN_CREATE_AFTER_LOGIN);
		}
		if (!isPaywallModalOpened) {
			handleSetPwType();
		}
	}, [isCreateOpened, isPaywallModalOpened]);

	useEffect(() => {
		if (isPaywallModalOpened) {
			webEventsApi.paywallAppear();
		}
	}, [isPaywallModalOpened]);

	const handleClosePaywall = () => {
		setIsPaywallModalOpened(false);
		webEventsApi.paywallClosed();
		webEventsApi.paywallCloseTapped();
	};

	return (
		<AppContext.Provider
			value={{
				categories,
				isMobile,
				isPromoOpened,
				setIsPromoOpened,
				featuredBots,
				isCreateOpened,
				setIsCreateOpened,
				loading: isLoading || isChatsLoading,
				setIsLoginOpened,
				isLoginOpened,
				user,
				setUser,
				isLimitModalOpened,
				setIsLimitModalOpened,
				isPaywallModalOpened,
				setIsPaywallModalOpened,
				handleLoginOrPaywall: handleContinueClick,
				handleLogout,
				setBotToEdit,
				paywallType,
				setPaywallType: handleSetPwType,
				isPhotoModalOpened,
				handlePhotoModalOpened,
				isLoadingUser,
				visitedBots,
				setVisitedBots: handleSetVisitedBots,
				handleUpdateUser,
			}}
		>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				autoHideDuration={1000}
			>
				<div className="App">{getComponent()}</div>
				<CookieBar />
				<StorePopup
					openned={isPromoOpened}
					onClose={() => setIsPromoOpened(false)}
				/>
				{isCreateOpened && (
					<CreateBotPopup
						openned={isCreateOpened}
						onClose={() => setIsCreateOpened(false)}
						bot={botToEdit}
					/>
				)}
				<WebLoginModal
					openned={isLoginOpened}
					onClose={() => setIsLoginOpened(false)}
					onSuccess={handleSuccessLogin}
					hasOpenedModal={
						isPromoOpened ||
						isCreateOpened ||
						isLimitModalOpened ||
						isPaywallModalOpened
					}
					forceClose={forceCloseLogin}
				/>
				<LimitModal
					openned={isLimitModalOpened}
					onClose={() => setIsLimitModalOpened(false)}
					onSuccess={handleContinueClick}
				/>
				<PaywallModal
					openned={isPaywallModalOpened}
					onClose={handleClosePaywall}
					onSuccess={handleSelectPlan}
					loading={isPaymentLoading}
					loadingProducts={productsLoading}
					products={availableProducts}
					type={paywallType}
				/>
				<PhotoModal opened={isPhotoModalOpened} src={photoModalSrc} />
				{isLayoutLoading && <Spinner withLayout />}
			</SnackbarProvider>
		</AppContext.Provider>
	);
}

const AppWithRouter = () => {
	const location = useLocation();
	const handleClick = () => {
		usersApi.deleteCache();
		window.location.href = '/';
	};

	const handleSuccessPaymentLoad = () => {
		const searchParams = new URLSearchParams(location.search);
		const currency = searchParams.get('cur');
		const revenue = parseFloat(searchParams.get('rev') || '');
		const transaction_id = searchParams.get('payment-uid' || '');
		const id = searchParams.get('id' || '');
		const name = searchParams.get('name' || '');

		if (isDevb2c() || isLocalEnv()) {
			console.log({revenue, currency, transaction_id});
			return;
		}

		try {
			if (revenue && currency && transaction_id) {
				webEventsApi.paywallClosed();
				webEventsApi.paywallPurchaseSuccess({
					revenue,
					product_id: id || '',
					product_name: name || '',
				});
				GtagApi.paywallPurchaseSuccess({
					revenue,
					currency,
					transaction_id,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Routes>
			<Route
				path="/success"
				element={
					<SuccessPaymentPage
						onClick={handleClick}
						text="Return to main page"
						onLoad={handleSuccessPaymentLoad}
					/>
				}
			/>
			<Route path="404" element={<FOF />} />
			<Route path="*" element={<App />} />
		</Routes>
	);
};

export default AppWithRouter;
