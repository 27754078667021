import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import styles from './DeleteMessageDialog.module.css';
import cn from 'classnames';

interface DeleteMessageDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
}

export const DeleteMessageDialog: React.FC<DeleteMessageDialogProps> = ({
	open,
	onClose,
	onSubmit,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={styles.dialog}
		>
			<DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
				{'Delete Message'}
			</DialogTitle>
			<DialogContent className={styles.dialogContent}>
				<DialogContentText id="alert-dialog-description">
					Are you sure you want to delete this message?
				</DialogContentText>
			</DialogContent>
			<DialogActions className={styles.dialogActions}>
				<Button onClick={onClose} className={styles.dialogButton}>
					Cancel
				</Button>
				<Button
					onClick={onSubmit}
					className={cn(styles.dialogButton, styles.deleteButton)}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};
