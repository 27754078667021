import {useEffect, useState} from 'react';
import styles from './ActionOptionsBtn.module.css';
import cn from 'classnames';
import threeDots from './images/threeDots.svg';

type Props = {
	icon?: string;
};

export const ActionOptionsBtn = ({
	icon = threeDots,
	children,
}: React.PropsWithChildren<Props>) => {
	const [isOpened, setIsOpened] = useState(false);

	const handleClose = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`#action-options-btn`)) {
			return;
		}

		setIsOpened(false);
	};

	const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		const isNewOpened = !isOpened;
		setIsOpened(isNewOpened);
		if (isNewOpened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
	};

	useEffect(() => {
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, []);

	return (
		<div
			className={styles.actionOptionsBtn}
			id="action-options-btn"
			onClick={handleOpen}
		>
			<img className={styles.icon} src={icon} alt="user icon" />
			{isOpened && <div className={styles.popover}>{children}</div>}
		</div>
	);
};

export const ActionOptionsRow = ({
	icon,
	text,
	onClick,
	isRed,
}: {
	icon: string;
	text: string;
	onClick: () => void;
	isRed?: boolean;
}) => {
	return (
		<div className={styles.row} onClick={onClick}>
			<img className={styles.icon} src={icon} alt={text} />
			<p className={cn(styles.text, {[styles.redText]: isRed})}>{text}</p>
		</div>
	);
};
