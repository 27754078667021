import {BotData} from '../../api/types';
import {MessagesQty} from '../../common-lib/src/components/BotTile/MessagesQty';
import '../../common-lib/src/components/BotTile/BotTile.css';
import styles from './Tile.module.css';
import cn from 'classnames';
import {Link, useNavigate} from 'react-router-dom';
import firstImg from './images/first.png';
import secondImg from './images/second.png';
import thirdImg from './images/third.png';
import fourthImg from './images/fourth.png';
import fifthImg from './images/fifth.png';
import {LikeBtn} from './LikeBtn/LikeBtn';
import {webApi} from '../../api/webApi';
import {useContext, useState} from 'react';
import {AppContext} from '../../App';

export const rateImages = [firstImg, secondImg, thirdImg, fourthImg, fifthImg];

type Props = {
	bot: BotData;
	className?: string;
	onlyPhoto?: boolean;
	onClick?: () => void;
	rate?: number;
};

export const TileWithRate = ({
	bot,
	className,
	onlyPhoto = false,
	onClick,
	rate,
}: Props) => {
	const navigate = useNavigate();
	const handleTileClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onClick?.();
		navigate(`/bot_${bot.id}/chat`);
	};

	const [isLiked, setIsLiked] = useState(bot.attributes.userLiked);
	const [likesCount, setLikesCount] = useState(bot.attributes.likesCount);

	const {user, setIsLoginOpened} = useContext(AppContext);

	if (rate === undefined) {
		return null;
	}

	const handleLikeClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		if (!user || user.isAnon) {
			setIsLoginOpened(true);
			return;
		}
		if (isLiked) {
			webApi.unlikeBot(bot.id);
			setLikesCount(Math.max(likesCount - 1, 0));
		} else {
			webApi.likeBot(bot.id);
			setLikesCount(likesCount + 1);
		}
		setIsLiked(!isLiked);
	};

	return (
		<Link
			className={cn('bot-tile', className, styles.tile, styles.tileWithRate)}
			onClick={handleTileClick}
			to={`/bot_${bot.id}/chat`}
		>
			<div className={cn('bot-tile__avatar', styles.avatar)}>
				<img
					src={bot.attributes.avatarUrl}
					alt={`${bot.attributes.name} - 160 - ${bot.attributes.createdAt}`}
					className={cn('bot-tile__image', styles.image)}
					loading="lazy"
					title={bot.attributes.name}
				/>
			</div>
			<p className={cn('bot-tile__title', styles.name)}>
				{bot.attributes.name}
			</p>
			<p className={styles.description}>{bot.attributes.bio || ''}</p>
			{rateImages[rate] && (
				<img className={styles.rate} src={rateImages[rate]} alt="rate" />
			)}
			<LikeBtn isLiked={isLiked} count={likesCount} onClick={handleLikeClick} />
		</Link>
	);
};

export const Tile = ({
	bot,
	className,
	onlyPhoto = false,
	onClick,
	rate,
}: Props) => {
	const navigate = useNavigate();
	const handleTileClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onClick?.();
		navigate(`/bot_${bot.id}/chat`);
	};

	if (onlyPhoto) {
		return (
			<Link
				className={cn('bot-tile', className, styles.tile, styles.onlyPhotoTile)}
				onClick={handleTileClick}
				to={`/bot_${bot.id}/chat`}
			>
				<div className={cn('bot-tile__avatar', styles.avatar)}>
					<img
						src={bot.attributes.avatarUrl}
						alt={bot.attributes.name}
						className={cn('bot-tile__image', styles.image, styles.onlyPhoto)}
					/>
				</div>
			</Link>
		);
	}

	if (rate !== undefined) {
		return (
			<TileWithRate
				bot={bot}
				className={className}
				onlyPhoto={onlyPhoto}
				onClick={onClick}
				rate={rate}
			/>
		);
	}

	return (
		<Link
			className={cn('bot-tile', className, styles.tile, {
				[styles.tileWithRate]: rate !== undefined,
			})}
			onClick={handleTileClick}
			to={`/bot_${bot.id}/chat`}
		>
			<div className={cn('bot-tile__avatar', styles.avatar)}>
				<img
					src={bot.attributes.avatarUrl}
					alt={`${bot.attributes.name} - 160 - ${bot.attributes.createdAt}`}
					className={cn('bot-tile__image', styles.image)}
					loading="lazy"
					title={bot.attributes.name}
				/>
			</div>
			<p className={cn('bot-tile__title', styles.name)}>
				{bot.attributes.name}
			</p>
			<p className={styles.description}>{bot.attributes.bio || ''}</p>
			<MessagesQty
				messagesQty={bot.attributes.messagesCount}
				className={cn('bot-tile__messages-qty', styles.qty)}
			/>
		</Link>
	);
};
