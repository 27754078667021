import './ChatMain.css';
import {Message} from '../../../api/Api';
import loadErrorImg from './images/load-error.svg';
import {useEffect, useState, useRef} from 'react';
import {UnblurBtn} from './UnblurBtn/UnblurBtn';
import {Spinner} from '../../Spinner/Spinner';
import {MessageDeleteBlock, useCommonActions} from './ChatMessage';
import cn from 'classnames';
import {Actions, CommonActions} from './ChatMain.types';

export const MAX_RETRY_TIME_KEY = 'MAX_RETRY_TIME';

const DEFAULT_MAX_RETRY_TIME = 30;

const LoadError = () => {
	return (
		<div className="chat__message_load-error">
			<img src={loadErrorImg} alt="Load error" />
			<p className="chat__message_load-error__text">
				Media is not available. Please try reloading the chat a little later.
			</p>
		</div>
	);
};

const useMediaLoader = (onRetry: () => void) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);
	const [retryCount, setRetryCount] = useState(0);
	const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const maxRetryTime = sessionStorage.getItem(MAX_RETRY_TIME_KEY)
		? parseInt(sessionStorage.getItem(MAX_RETRY_TIME_KEY)!)
		: DEFAULT_MAX_RETRY_TIME;

	const retrySequence = [1, 2, 3, 5, 8, 13, 21, 34];
	const totalLoadingTime = useRef(0);
	const isLoadingFinalError = error && totalLoadingTime.current >= maxRetryTime;

	useEffect(() => {
		return () => {
			if (retryTimeoutRef.current) {
				clearTimeout(retryTimeoutRef.current);
			}
		};
	}, []);

	const handleLoadStart = () => {
		setIsLoading(true);
		setError(false);
	};

	const handleLoad = () => {
		setIsLoading(false);
		setError(false);
		setRetryCount(0);
		totalLoadingTime.current = 0;
	};

	const handleError = () => {
		setError(true);
		setIsLoading(true);

		if (totalLoadingTime.current < maxRetryTime) {
			const nextRetryDelay =
				retrySequence[retryCount] || retrySequence[retrySequence.length - 1];
			totalLoadingTime.current += nextRetryDelay;

			retryTimeoutRef.current = setTimeout(() => {
				setRetryCount((prev) => prev + 1);
				onRetry?.();
			}, nextRetryDelay * 1000);
		}
	};

	return {
		isLoading,
		error,
		handleLoadStart,
		handleLoad,
		handleError,
		isLoadingFinalError,
	};
};

const useUnblur = (message: Message, actions?: Actions) => {
	const hasUnblur = !!(message.blured && actions?.unblur);
	const [wasUnblurred, setWasUnblurred] = useState(false);
	const [isUnblurring, setIsUnblurring] = useState(false);

	const handleUnblur = async () => {
		setIsUnblurring(true);
		const result = await actions?.unblur?.(message);
		if (actions?.hasPremium && result) {
			setWasUnblurred(true);
		}
		setIsUnblurring(false);
	};

	return {
		hasUnblur,
		wasUnblurred,
		handleUnblur,
		showUnblurBtn: hasUnblur && !isUnblurring && !wasUnblurred,
	};
};

export const VideoMessage = ({
	message,
	actions,
	commonActions,
}: {
	message: Message;
	actions?: Actions;
	commonActions?: CommonActions;
}) => {
	const {video} = message;
	const isLastMessage = !!actions;
	const videoRef = useRef<HTMLVideoElement>(null);
	const isMuted = actions?.muted ?? true;
	const {shouldShowHover, handleDelete, handleTouchStart, isHoverOpen} =
		useCommonActions(message, commonActions);
	const {handleUnblur, showUnblurBtn} = useUnblur(message, actions);

	const onRetry = () => {
		if (videoRef.current) {
			videoRef.current.load();
		}
	};

	const {
		isLoading,
		error,
		handleLoadStart,
		handleLoad,
		handleError,
		isLoadingFinalError,
	} = useMediaLoader(onRetry);

	if (!video) {
		return null;
	}

	return (
		<div
			className={cn('chat__message', 'chat__message_video', {
				chat__message_user: message.turn === 'user',
				chat__message_bot: message.turn !== 'user',
				'chat__message_delete-hover': shouldShowHover,
			})}
			id={`message_${message.timestamp}`}
			onClick={() => handleTouchStart(`message_${message.timestamp}`)}
		>
			<MessageDeleteBlock
				message={message}
				commonActions={commonActions}
				isHoverOpen={isHoverOpen}
				handleDelete={handleDelete}
			/>
			{isLoading && !isLoadingFinalError && <Spinner withLayout={false} />}
			{isLoadingFinalError && <LoadError />}
			{!isLoadingFinalError && (
				<video
					ref={videoRef}
					src={video}
					muted={!isLastMessage || isMuted || message.blured}
					controls={!message.blured}
					loop={message.blured}
					autoPlay={isLastMessage || message.blured}
					playsInline={isLastMessage || message.blured}
					className={cn('chat__message_bot-video', {
						'chat__message_bot-video_blured': message.blured,
					})}
					style={{display: isLoading || error ? 'none' : 'block'}}
					onLoadStart={handleLoadStart}
					onCanPlay={handleLoad}
					onError={handleError}
				/>
			)}
			{!isLoadingFinalError && showUnblurBtn && (
				<UnblurBtn onClick={handleUnblur} isPremium={!!actions?.hasPremium} />
			)}
		</div>
	);
};

export const ImageMessage = ({
	message,
	actions,
	commonActions,
}: {
	message: Message;
	actions?: Actions;
	commonActions?: CommonActions;
}) => {
	const {image} = message;
	const imgRef = useRef<HTMLImageElement | null>(null);
	const {handleUnblur, showUnblurBtn} = useUnblur(message, actions);
	const {shouldShowHover, handleDelete, handleTouchStart, isHoverOpen} =
		useCommonActions(message, commonActions);

	const onRetry = () => {
		if (imgRef.current && image) {
			imgRef.current.src = image;
		}
	};

	const {
		isLoading,
		error,
		handleLoadStart,
		handleLoad,
		handleError,
		isLoadingFinalError,
	} = useMediaLoader(onRetry);

	if (!image) {
		return null;
	}

	return (
		<div
			className={cn('chat__message', 'chat__message_photo', {
				chat__message_user: message.turn === 'user',
				chat__message_bot: message.turn !== 'user',
				'chat__message_delete-hover': shouldShowHover,
			})}
			id={`message_${message.timestamp}`}
			onClick={() => handleTouchStart(`message_${message.timestamp}`)}
		>
			<MessageDeleteBlock
				message={message}
				commonActions={commonActions}
				isHoverOpen={isHoverOpen}
				handleDelete={handleDelete}
			/>
			{isLoading && !isLoadingFinalError && <Spinner withLayout={false} />}
			{isLoadingFinalError && <LoadError />}
			{!isLoadingFinalError && (
				<img
					ref={imgRef}
					src={image}
					alt="chat-avatar"
					className="chat__message_bot-image"
					style={{display: isLoading || error ? 'none' : 'block'}}
					onLoadStart={handleLoadStart}
					onLoad={handleLoad}
					onError={handleError}
				/>
			)}
			{!isLoadingFinalError && showUnblurBtn && (
				<UnblurBtn onClick={handleUnblur} isPremium={!!actions?.hasPremium} />
			)}
		</div>
	);
};
