import {webEventsApi} from '../amplitude/webEvents';
import {MAIN_CHAT_ID} from '../App';
import {api} from '../common-lib/src/api/Api';
import {XAuthName} from '../common-lib/src/api/constants';
import {
	BotData,
	CategoryPreview,
	ChatsFirebaseResponse,
	GetBotsResponse,
	GetTagsResponse,
	MediaData,
	PaginationMeta,
	TagsData,
} from './types';
import {usersApi} from './usersApi/usersApi';

const token =
	'84a16cdbe4a1ec91fb1b9f300cedcaa137374d78afb7a93271166584261e22b4722442bb8df0cf3f6fa9d575c24df8ce5a2b419fa9de11c6220ec2933cbb777fe6cceb1b4bdb1b8c0739722ec0a3645f26d2f1baf2b07461cb53aa930c35bb55a8c74c505e6646b39c1db8201322ef77b2da36c89f59667f6befb5f412a05596';

type BotsResponse = {
	data: BotData[];
	meta: PaginationMeta;
};

type BotResponse = {
	data: BotData;
};

const STRAPI_TOKEN_KEY = 'strapitoken';
const MIGRATED_KEY = 'strapi-migrated';

const BOT_SORTS =
	'&sort[0]=messagesCount:desc&sort[1]=likeTotalCount:desc&sort[2]=createdAt:desc';

class WebApi {
	url: string;
	cache: {
		bots?: GetBotsResponse;
	};
	userHeader: string | null = null;
	sessionRequest: Promise<void> | null = null;

	constructor() {
		this.url = 'https://api.exh.ai/strapi-secondary/api';
		// this.url = 'http://localhost:1337/api';
		this.cache = {};
		this.sessionRequest = null;
	}

	setBotsCache(data: GetBotsResponse) {
		this.cache.bots = data;
	}

	private getHeaders(isForm = false): HeadersInit & Record<string, string> {
		const headers: HeadersInit & Record<string, string> = {
			Authorization: `Bearer ${token}`,
		};
		if (!this.userHeader) {
			this.userHeader = this.getStrapiToken();
		}

		headers['user'] = this.userHeader as string;
		headers['X-APP-ID'] = 'com.ex-human.botify';
		headers['X-APP-VERSION'] = '1.0.0';

		if (usersApi.getToken()) {
			headers[XAuthName] = usersApi.getToken() as string;
		}

		if (!isForm) {
			headers['Content-Type'] = 'application/json';
		}
		return headers;
	}

	async getBot(id: string): Promise<BotResponse> {
		const headers = this.getHeaders();
		//@ts-ignore
		if (!headers['user']) {
			await this.session();
		}
		return fetch(`${this.url}/bots/${id}?populate=voice,tags`, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	getBots() {
		if (this.cache.bots) {
			console.log('Get from cache');
			return Promise.resolve(this.cache.bots);
		}

		return fetch(
			`${this.url}/bots/?populate=metadata,metadata.categories&pagination[start]=0&pagination[limit]=100&sort=metadata.rankingScore&filters[firebaseUserId][$null]=[$null]`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		)
			.then((res) => res.json())
			.then((data) => {
				this.setBotsCache(data);
				return data;
			}) as Promise<GetBotsResponse>;
	}

	searchByName(
		name: string,
		page: number,
		categoryId?: string
	): Promise<{
		data: BotData[];
		meta: PaginationMeta;
	}> {
		let url = `${this.url}/bots?filters[name][$containsi]=${name}&pagination[page]=${page}${BOT_SORTS}`;
		if (categoryId) {
			url += `&filters[tags][id]=${categoryId}`;
		}
		return fetch(url, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	getByTag(tagId: string, page = 0, pageSize = 20): Promise<BotsResponse> {
		if (tagId === MAIN_CHAT_ID.toString()) {
			return this.getMainPageBots(page, pageSize);
		}

		return fetch(
			`${this.url}/bots?&filters[tags][id]=${tagId}&pagination[page]=${page}&pagination[pageSize]=${pageSize}${BOT_SORTS}&tag=web`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json());
	}

	getByLikePeriod(period: string, page = 0): Promise<BotsResponse> {
		return fetch(
			`${this.url}/bots?&filters[likeEndPeriod]=${period}&pagination[page]=${page}${BOT_SORTS}&tag=web`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json());
	}

	async getLikePeriod(limit: number): Promise<{[key: string]: BotData[]}> {
		const {data} = (await fetch(
			`${this.url}/bots/likes?limit=${limit}&likeEndPeriod=true`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json())) as BotsResponse;

		const groups = data.reduce((acc, bot) => {
			const groupName = bot.attributes.likeEndPeriod;
			if (!groupName) {
				return acc;
			}
			if (!acc[groupName]) {
				acc[groupName] = [];
			}
			acc[groupName].push(bot);
			return acc;
		}, {} as {[key: string]: BotData[]});
		return groups;
	}

	getMainPageBots(page = 0, pageSize = 20): Promise<BotsResponse> {
		return fetch(
			`${this.url}/bots?&pagination[page]=${page}${BOT_SORTS}&tag=web&pagination[pageSize]=${pageSize}`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json());
	}

	getUserBots(userId: string, page = 0, pageSize = 20): Promise<BotsResponse> {
		return fetch(
			`${this.url}/bots?filters[firebaseUserId][$eq]=${userId}&filters[isDeleted][$eq]=false&pagination[page]=${page}&pagination[pageSize]=${pageSize}${BOT_SORTS}`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json());
	}

	searchByNameInIds(
		name: string,
		page: number,
		ids: string[]
	): Promise<{data: BotData[]; meta: PaginationMeta}> {
		let url = `${this.url}/bots?filters[name][$containsi]=${name}&pagination[page]=${page}${BOT_SORTS}`;
		ids.forEach((id, i) => {
			url += `&filters[id][$in][${i}]=${id}`;
		});
		return fetch(url, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	getBotsByIds(
		ids: string[]
	): Promise<{data: BotData[]; meta: PaginationMeta}> {
		let url = `${this.url}/bots?pagination[page]=0&pagination[pageSize]=50`;
		ids.forEach((id, i) => {
			url += `&filters[id][$in][${i}]=${id}`;
		});
		return fetch(url, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	convertTagsToPreview(data: TagsData[]): CategoryPreview[] {
		return data.map((tag) => {
			return {
				id: tag.id,
				name: tag.attributes.name,
				sort: tag.attributes.sort,
				createdAt: tag.attributes.createdAt,
				updatedAt: tag.attributes.updatedAt,
				publishedAt: tag.attributes.publishedAt,
				locale: '',
				showBanner: false,
				showOnWeb: tag.attributes.showOnExplore,
				bots: [],
				slug: tag.attributes.slug,
			};
		});
	}

	async getCategoriesPreview(): Promise<CategoryPreview[]> {
		const res: GetTagsResponse = await fetch(
			`${this.url}/tags?pagination[pageSize]=50`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		).then((res) => res.json());

		return this.convertTagsToPreview(res.data);
	}

	reportBot({id, reason}: {id: number; reason: string}) {
		return fetch(`${this.url}/bots/${id}/report`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				reason,
			}),
		}).then((res) => res.json());
	}

	async session() {
		if (this.sessionRequest) {
			return this.sessionRequest;
		}

		const userid = api.getUserId(() => webEventsApi.newUser());
		const req = fetch(`${this.url}/user-helper/session/${userid}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.token) {
					api.setCookie(STRAPI_TOKEN_KEY, data.token);
					this.userHeader = data.token;
				}
			})
			.finally(() => {
				this.sessionRequest = null;
			});

		this.sessionRequest = req;

		return req;
	}

	async migrate() {
		const prevUserToken = api.getCookie(STRAPI_TOKEN_KEY);
		if (!prevUserToken) {
			return;
		}
		const migrated = api.getCookie(MIGRATED_KEY);
		if (migrated) {
			return;
		}

		await this.session();
		const newToken = api.getCookie(STRAPI_TOKEN_KEY);
		if (newToken === prevUserToken) {
			return;
		}

		return fetch(`${this.url}/bots/migrate`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({
				prevUserToken,
			}),
		}).then(() => {
			api.setCookie(MIGRATED_KEY, '1');
		});
	}

	getStrapiToken() {
		if (this.userHeader) {
			return this.userHeader;
		}
		const token = api.getCookie(STRAPI_TOKEN_KEY);
		if (!token) {
			return null;
		}

		this.userHeader = token;
		return token;
	}

	createBot(data: object): Promise<{data: BotData}> {
		return fetch(`${this.url}/bots/create-web`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({data}),
		}).then((res) => res.json());
	}

	updateBot(id: number, data: object) {
		return fetch(`${this.url}/bots/update-web`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify({id, data}),
		}).then((res) => res.json());
	}

	likeBot(id: number) {
		return fetch(`${this.url}/bots/${id}/like`, {
			method: 'POST',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	unlikeBot(id: number) {
		return fetch(`${this.url}/bots/${id}/dislike`, {
			method: 'POST',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	async getSimilarBots(id: string | number): Promise<BotsResponse> {
		return fetch(`${this.url}/bots/${id}/similar?limit=8`, {
			method: 'GET',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	async getImages(botId: number, page: number): Promise<{data: MediaData[]}> {
		// return fetch(`${this.url}/bots/${botId}/media?pagination[page]=${page}`, {
		// 	method: 'GET',
		// 	headers: this.getHeaders(),
		// }).then((res) => res.json());

		const dataFromBack = await fetch(
			`${this.url}/media-messages?filters[botId]=${botId}&pagination[pageSize]=10&pagination[page]=${page}&populate=image&sort=createdAt:desc`,
			{
				method: 'GET',
				headers: this.getHeaders(),
			}
		)
			.then((res) => res.json())
			.then((res) => {
				const images = res.data.map(
					(data: {
						attributes: {
							image: {data: {attributes: {originalUrl: any; previewUrl: any}}};
						};
					}) => ({
						url: data.attributes.image.data.attributes.originalUrl,
						previewUrl: data.attributes.image.data.attributes.previewUrl,
					})
				);
				return {data: images};
			});

		return {data: dataFromBack.data};
	}

	userFeedback(data: {
		feedback: string;
		email: string;
	}): Promise<{success: boolean}> {
		return fetch(`${this.url}/user-helper/feedback`, {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then((res) => res.json());
	}

	chats() {
		return fetch(`${this.url}/bots/chats`, {
			method: 'GET',
			headers: this.getHeaders(),
		})
			.then((res) => res.json())
			.then((res: ChatsFirebaseResponse) => {
				if (res.data) {
					return res.data.reverse();
				}
				throw new Error('No chats data');
			});
	}

	deleteUser() {
		return fetch(`${this.url}/user-helper/me`, {
			method: 'DELETE',
			headers: this.getHeaders(),
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error('Failed to delete user');
				}
				return res.json();
			})
			.then(() => {
				api.setCookie(STRAPI_TOKEN_KEY, '');
				this.userHeader = null;
				api.setUserIdCookie('');
			});
	}

	resetToken() {
		api.setCookie(STRAPI_TOKEN_KEY, '');
		this.userHeader = null;
	}

	deleteBot(id: number) {
		return fetch(`${this.url}/bots/${id}`, {
			method: 'DELETE',
			headers: this.getHeaders(),
		}).then((res) => res.json());
	}

	reportUser(data: {
		userId: string;
		reason: string;
		authorId: string;
	}): Promise<any> {
		return fetch('https://reportuser-otah6f7ifq-uc.a.run.app', {
			method: 'POST',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then((res) => res.json());
	}
}

export const webApi = new WebApi();
