import {useSnackbar} from 'notistack';
import {useContext, useState, useRef, useEffect} from 'react';
import {AppContext} from '../../../../../App';
import {BotData, MediaData} from '../../../../../api/types';
import {webApi} from '../../../../../api/webApi';
import {useIsVisible} from '../../../../../common-lib/src/hooks/useIsVisible';
import {BotProfileProps} from '../BotPage.types';

export const useBotProfile = (props: BotProfileProps) => {
	const {enqueueSnackbar} = useSnackbar();
	const {bot, handleShareClick} = props;
	const {user, setIsLoginOpened, handlePhotoModalOpened} =
		useContext(AppContext);

	const [page, setPage] = useState(1);
	const [isLastPage, setIsLastPage] = useState(false);
	const [isSimilarLoading, setIsSimilarLoading] = useState(false);
	const [similarBots, setSimilarBots] = useState<BotData[]>([]);

	const [isPhotosLoading, setIsPhotosLoading] = useState(false);
	const [photos, setPhotos] = useState<MediaData[]>([]);

	const [isLiked, setIsLiked] = useState(bot.attributes.userLiked);
	const [likesCount, setLikesCount] = useState(bot.attributes.likesCount);
	const scrollerRef = useRef<HTMLDivElement>(null);

	const isVisible = useIsVisible({el: scrollerRef.current});

	const handlePhotoClick = (src: string) => {
		handlePhotoModalOpened(true, src);
	};

	const fetchImages = async (page: number) => {
		if (isLastPage) {
			return;
		}

		try {
			setIsPhotosLoading(true);
			const response = await webApi.getImages(bot.id, page);

			if (response.data.length === 0) {
				setIsLastPage(true);
			}

			setPhotos([...photos, ...response.data]);
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Failed to load photos', {variant: 'error'});
		} finally {
			setIsPhotosLoading(false);
		}
	};

	useEffect(() => {
		fetchImages(page);
	}, [page]);

	useEffect(() => {
		if (isVisible && !isPhotosLoading) {
			setPage(page + 1);
		}
	}, [isVisible, isPhotosLoading]);

	useEffect(() => {
		const fetchSimilarBots = async () => {
			try {
				setIsSimilarLoading(true);
				const response = await webApi.getSimilarBots(bot.id);
				setSimilarBots(response.data || []);
			} catch (e) {
				console.error(e);
				enqueueSnackbar('Failed to load similar bots', {variant: 'error'});
			} finally {
				setIsSimilarLoading(false);
			}
		};

		fetchSimilarBots();
	}, []);

	const handleLikeClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		if (!user || user.isAnon) {
			setIsLoginOpened(true);
			return;
		}
		if (isLiked) {
			webApi.unlikeBot(bot.id);
			setLikesCount(Math.max(likesCount - 1, 0));
		} else {
			webApi.likeBot(bot.id);
			setLikesCount(likesCount + 1);
		}
		setIsLiked(!isLiked);
	};

	const handleBotClick = (id: string | number) => {
		//TODO: change this to navigate with state reload
		window.location.href = `/bot_${id}/chat`;
	};

	const getBotClickLink = (id: string | number) => {
		return `/bot_${id}/chat`;
	};

	useEffect(() => {
		return () => {
			setPhotos([]);
			setSimilarBots([]);
			setIsLastPage(false);
			setPage(0);
		};
	}, [bot]);

	return {
		isLiked,
		likesCount,
		handleLikeClick,
		handleShareClick,
		isSimilarLoading,
		similarBots,
		isPhotosLoading,
		photos,
		handleBotClick,
		scrollerRef,
		getBotClickLink,
		handlePhotoClick,
	};
};
