import styles from './ScrollBtns.module.css';
import cn from 'classnames';
import arrowIcon from './images/arrow.svg';
import {useScrollBtns} from './useScrollBtns';
import {cloneElement} from 'react';

type ScrollBtnProps = {
	onClick: () => void;
	className?: string;
};

const ScrollBtn = ({
	onClick,
	className,
	left = false,
}: ScrollBtnProps & {left?: boolean}) => {
	const alt = left ? 'scroll left' : 'scroll right';
	const arrowClass = left ? styles.scrollButtonLeft : styles.scrollButtonRight;

	return (
		<button
			className={cn(styles.scrollButton, arrowClass, className)}
			onClick={onClick}
		>
			<img src={arrowIcon} alt={alt} />
		</button>
	);
};

const ScrollBtnLeft = ({onClick, className}: ScrollBtnProps) => {
	return <ScrollBtn onClick={onClick} className={className} left />;
};

const ScrollBtnRight = ({onClick, className}: ScrollBtnProps) => {
	return <ScrollBtn onClick={onClick} className={className} />;
};

const ScrollableBlock = ({children}: React.PropsWithChildren) => {
	return <div className={styles.scroll}>{children}</div>;
};

type Props = {
	useIref?: boolean;
	className?: string;
};

export const ScrollContainer = ({
	children,
	useIref = false,
	className,
}: React.PropsWithChildren<Props>) => {
	const {
		scrollContainerRef,
		scrollLeftVisible,
		scrollRightVisible,
		handleScrollRight,
		handleScrollLeft,
	} = useScrollBtns({showOnTouch: false});

	return (
		<div className={styles.containerForScroll}>
			<ScrollableBlock>
				{cloneElement(children as React.ReactElement, {
					[useIref ? 'iref' : 'ref']: scrollContainerRef,
				})}
			</ScrollableBlock>
			{scrollLeftVisible && (
				<ScrollBtnLeft onClick={handleScrollLeft} className={className} />
			)}
			{scrollRightVisible && (
				<ScrollBtnRight onClick={handleScrollRight} className={className} />
			)}
		</div>
	);
};
