import {PlanProps} from './types';
import {convertPrice} from './utils';
import cn from 'classnames';

export const PWPaymentPlan = ({
	priceBottom = 'per year',
	onSelect,
	special = false,
	product,
	isSelected,
}: PlanProps) => (
	<div
		className={cn('paywall-modal__price', {
			'paywall-modal__price_selected': isSelected,
		})}
		onClick={() => onSelect(product)}
	>
		<p className="paywall-modal__price-title">{product.name}</p>
		<div className="paywall-modal__price_prices">
			<p className="paywall-modal__price-monthly">
				${convertPrice(product.price)}
			</p>
			<p className="paywall-modal__price-yearly">per year</p>
		</div>
		{special && (
			<div className="paywall-modal__badge">
				<div className="paywall-modal__text-wrapper">Most popular</div>
			</div>
		)}
	</div>
);
