import './PaywallModal.css';
import {useEffect, useState} from 'react';
import {Product} from '../../api/types';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {PaywallProps} from './types';
import {PaywallModalContainer} from './PWContainer';
import {PWBtn} from './PWBtn';
import {PWList} from './PWList';
import {UltraPaywall} from './UltraPaywall/UltraPaywall';
import {PWPaymentPlan} from './PWPaymentPlan';
import {webEventsApi} from '../../amplitude/webEvents';

export const PaywallModal = (props: PaywallProps) => {
	const {products, loadingProducts} = props;

	if (loadingProducts || !products.length) {
		if (props.openned && !products.length) {
			console.warn('No products found');
		}
		return null;
	}

	if (props.type === 'ultra') {
		const ultraProduct = products.find(
			(product) => product.access_level === 'ultra'
		);
		if (ultraProduct) {
			return <UltraPaywall {...props} products={[ultraProduct]} />;
		}
	}

	if (products.length === 1 && products[0].access_level === 'ultra') {
		return <UltraPaywall {...props} />;
	}

	return <DefaultPaywallModal {...props} />;
};

export const DefaultPaywallModal = ({
	openned,
	onClose,
	onSuccess,
	loading,
	products,
	loadingProducts,
}: PaywallProps) => {
	const [selectedPlan, setSelectedPlan] = useState<Product | null>();
	const [isUltraSelected, setIsUltraSelected] = useState(false);

	useEffect(() => {
		if (products.length) {
			const ultraProduct = products.find(
				(product) => product.access_level === 'ultra'
			);
			if (ultraProduct) {
				setSelectedPlan(ultraProduct);
			} else {
				setSelectedPlan(products[0]);
			}
		} else {
			console.warn('No products found');
		}
	}, [products]);

	useEffect(() => {
		if (!openned) {
			return;
		}
		if (selectedPlan?.name.includes('Ultra')) {
			setIsUltraSelected(true);
		} else {
			setIsUltraSelected(false);
		}

		webEventsApi.paywallProductSelected({
			product_id: selectedPlan?.id || '',
			product_name: selectedPlan?.name || '',
		});
	}, [selectedPlan, openned]);

	return (
		<PaywallModalContainer openned={openned} onClose={onClose}>
			<div className="paywall-modal__header">
				<h2 className="paywall-modal__header-h2">Get Full Access</h2>
			</div>
			<PWList
				items={[
					{img: '🖼️', text: 'Photos & Voices'},
					{img: '💫', text: 'Unlimited messages'},
					{img: '🔒', text: 'Private bots'},
					{img: '💖', text: 'Role-playing', forUlta: true},
					{img: '🧠', text: 'Tailor-made AI models', forUlta: true},
				]}
				isUltraSelected={isUltraSelected}
			/>
			{loadingProducts ? (
				<Spinner withLayout={false} />
			) : (
				<div className="paywall-modal__prices">
					{products.map((product) => {
						return (
							<PWPaymentPlan
								key={product.id}
								product={product}
								isSelected={selectedPlan?.id === product.id}
								priceBottom=""
								onSelect={setSelectedPlan}
							/>
						);
					})}
				</div>
			)}
			<PWBtn
				loading={loading}
				selectedPlan={selectedPlan}
				onSuccess={onSuccess}
				text="Subscribe"
			/>
		</PaywallModalContainer>
	);
};
