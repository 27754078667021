import {useNavigate} from 'react-router-dom';
import {BotData} from '../../../../../api/types';
import {useSnackbar} from 'notistack';
import {useState, useContext, useEffect} from 'react';
import {AppContext} from '../../../../../App';
import {webApi} from '../../../../../api/webApi';
import {SHARING_TEXT} from '../SharingModal/SharingModal';
import {Message} from '../../../../../common-lib/src/api/Api';

export const useBotPage = (bot: BotData, isChat?: boolean) => {
	const navigate = useNavigate();
	const [isSharingOpened, setIsSharingOpened] = useState(false);
	const [isOtherActionsOpened, setIsOtherActionsOpened] = useState(false);
	const [isFeedbackOpened, setIsFeedbackOpened] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const {setBotToEdit, setIsCreateOpened, visitedBots, setVisitedBots} =
		useContext(AppContext);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
		useState<Message | null>(null);

	const handleDeleteConfirmation = (message: Message) => {
		setDeleteConfirmationMessage(message);
		setShowDeleteConfirmation(true);
	};

	const handleDeleteConfirmationClose = () => {
		setDeleteConfirmationMessage(null);
		setShowDeleteConfirmation(false);
	};

	const handleGoToUserProfile = (username: string) => {
		navigate(`/profile/${username}`);
	};

	useEffect(() => {
		const index = visitedBots.findIndex((b) => b.id === bot.id);
		if (index === -1) {
			setVisitedBots([bot, ...visitedBots]);
			return;
		}

		if (index !== 0) {
			const [movedBot] = visitedBots.splice(index, 1);
			visitedBots.unshift(movedBot);
			setVisitedBots(visitedBots);
			return;
		}
	}, [bot.id, visitedBots]);

	const handleGoBack = () => {
		navigate('/');
	};

	const handleGoProfile = () => {
		navigate(`/bot_${bot.id}`);
	};

	const handleGoChat = () => {
		navigate(`/bot_${bot.id}/chat`);
	};

	const handleShareClick = () => {
		if (navigator.share) {
			navigator
				.share({
					title: `Botify AI. ${bot.attributes.name}`,
					text: SHARING_TEXT,
					url: window.location.href,
				})
				.catch((error) => {
					console.error('Error sharing:', error);
				});
		} else {
			setIsSharingOpened(true);
		}
	};

	const handleCloseOthers = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`#bot-actions`)) {
			return;
		}

		setIsOtherActionsOpened(false);
	};

	const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const newIsOpened = !isOtherActionsOpened;
		setIsOtherActionsOpened(newIsOpened);

		if (newIsOpened) {
			document.addEventListener('click', handleCloseOthers);
		} else {
			document.removeEventListener('click', handleCloseOthers);
		}
	};

	useEffect(() => {
		return () => {
			document.removeEventListener('click', handleCloseOthers);
		};
	}, []);

	const handleEditClick = () => {
		setBotToEdit(bot);
		setIsCreateOpened(true);
	};

	const handleSubmitReport = async (reason: string) => {
		setIsFeedbackOpened(false);
		try {
			await webApi.reportBot({
				id: bot.id,
				reason,
			});
			enqueueSnackbar('Thank you for your feedback!', {variant: 'success'});
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		}
	};

	return {
		handleGoBack,
		handleShareClick,
		isSharingOpened,
		setIsSharingOpened,
		handleCloseOthers,
		isOtherActionsOpened,
		setIsOtherActionsOpened,
		handleOpen,
		handleEditClick,
		handleSubmitReport,
		setIsFeedbackOpened,
		isFeedbackOpened,
		handleGoProfile,
		handleGoChat,
		handleDeleteConfirmation,
		handleDeleteConfirmationClose,
		deleteConfirmationMessage,
		showDeleteConfirmation,
		handleGoToUserProfile,
	};
};
