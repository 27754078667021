import cn from 'classnames';
import backImg from '../../../images/back-desk.svg';
import styles from './ProfileHeader.module.css';
import {HeaderContainer} from '../../Header/Header';
import deleteIcon from './images/delete.svg';
import {
	ActionOptionsBtn,
	ActionOptionsRow,
} from '../../ActionOptionsBtn/ActionOptionsBtn';
import {useNavigate} from 'react-router-dom';
import {useConfirmation} from '../../../common-lib/src/hooks/useConfirmation/useConfirmation';
import {useSnackbar} from 'notistack';
import {useContext} from 'react';
import {AppContext} from '../../../App';
import {webApi} from '../../../api/webApi';
import {chatStorage} from '../../../chatStore/ChatStorage';

export const EditProfileHeader = () => {
	const navigate = useNavigate();
	const {enqueueSnackbar} = useSnackbar();
	const {handleLogout} = useContext(AppContext);

	const handleConfirmDelete = async () => {
		try {
			await webApi.deleteUser();
			handleLogout();
			chatStorage.removeChats();
			window.location.href = '/';
		} catch (error) {
			console.error('Error deleting user:', error);
			enqueueSnackbar('Error deleting user', {variant: 'error'});
		}
	};

	const {open, ConfirmationDialog} = useConfirmation({
		title: 'Are you sure?',
		text: 'All your data including chats history and created bots will be lost',
		okButtonText: 'Delete',
		onSubmit: handleConfirmDelete,
	});

	const handleBack = () => {
		navigate('/profile');
	};

	const handleDelete = () => {
		open();
	};

	return (
		<HeaderContainer>
			<div className={cn(styles.container)}>
				<div className={styles.content}>
					<div className={styles.back} onClick={handleBack}>
						<img src={backImg} alt="Back" />
					</div>
					<h2 className={styles.title}>Edit Profile</h2>
					<ActionOptionsBtn>
						<ActionOptionsRow
							icon={deleteIcon}
							text="Delete User"
							onClick={handleDelete}
							isRed
						/>
					</ActionOptionsBtn>
				</div>
			</div>
			<ConfirmationDialog />
		</HeaderContainer>
	);
};
