import {MenuItem, Modal, Select, SelectChangeEvent} from '@mui/material';
import styles from './Feedback.module.css';
import closeImg from './images/close.svg';
import {useEffect, useState} from 'react';
import {InButton} from '../../common-lib/src/components/InButton/InButton';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (reason: string) => void;
};

const values = [
	'Spam',
	'Violence',
	'Pornography',
	'Child Abuse',
	'Copyright',
	'Personal Details',
	'Illegal Drugs',
];

const defaultValue = 'Select a reason';

const sx = {
	background: 'linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff',
	color: 'white',
};

export const FeedbackModal = ({isOpen, onClose, onSubmit}: Props) => {
	const [value, setValue] = useState<string>(defaultValue);

	const handleChange = (e: SelectChangeEvent<string>) => {
		setValue(e.target.value as string);
	};

	useEffect(() => {
		if (isOpen) {
			setValue(defaultValue);
		}
	}, [isOpen]);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.container}>
					<h2 className={styles.title}>Share report</h2>
					<Select
						className={styles.select}
						value={value}
						onChange={handleChange}
						inputProps={{
							name: 'report',
							id: 'report',
						}}
						sx={sx}
					>
						<MenuItem disabled value="Select a reason" sx={sx}>
							Select a reason
						</MenuItem>
						{values.map((v, i) => (
							<MenuItem value={v} key={v} sx={sx}>
								{v}
							</MenuItem>
						))}
					</Select>
					<InButton
						id="report"
						isFilled
						isDisabled={value === defaultValue}
						className={styles.submit}
						onClick={() => onSubmit(value)}
					>
						Submit
					</InButton>
				</div>
				<img
					src={closeImg}
					alt="close"
					className={styles.close}
					onClick={() => onClose()}
				/>
			</div>
		</Modal>
	);
};
