import {TimeLimitInfo} from '../../../../chatStore/ChatStorage';

export const headerHeight = 64;
export const inputInitialHeight = 24;
export const magicConst = 64;
export const inputMaxHeight = 100;
export const SHOW_VOTE_NOTIFICATION_COUNT = 10;
export const SHOWN_VOTE_NOTIFICATION_KEY = 'SHOWN_VOTE_NOTIFICATION_KEY';
export const CONTEXT_SLICE_LENGTH = -99;
export const DEFAULT_LIMIT_INFO: TimeLimitInfo = {
	hours: 0,
	minutes: 0,
	sec: 0,
	hoursStr: '00',
	minutesStr: '00',
	secStr: '00',
};
