import {Link} from 'react-router-dom';
import styles from './DiscoverBotsBtn.module.css';
import compassImg from './images/compas.svg';

export const DiscoverBotsBtn = () => {
	return (
		<Link to="/" className={styles.btn}>
			<img src={compassImg} alt="discover" className={styles.img} />
			<p className={styles.text}>Discover bots</p>
		</Link>
	);
};
