import {useEffect} from 'react';
import {BotData} from '../../../../../api/types';
import {
	createBotPageJsonLd,
	resetTitleAndDescription,
} from '../../../../../utils';

export const useBotPageMeta = (bot: BotData) => {
	useEffect(() => {
		document.title = `${bot.attributes.name} - character AI - Botify AI`;

		const botDescription =
			bot.attributes.bio ||
			bot.attributes.description ||
			bot.attributes.personaFacts.map((fact) => fact.content).join(' ') ||
			bot.attributes.bio ||
			'';
		const truncatedDescription =
			botDescription.length > 160
				? `${botDescription.substring(0, 160).replace(/\s+\S*$/, '')}...`
				: botDescription;
		const description = `${bot.attributes.name} - ${truncatedDescription} | Botify AI`;
		document
			.querySelector('meta[name="description"]')
			?.setAttribute('content', description);

		createBotPageJsonLd(bot.attributes.name);

		return () => {
			resetTitleAndDescription();
		};
	}, [bot]);
};
