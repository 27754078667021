export function removeHtmlTagsFromString(str: string) {
	return str.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
}

export function fallbackCopyTextToClipboard(text: string) {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		const successful = document.execCommand('copy');
		const msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
}

export const copyToClipboard = (code: boolean, value: string) => {
	const text = code ? removeHtmlTagsFromString(value) : value;
	navigator.clipboard
		.writeText(text)
		.then(() => {
			console.log('Copied to clipboard');
		})
		.catch((err) => {
			console.log('Error: ', err);
			fallbackCopyTextToClipboard(text);
		});
};
