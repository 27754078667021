import styles from './PublicBot.module.css';
import lock from './images/lock.svg';
import check from './images/check.svg';
import photosImg from './images/photos.svg';
import {Switcher} from '../../../common-lib/src/components/Switcher/Switcher';
import cn from 'classnames';

type Props = {
	value: boolean;
	onChange: (value: boolean) => void;
	isSwitch?: boolean;
	small?: boolean;
};

export const GeneratePhotos = ({
	value,
	onChange,
	isSwitch = false,
	small = false,
}: Props) => {
	return (
		<div className={styles.container}>
			<div
				className={cn(styles.row, {[styles.rowSmall]: small})}
				onClick={() => onChange(!value)}
			>
				<img
					src={photosImg}
					alt="generate photos"
					className={cn(styles.lock, {[styles.lockSmall]: small})}
				/>
				<p className={styles.text}>Generate photos</p>
				{!isSwitch && (
					<div className={styles.checkbox}>
						{value && <img src={check} alt="check" className={styles.check} />}
					</div>
				)}
				{isSwitch && (
					<Switcher
						className={styles.switch}
						checked={value}
						onChange={() => onChange(!value)}
					/>
				)}
			</div>
		</div>
	);
};

export const PublicBot = ({
	value,
	onChange,
	isSwitch = false,
	small = false,
}: Props) => {
	return (
		<div className={styles.container}>
			<div
				className={cn(styles.row, {[styles.rowSmall]: small})}
				onClick={() => onChange(!value)}
			>
				<img
					src={lock}
					alt="public bot"
					className={cn(styles.lock, {[styles.lockSmall]: small})}
				/>
				<p className={styles.text}>Public bot</p>
				{!isSwitch && (
					<div className={styles.checkbox}>
						{value && <img src={check} alt="check" className={styles.check} />}
					</div>
				)}
				{isSwitch && (
					<Switcher
						className={styles.switch}
						checked={value}
						onChange={() => onChange(!value)}
					/>
				)}
			</div>
			<p className={styles.note}>
				{value
					? 'Anyone can find your bot, but your messages are always private'
					: 'Only you can find bot, your messages always private'}
			</p>
		</div>
	);
};
