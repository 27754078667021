import playImg from '../../images/play.svg';
import pauseImg from '../../images/pause.svg';
import checkImg from '../../images/check.svg';
import emptyImg from '../../images/empty.svg';
import {VoiceDataType} from '../../data/voicesData';
import './VoiceRow.css';
import cn from 'classnames';

type VoiceRowProps = {
	voice: VoiceDataType;
	selected: boolean;
	onSelect: () => void;
	onPlay: (voice: VoiceDataType | null) => void;
	isPlaying: boolean;
	className?: string;
};

type PlayProps = {
	plying: boolean;
	onClick: () => void;
};

const Play = ({plying, onClick}: PlayProps) => {
	return (
		<img
			className="play-btn"
			src={plying ? pauseImg : playImg}
			alt="play"
			onClick={onClick}
		/>
	);
};

export const VoiceRow = ({
	voice,
	selected,
	onSelect,
	onPlay,
	isPlaying,
	className,
}: VoiceRowProps) => {
	return (
		<div className={cn('voice-row', className)} onClick={() => onSelect()}>
			<Play
				plying={isPlaying}
				onClick={() => {
					onPlay(isPlaying ? null : voice);
					onSelect();
				}}
			/>
			<p className="voice-text">{voice.name}</p>
			{selected && <img className="voice-select" src={checkImg} alt="check" />}
			{!selected && (
				<img
					className="voice-select"
					src={emptyImg}
					alt="empty"
					onClick={onSelect}
				/>
			)}
		</div>
	);
};
