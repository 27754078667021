export const PWPrivacy = () => {
	return (
		<div className="paywall-modal__terms-row">
			<a
				href="https://api.exh.ai/botify-web/doc/terms"
				className="paywall-modal__terms-text"
			>
				Term and Conditions
			</a>
			<p className="paywall-modal__terms-delimiter">|</p>
			<a
				href="https://api.exh.ai/botify-web/doc/privacy"
				className="paywall-modal__terms-text"
			>
				Privacy Policy
			</a>
		</div>
	);
};
