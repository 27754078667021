import {Link} from 'react-router-dom';
import styles from './BecomeCreatorBtn.module.css';
import img from './images/img.svg';
import cn from 'classnames';

export const BecomeCreatorBtn = ({noMargin = false}: {noMargin?: boolean}) => {
	return (
		<Link
			to="https://www.reddit.com/r/botify_ai/comments/1cruudn/authors_of_the_best_bots_will_get_real_money_from"
			className={cn(styles.panelsDailyReward, {
				[styles.panelsDailyRewardNoMargin]: noMargin,
			})}
		>
			<div className={styles.frame}>
				<img src={img} alt="become a creator" className={styles.fi} />
				<div className={styles.div}>
					<div className={styles.textWrapper}>Become a creator</div>
					<div className={styles.textWrapper2}>Earn real money</div>
				</div>
			</div>
		</Link>
	);
};
