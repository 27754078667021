import {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from '../../App';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {Footer} from '../Footer/Footer';
import {Header} from '../Header/Header';
import './Main.css';
import cn from 'classnames';
import {SideBar} from '../BotPageContainer/components/BotPage/Sidebar/SideBar';

type Props = {
	header?: React.ReactNode;
	headerContent?: React.ReactNode;
	isLoading?: boolean;
	className?: string;
};

export const MainContainer = ({
	children,
	headerContent,
	isLoading = false,
	className = '',
	header,
}: React.PropsWithChildren<Props>) => {
	const {isMobile} = useContext(AppContext);
	if (isMobile) {
		return (
			<MobileContainer
				headerContent={headerContent}
				isLoading={isLoading}
				className={className}
				header={header}
			>
				{children}
			</MobileContainer>
		);
	}

	return (
		<DesktopContainer
			headerContent={headerContent}
			isLoading={isLoading}
			className={className}
			header={header}
		>
			{children}
		</DesktopContainer>
	);
};

const DesktopContainer = ({
	children,
	header,
	headerContent,
	isLoading = false,
	className = '',
}: React.PropsWithChildren<Props>) => {
	const [isFooterVisible, setIsFooterVisible] = useState(false);
	const footerRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsFooterVisible(entry.isIntersecting);
			},
			{
				root: null,
				threshold: 1.0,
			}
		);

		if (footerRef.current) {
			observer.observe(footerRef.current);
		}

		return () => {
			if (footerRef.current) {
				observer.unobserve(footerRef.current);
			}
		};
	}, []);

	return (
		<main className={cn('main-desktop', className)}>
			<div className="upper">{header || <Header />}</div>

			<div className="main-container">
				<SideBar />
				<div className="main-content-container">
					<div className={cn('main-content-desk', {nofade: isFooterVisible})}>
						{headerContent}
						{isLoading ? <Spinner withLayout={false} /> : children}
						<div ref={footerRef}></div>
						<Footer />
					</div>
				</div>
			</div>
		</main>
	);
};

const MobileContainer = ({
	children,
	headerContent,
	isLoading = false,
	className = '',
	header,
}: React.PropsWithChildren<Props>) => {
	return (
		<main className={cn('main', className)}>
			<div className="upper">
				{header || <Header />}
				{headerContent}
			</div>

			{isLoading ? <Spinner withLayout={false} /> : children}
			<Footer />
		</main>
	);
};
