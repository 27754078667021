import {Modal} from '@mui/material';
import styles from './SharingModal.module.css';
import {CopyTextArea} from '../../../../../common-lib/src/components/CopyTextArea/CopyTextArea';
import fb from './images/fb.svg';
import twitter from './images/twitter.svg';
import reddit from './images/reddit.svg';
import whatsapp from './images/whatsapp.svg';
import closeImg from './images/close.svg';

type Props = {
	isOpened: boolean;
	onClose: () => void;
	link: string;
	text?: string;
	title?: string;
};

export const SHARING_TEXT = "'Check out this Botify AI bot!'";

export const SharingModal = ({
	isOpened,
	onClose,
	link,
	text = SHARING_TEXT,
	title = 'Share Character',
}: Props) => {
	return (
		<Modal open={isOpened} onClose={onClose}>
			<div className={styles.modal}>
				<div className={styles.container}>
					<h2 className={styles.title}>{title}</h2>
					<CopyTextArea value={link} />
					<div className={styles.buttons}>
						<a
							href={`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${text} #botifyai&hashtag=botifyai`}
							className={styles.a}
							target="blank"
							title="Share on Facebook"
						>
							<img src={fb} alt="facebook" className={styles.img} />
						</a>
						<a
							href={`https://twitter.com/share?url=${link}&text=${SHARING_TEXT} #botifyai&hashtags=botifyai`}
							className={styles.a}
							target="blank"
							title="Share on Twitter"
						>
							<img src={twitter} alt="twitter" className={styles.img} />
						</a>
						<a
							href={`https://www.reddit.com/submit?url=${link}&title=${SHARING_TEXT} #botifyai`}
							className={styles.a}
							target="blank"
							title="Share on Reddit"
						>
							<img src={reddit} alt="reddit" className={styles.img} />
						</a>
						<a
							href={`https://web.whatsapp.com/send?text=${SHARING_TEXT} #botifyai ${link}`}
							className={styles.a}
							target="blank"
							title="Share on WhatsApp"
						>
							<img src={whatsapp} alt="whatsapp" className={styles.img} />
						</a>
					</div>
					<p className={styles.footer}>
						This <span>will not</span> share your conversation with others!
					</p>
				</div>
				<img
					src={closeImg}
					alt="close"
					className={styles.close}
					onClick={() => onClose()}
				/>
			</div>
		</Modal>
	);
};
