import {Link} from 'react-router-dom';
import classes from './FOF.module.css';
import fof from './images/404.webp';
import logo from './images/logo.webp';

export const FOF = () => {
	return (
		<div className={classes.DESKTOP}>
			<div className={classes.overlap}>
				<Link className={classes.logoLink} to="/">
					<img className={classes.logoFull} alt="Logo full" src={logo} />
				</Link>

				<div className={classes.frame}>
					<img className={classes.IMG} alt="Img" src={fof} />
					<div className={classes.TXT}>
						<div className={classes['text-wrapper']}>
							Oups... Page not found!
						</div>
						<p className={classes.div}>
							The page you are looking for appears to be moved, deleted, or does
							not exist.
						</p>
						<Link to="/" className={classes['button-fat']}>
							<div className={classes['overlap-group']}>
								<div className={classes['text-wrapper-2']}>Home page</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
