const getStringFromNumber = (number: number): string => {
	if (!number) {
		return '0';
	}
	if (number > 10 ** 6) {
		return `${Math.round(number / 10 ** 5) / 10}M`;
	}
	if (number > 10 ** 3) {
		return `${Math.round(number / 10 ** 2) / 10}K`;
	}

	return number.toString();
};

export const getMessagesQty = (messagesQty?: number): string => {
	if (messagesQty === undefined) {
		const fakeMsgQty = Math.floor(Math.random() * 10000);
		return getMessagesQty(fakeMsgQty);
	}
	return getStringFromNumber(messagesQty);
};
