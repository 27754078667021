import {useContext, useEffect, useState} from 'react';
import styles from './SendMeBtn.module.css';
import cn from 'classnames';
import {webEventsApi} from '../../../../../amplitude/webEvents';
import {AppContext} from '../../../../../App';
import dot from './images/dot.svg';
import {useNavigate} from 'react-router-dom';

type Props = {
	onClick: (text: string) => void;
	hasVideo?: boolean;
};

const USED_PAIRPHOTO_KEY = 'used_pairphoto';

export const SendMeBtn = ({onClick, hasVideo}: Props) => {
	const [isOpened, setIsOpened] = useState(false);
	const {user, setIsLoginOpened} = useContext(AppContext);
	const navigate = useNavigate();
	const [usedPairPhoto, setUsedPairPhoto] = useState(
		localStorage.getItem(USED_PAIRPHOTO_KEY) === 'true'
	);

	const handleCloseOverlay = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (!target.closest(`.${styles.container}`)) {
			setIsOpened(false);
		}
	};

	useEffect(() => {
		if (isOpened) {
			document.addEventListener('click', handleCloseOverlay);
		} else {
			document.removeEventListener('click', handleCloseOverlay);
		}
		return () => {
			document.removeEventListener('click', handleCloseOverlay);
		};
	}, [isOpened]);

	const handleRowClick = (
		text: string,
		type: 'photos' | 'videos',
		pair?: boolean
	) => {
		if (pair) {
			webEventsApi.pairPhotoRequested({
				is_persona_avatar_set: !!user?.personaAvatar,
			});
		}
		webEventsApi.storeActionTapped({type});

		if (pair && !user?.personaAvatar) {
			if (!user || user?.isAnon) {
				setIsLoginOpened(true);
			} else {
				setIsOpened(false);
				navigate(
					`/profile?open=persona&from=${encodeURIComponent(
						window.location.pathname
					)}`
				);
			}
			return;
		}

		onClick(text);
		setIsOpened(false);
		if (pair) {
			setUsedPairPhoto(true);
		}
	};

	useEffect(() => {
		localStorage.setItem(USED_PAIRPHOTO_KEY, usedPairPhoto.toString());
	}, [usedPairPhoto]);

	return (
		<div className={styles.container}>
			<div className={cn(styles.list, {[styles.listVisible]: isOpened})}>
				<div
					className={styles.listRow}
					onClick={() => handleRowClick('selfie', 'photos')}
				>
					<p className={styles.icon}>🤳</p>
					<p className={styles.text}>Selfie</p>
				</div>
				<div
					className={styles.listRow}
					onClick={() => handleRowClick('romantic photo', 'photos')}
				>
					<p className={styles.icon}>💋</p>
					<p className={styles.text}>Romantic photo</p>
				</div>
				<div
					className={styles.listRow}
					onClick={() => handleRowClick('hot photo', 'photos')}
				>
					<p className={styles.icon}>🔥</p>
					<p className={styles.text}>Hot photo</p>
				</div>
				<div
					className={styles.listRow}
					onClick={() => handleRowClick('our photo together', 'photos', true)}
				>
					<p className={styles.icon}>👫</p>
					<p className={styles.text}>Pair photo</p>
					{!usedPairPhoto && (
						<img src={dot} alt="dot" className={styles.pairPhotoHint} />
					)}
				</div>
				{hasVideo && (
					<div
						className={styles.listRow}
						onClick={() => handleRowClick('video', 'videos')}
					>
						<p className={styles.icon}>📹</p>
						<p className={styles.text}>Video</p>
					</div>
				)}
			</div>
			<div className={styles.btn} onClick={() => setIsOpened(!isOpened)}>
				{!usedPairPhoto && (
					<img src={dot} alt="dot" className={styles.pairPhotoHint} />
				)}
				<p className={styles.btnText}>Send me...</p>
			</div>
		</div>
	);
};
