import {Button} from '@mui/material';
import './InButton.css';
import cn from 'classnames';

type Props = {
	isFilled?: boolean;
	isDisabled?: boolean;
	onClick?: (e?: any) => void;
	className?: string;
	children: string | React.ReactNode;
	light?: boolean;
	id: string;
	inverted?: boolean;
	loading?: boolean;
};

export const InButton = ({
	light = false,
	isFilled = false,
	isDisabled = false,
	children,
	onClick = () => {},
	className = '',
	id,
	inverted = false,
	loading = false,
}: Props) => {
	const lightClass = light ? 'light' : '';
	return (
		<Button
			variant={`${isFilled ? 'contained' : 'outlined'}`}
			className={cn(
				'inbutton',
				className,
				lightClass,
				{
					inbutton_inverted: inverted,
				},
				{inbutton_loading: loading}
			)}
			onClick={onClick}
			id={id}
			disabled={isDisabled}
		>
			{children}
		</Button>
	);
};
