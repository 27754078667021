import {
	init,
	logEvent,
	identify,
	Identify,
	add,
	setUserId,
	setOptOut,
} from '@amplitude/analytics-browser';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';
import {AMPLITUDE_KEY} from './constants';
import {isLocalEnv} from '../common-lib/src/common/utils';
import {WebEventsProvider} from './webEvents';

class AmplitudeApi implements WebEventsProvider {
	isOn: boolean;
	providerId = 'Amplitude';

	constructor() {
		this.isOn = !isLocalEnv();
		this.init();
	}

	init() {
		if (!this.isOn) {
			return;
		}

		try {
			const key = AMPLITUDE_KEY;
			init(key);
			const sessionReplay = sessionReplayPlugin({sampleRate: 0.1});
			add(sessionReplay);
		} catch (error) {
			console.warn('Failed to initialize Amplitude:', error);
			this.isOn = false;
		}
	}

	setUserProps(data: Record<string, any>) {
		if (!this.isOn) {
			return;
		}

		const event = new Identify();
		Object.entries(data).forEach(([key, value]) => {
			event.set(key, value);
		});

		identify(event);
	}

	setUserSplit(split: string) {
		if (!this.isOn || !split) {
			return;
		}

		const event = new Identify();
		event.set('split_response', split);

		identify(event);
	}

	setUserId(userId: string) {
		if (!this.isOn) {
			return;
		}

		setUserId(userId);
	}

	event(event: string, data: any) {
		if (!this.isOn) {
			console.log('Amplitude: event', event, data);
			return;
		}
		try {
			logEvent(event, data);
		} catch (error) {
			console.warn('Failed to log Amplitude event:', error);
		}
	}

	optOut(): void {
		if (!this.isOn) {
			return;
		}

		setOptOut(true);
	}
}

export const webAmpliApi = new AmplitudeApi();
