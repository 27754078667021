import styles from './RetryFeedbackRow.module.css';
import betterImg from './images/better.svg';
import sameImg from './images/same.svg';
import worseImg from './images/worse.svg';
import closeImg from './images/close.svg';
import {Message} from '../../../../api/Api';
import {MessageVote, VoteMessageData} from '../ChatMain.types';

type Props = {
	onFeedback?: (data: VoteMessageData) => void;
	onClose?: () => void;
	message: Message;
};

const FeedbackBtn = ({
	icon,
	text,
	onClick,
}: {
	icon: string;
	text: string;
	onClick: () => void;
}) => {
	return (
		<div onClick={onClick} className={styles.btn}>
			<img src={icon} alt={text} className={styles.icon} />
			<p className={styles.btnText}>{text}</p>
		</div>
	);
};

export const RetryFeedbackRow = ({onFeedback, onClose, message}: Props) => {
	if (!onFeedback || !onClose) {
		return null;
	}

	const handleClick = (vote: MessageVote) => {
		onFeedback({vote, message});
		onClose();
	};

	return (
		<div className={styles.retryFeedbackRow}>
			<div className={styles.textAndBtns}>
				<p className={styles.text}>Was this response better or worse?</p>
				<div className={styles.btns}>
					<FeedbackBtn
						icon={betterImg}
						text="Better"
						onClick={() => handleClick('better')}
					/>
					<FeedbackBtn
						icon={sameImg}
						text="Same"
						onClick={() => handleClick('same')}
					/>
					<FeedbackBtn
						icon={worseImg}
						text="Worse"
						onClick={() => handleClick('worse')}
					/>
				</div>
			</div>
			<img
				src={closeImg}
				alt="Close"
				onClick={onClose}
				className={styles.closeBtn}
			/>
		</div>
	);
};
