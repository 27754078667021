import {useState, useContext, useEffect} from 'react';
import {AppContext} from '../../../App';
import {CategoryAndBots} from '../../../api/apiHelper';
import {BotData} from '../../../api/types';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import {MainContainer} from '../../Main/MainContainer';
import {TilesRow} from '../../TilesRow/TilesRow';
import {getHeaderContent} from '../Category';
import {webApi} from '../../../api/webApi';
import {ScrollContainer} from '../../ScrollButtons/ScrollBtns';
import styles from './NewCategory.module.css';
import {MobileNewCategoryScreen} from './MobileNewCategoryScreen/MobileNewCategoryScreen';

type Props = {
	category: CategoryAndBots;
};

export const groupsSortOrder: string[] = [
	'today',
	'yesterday',
	'week',
	'month',
];

type ScrollProps = {
	bots: BotData[];
	categoryData: CategoryAndBots;
};

const RowWithBtnScroll = ({bots, categoryData}: ScrollProps) => {
	return (
		<ScrollContainer useIref className={styles.scrollBtn}>
			<TilesRow bots={bots} withRate categoryData={categoryData} />
		</ScrollContainer>
	);
};

export const useNewCategoryScreen = () => {
	const [isLoading, setIsLoading] = useState(true);
	const {isMobile, categories} = useContext(AppContext);
	const [groups, setGroups] = useState<{[key: string]: BotData[]}>({});

	const fetchData = async () => {
		try {
			setIsLoading(true);

			const groups = await webApi.getLikePeriod(isMobile ? 5 : 8);

			setGroups(groups);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const rows = Object.entries(groups).sort(
		([a], [b]) => groupsSortOrder.indexOf(a) - groupsSortOrder.indexOf(b)
	);

	return {
		isLoading,
		rows,
		categories,
		isMobile,
	};
};

export const NewCategoryScreen = ({category}: Props) => {
	const {isMobile} = useContext(AppContext);

	if (isMobile) {
		return <MobileNewCategoryScreen />;
	}

	return <DesktopNewCategoryScreen category={category} />;
};

export const DesktopNewCategoryScreen = ({category}: Props) => {
	const {isLoading, rows, categories, isMobile} = useNewCategoryScreen();

	return (
		<MainContainer headerContent={getHeaderContent(isMobile)}>
			{!isLoading &&
				rows.map(([key, bots], i) => {
					const c = categories[key];
					if (!c) {
						return null;
					}

					return (
						<div
							style={{
								width: '100%',
							}}
							key={key}
						>
							<RowWithBtnScroll
								key={key}
								bots={bots}
								categoryData={{
									bots: bots,
									data: c.data,
								}}
							/>
						</div>
					);
				})}
			{isLoading ? <Spinner withLayout={false} noTopMargin /> : null}
		</MainContainer>
	);
};
