import {Dialog} from '@mui/material';
import styles from './MobileDialog.module.css';
import {CommonTransition} from '../../../../common-lib/src/components/CommonTransition/CommonTransition';
import {PropsWithChildren} from 'react';
import backImg from '../images/back.svg';
import cn from 'classnames';

type Props = {
	openned: boolean;
	onClose: () => void;
	title: string;
	contentClassName?: string;
};

export const MobileDialog = ({
	openned,
	onClose,
	title,
	children,
	contentClassName,
}: PropsWithChildren<Props>) => {
	return (
		<Dialog
			open={openned}
			onClose={onClose}
			fullScreen
			className={styles.dialog}
			TransitionComponent={CommonTransition}
			sx={{
				height: '100dvh',
				backgroundColor: 'black',
				boxSizing: 'border-box',
				width: '100%',
			}}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<img
						src={backImg}
						alt="back"
						onClick={onClose}
						className={styles.back}
					/>
					<h2 className={styles.title}>{title}</h2>
				</div>
				<div className={cn(styles.content, contentClassName)}>{children}</div>
			</div>
		</Dialog>
	);
};
