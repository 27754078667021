import {Pronoun} from './NamePronoun/NamePronoun';

export const convertPronoun = (pronoun: string): Pronoun => {
	switch (pronoun) {
		case 'he/him':
			return 'He';
		case 'she/her':
			return 'She';
		case 'they/them':
			return 'They';
		default:
			return 'N/A';
	}
};
