import {Button} from '@mui/material';
import {Tile} from '../Tile/Tile';
import styles from './styles.TileRow.module.css';
import {useNavigate} from 'react-router-dom';
import {CategoryAndBots} from '../../api/apiHelper';
import cn from 'classnames';
import {AppContext, PERIOD_CATEGORIES, VISITED_CHAD_ID} from '../../App';
import {BotData} from '../../api/types';
import {MobileTile} from '../Tile/MobileTile';
import {useContext} from 'react';
import {webEventsApi} from '../../amplitude/webEvents';

type Props = {
	categoryData: CategoryAndBots;
	bots: BotData[];
	withShowAll?: boolean;
	isPreview?: boolean;
	index?: number;
	withRate?: boolean;
	iref?: any;
	skipEvents?: boolean;
};

export const TilesPlate = ({
	categoryData,
	bots,
	withShowAll = false,
	isPreview = false,
	index,
	withRate,
	skipEvents = false,
}: Props) => {
	const {data} = categoryData;
	const navigate = useNavigate();
	const handleShowAll = () => {
		navigate(`/category_${data.id}`);
	};
	const {isMobile} = useContext(AppContext);
	const isPeriodCategory = PERIOD_CATEGORIES.includes(data.attributes.slug);

	const handleBotClick = (bot: BotData) => {
		if (skipEvents) {
			return;
		}
		if (isPreview) {
			webEventsApi.exploreBotTapped({
				category_id: categoryData.data.id,
				category_name: categoryData.data.attributes.name,
				bot_index: bots.findIndex((b) => b.id === bot.id).toString(),
				bot_name: bot.attributes.name,
				bot_chat_id: bot.id,
				category_index: index || 0,
			});
		} else {
			webEventsApi.categoryDetailsBotTapped({
				category_id: categoryData.data.id,
				category_name: categoryData.data.attributes.name,
				bot_index: bots.findIndex((b) => b.id === bot.id).toString(),
				bot_name: bot.attributes.name,
			});
		}
	};

	return (
		<div className={cn(styles.container, 'tiles-row-container')}>
			<div
				className={cn(
					isMobile ? styles.mobileGrid : styles.grid,
					{
						[styles.gridPreview]: isPreview,
						[styles.gridWithRate]: withRate,
					},
					'tiles-row_mobile'
				)}
			>
				{bots.map((bot, i) => {
					if (isMobile) {
						return (
							<MobileTile
								bot={bot}
								key={bot.id}
								onClick={() => handleBotClick(bot)}
							/>
						);
					}
					return (
						<Tile
							bot={bot}
							key={bot.id}
							onClick={() => handleBotClick(bot)}
							rate={isPeriodCategory ? i : undefined}
						/>
					);
				})}
			</div>
		</div>
	);
};

export const TilesRow = ({categoryData, withRate, iref}: Props) => {
	const {bots, data} = categoryData;
	const navigate = useNavigate();
	const handleShowAll = () => {
		navigate(`/category_${data.id}`);
	};

	return (
		<div
			className={cn(styles.container, {
				[styles.containerWithRate]: withRate,
			})}
		>
			<div className={styles.headerRow}>
				<h2 className={styles.header}>{data.attributes.name}</h2>
				<Button
					variant="text"
					className={styles.showAll}
					id="showAll"
					onClick={handleShowAll}
				>
					Show All
				</Button>
			</div>
			<div
				className={cn(styles.row, {
					[styles.rowWithRate]: withRate,
				})}
				ref={iref}
			>
				{bots.map((bot, index) => {
					return (
						<Tile
							bot={bot}
							key={bot.id}
							onlyPhoto={categoryData.data.id.toString() === VISITED_CHAD_ID}
							className={styles.tile}
							rate={withRate ? index : undefined}
						/>
					);
				})}
			</div>
		</div>
	);
};
