import FayVoice from '../audio/Fay.wav';
import MarcVoice from '../audio/Marc.wav';
import FeliciVoice from '../audio/Felicia.wav';
import FridaVoice from '../audio/Frida.wav';
import FloraVoice from '../audio/Flora.wav';
import FreyaVoice from '../audio/Freya.wav';
import FarynVoice from '../audio/Faryn.wav';
import FranchescaVoice from '../audio/Franchesca.wav';
import MaxVoice from '../audio/Max.wav';
import FionaVoice from '../audio/Fiona.wav';
import MiguelVoice from '../audio/Miguel.wav';
import MustafaVoice from '../audio/Mustafa.wav';
import MikeVoice from '../audio/Mike.wav';
import MarcoVoice from '../audio/Marco.wav';
import MasonVoice from '../audio/Mason.wav';
import MikaelVoice from '../audio/Mikael.wav';
import MylesVoice from '../audio/Myles.wav';

export type VoiceDataType = {
	src: string;
	name: string;
	isFemale: boolean;
};

export const voicesData: VoiceDataType[] = [
	{
		src: FayVoice,
		name: 'Fay',
		isFemale: true,
	},
	{
		src: FeliciVoice,
		name: 'Felicia',
		isFemale: true,
	},
	{
		src: FionaVoice,
		name: 'Fiona',
		isFemale: true,
	},
	{
		src: FreyaVoice,
		name: 'Freya',
		isFemale: true,
	},
	{
		src: FloraVoice,
		name: 'Flora',
		isFemale: true,
	},
	{
		src: FarynVoice,
		name: 'Faryn',
		isFemale: true,
	},
	{
		src: FranchescaVoice,
		name: 'Franchesca',
		isFemale: true,
	},
	{
		src: FridaVoice,
		name: 'Frida',
		isFemale: true,
	},
	{
		src: MarcVoice,
		name: 'Marc',
		isFemale: false,
	},
	{
		src: MaxVoice,
		name: 'Max',
		isFemale: false,
	},
	{
		src: MiguelVoice,
		name: 'Miguel',
		isFemale: false,
	},
	{
		src: MustafaVoice,
		name: 'Mustafa',
		isFemale: false,
	},
	{
		src: MikeVoice,
		name: 'Mike',
		isFemale: false,
	},
	{
		src: MarcoVoice,
		name: 'Marco',
		isFemale: false,
	},
	{
		src: MasonVoice,
		name: 'Mason',
		isFemale: false,
	},
	{
		src: MikaelVoice,
		name: 'Mikael',
		isFemale: false,
	},
	{
		src: MylesVoice,
		name: 'Myles',
		isFemale: false,
	},
];
