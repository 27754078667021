import {Button, ButtonGroup} from '@mui/material';
import {TextArea} from '../../../common-lib/src/components/TextArea/TextArea';
import styles from './NamePronoun.module.css';
import cn from 'classnames';

export type Pronoun = 'He' | 'She' | 'They' | 'N/A';

type Props = {
	showError?: boolean;
	onChangeName: (value: string) => void;
	onChangePronoun: (value: Pronoun) => void;
	name: string;
	pronoun: Pronoun;
	onCheck?: (values: string) => void;
	disabled?: boolean;
	title?: string;
};

type PronounSelectorProps = {
	pronoun: Pronoun;
	handlePronounClick: (value: Pronoun) => void;
	disabled?: boolean;
	className?: string;
};

export const PronounSelector = ({
	pronoun,
	handlePronounClick,
	disabled,
	className,
}: PronounSelectorProps) => {
	return (
		<ButtonGroup className={cn(styles.pronoun, className)} disabled={disabled}>
			<Button
				onClick={() => handlePronounClick('N/A')}
				className={cn(styles.btn, {[styles.btnSelected]: pronoun === 'N/A'})}
			>
				N/A
			</Button>
			<Button
				onClick={() => handlePronounClick('He')}
				className={cn(styles.btn, {[styles.btnSelected]: pronoun === 'He'})}
			>
				He
			</Button>
			<Button
				onClick={() => handlePronounClick('She')}
				className={cn(styles.btn, {[styles.btnSelected]: pronoun === 'She'})}
			>
				She
			</Button>
			<Button
				onClick={() => handlePronounClick('They')}
				className={cn(styles.btn, {[styles.btnSelected]: pronoun === 'They'})}
			>
				They
			</Button>
		</ButtonGroup>
	);
};

export const NamePronoun = ({
	showError,
	onChangeName,
	onChangePronoun,
	name,
	pronoun,
	onCheck,
	disabled,
	title = 'Enter character name',
}: Props) => {
	const handlePronounClick = (value: Pronoun) => {
		onChangePronoun(value);
	};

	const handleBlur = (value: string) => {
		onCheck?.(value);
	};

	return (
		<div className={styles.container}>
			<TextArea
				onChange={onChangeName}
				showError={showError}
				value={name}
				placeholder={title}
				maxLength={30}
				className={styles.name}
				onBlur={() => handleBlur(name)}
				disabled={disabled}
			/>
			{showError && (
				<p className={styles.error}>
					Bot name was detected as inaproppriate. Please change it and try again
				</p>
			)}
			<PronounSelector
				pronoun={pronoun}
				handlePronounClick={handlePronounClick}
				disabled={disabled}
			/>
		</div>
	);
};
