import {api} from '../common-lib/src/api/Api';
import {isLocalEnv} from '../common-lib/src/common/utils';
import {checkIsAgreed} from '../components/CookieBar/utils';

type PaywallPurchaseSuccessData = {
	revenue: string | number;
	currency: string;
	transaction_id: string;
};

export class GtagApi {
	private static event(name: string, data = {} as Record<string, any>) {
		if (isLocalEnv()) {
			console.log(`Gtag event: ${name} ${JSON.stringify(data)}`);
			return;
		}
		if (!checkIsAgreed()) {
			return;
		}
		data.user_id = api.getUserId();
		data.event = name;
		//@ts-ignore
		if (window.dataLayer) {
			try {
				//@ts-ignore
				dataLayer.push(data);
			} catch (e) {
				console.error('Error in gtag event:', e);
				console.log(`Gtag event: ${name} ${JSON.stringify(data)}`);
			}
		} else {
			console.log('No gtag');
			console.log(`Gtag event: ${name} ${JSON.stringify(data)}`);
		}
	}

	static paywallPurchaseSuccess(data: PaywallPurchaseSuccessData) {
		this.event('paywall_purchase_success', data);
	}

	static paywallAppear() {
		this.event('paywall_appear');
	}

	static paywallPurchaseStarted() {
		this.event('paywall_purchase_started');
	}

	static authOpened() {
		this.event('auth_opened');
	}

	static appBannerAppear() {
		this.event('app_banner_appear');
	}

	static appBannerLinkClick() {
		this.event('app_banner_link_click');
	}

	static authNewUser() {
		this.event('auth_new_user');
	}

	static authSignIn() {
		this.event('auth_sign_in');
	}
}
