import {useContext} from 'react';
import {AppContext} from '../../App';
import styles from './GetAccessBtn.module.css';
import {lock, bg} from './images/GetAccessBtn.images';
import cn from 'classnames';

export const GetAccessBtn = ({className}: {className?: string}) => {
	const {handleLoginOrPaywall, user} = useContext(AppContext);

	const handleGetClick = () => {
		handleLoginOrPaywall();
	};

	if (user?.isPaid) {
		return null;
	}

	return (
		<div
			className={cn(styles.banner, className)}
			onClick={handleGetClick}
			style={{backgroundImage: `url(${bg})`}}
		>
			<img
				src={lock}
				alt="Lock"
				className={styles.lock}
				title="Get Full Access"
			/>
			<div className={styles.texts}>
				<p className={styles.boldText}>Get Full Access</p>
				<p className={styles.text}>Romantic mode, photos and more</p>
			</div>
		</div>
	);
};
