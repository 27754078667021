import {Modal} from '@mui/material';
import {useEffect, useState} from 'react';
import {
	getAuth,
	GoogleAuthProvider,
	OAuthProvider,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	linkWithCredential,
	linkWithPopup,
	linkWithRedirect,
	User,
	AuthCredential,
	EmailAuthProvider,
} from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import {CloseModalBtn} from '../../common-lib/src/components/CloseModalBtn/CloseModalBtn';
import {GtagApi} from '../../api/gtagApi';
import appleLogo from './images/apple-logo.svg';
import './WebLoginModal.css';
import {webEventsApi} from '../../amplitude/webEvents';
initializeApp({
	apiKey: 'AIzaSyCGy1uHHw9cGf5ulJBmEQGbgyidMHWuET4',
	authDomain: 'botifyai-f0e70.firebaseapp.com',
	databaseURL: 'https://botifyai-f0e70-default-rtdb.firebaseio.com',
	projectId: 'botifyai-f0e70',
	storageBucket: 'botifyai-f0e70.appspot.com',
	messagingSenderId: '501689208409',
	appId: '1:501689208409:web:afcd1630203be5856c2b48',
	measurementId: 'G-76TKQ4TF4Z',
});

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

type Props = {
	openned: boolean;
	onClose: () => void;
	onSuccess: (data: {email: string; token: string}) => void;
	hasOpenedModal: boolean;
	forceClose: boolean;
};

type AuthMode = 'signin' | 'signup' | 'reset';

const errorMap = {
	'auth/wrong-password': 'Wrong password. Please try again.',
	'auth/user-not-found':
		'No user found with this email. Please check your email or sign up.',
	'auth/email-already-in-use':
		'This email is already registered. Use it to log in or choose a different email.',
	'auth/credential-already-in-use':
		'This credential is already linked to another account. Try a different login method.',
	'auth/popup-closed-by-user':
		'You closed the popup before signing in. Please try again.',
	'auth/cancelled-popup-request':
		'Sign-in request cancelled. Complete the current sign-in before starting a new one.',
	'auth/network-request-failed':
		'Network error. Check your connection and try again.',
	'auth/invalid-credential':
		'Invalid credential. Please try again with a different login method.',
	'auth/popup-blocked':
		'Popups are blocked. Enable them in your browser settings and try again.',
};

export const WebLoginModal = ({
	openned,
	onClose,
	onSuccess,
	hasOpenedModal,
	forceClose,
}: Props) => {
	const [authMode, setAuthMode] = useState<AuthMode>('signin');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setError('');
		setLoading(false);
		setEmail('');
		setPassword('');
		onClose();
	};

	useEffect(() => {
		if (openned) {
			GtagApi.authOpened();
		}
	}, [openned]);

	useEffect(() => {
		setTimeout(() => {
			const body = document.querySelector('body');
			body?.style.setProperty('overflow', hasOpenedModal ? 'hidden' : 'auto');
		}, 100);
	}, [hasOpenedModal]);

	const linkAnonymousAccount = async (
		user: User,
		credential: AuthCredential | GoogleAuthProvider | OAuthProvider,
		useRedirect = false
	) => {
		try {
			if (
				credential instanceof GoogleAuthProvider ||
				credential instanceof OAuthProvider
			) {
				if (useRedirect) {
					await linkWithRedirect(user, credential);
					return null;
				} else {
					const result = await linkWithPopup(user, credential);
					return result.user;
				}
			} else {
				const result = await linkWithCredential(user, credential);
				return result.user;
			}
		} catch (error: any) {
			throw error;
		}
	};

	const handleProviderSignIn = async (
		provider: GoogleAuthProvider | OAuthProvider,
		isCredentialAlreadyInUse = false
	) => {
		try {
			setLoading(true);
			setError('');

			if (auth.currentUser?.isAnonymous && !isCredentialAlreadyInUse) {
				const user = await linkAnonymousAccount(auth.currentUser, provider);
				if (user) {
					onSuccess({
						email: user.email || '',
						token: await user.getIdToken(),
					});
					handleClose();
				}
			} else {
				const result = await signInWithPopup(auth, provider);
				onSuccess({
					email: result.user.email || '',
					token: await result.user.getIdToken(),
				});
				handleClose();
			}
		} catch (error: any) {
			if (
				error.code === 'auth/credential-already-in-use' ||
				error.code === 'auth/email-already-in-use'
			) {
				handleProviderSignIn(provider, true);
			} else {
				webEventsApi.loginError({
					error: error?.message,
					error_code: error?.code,
				});
				if (error.code in errorMap) {
					setError(errorMap[error.code as keyof typeof errorMap]);
				} else {
					setError(error.message);
				}
			}
		} finally {
			setLoading(false);
		}
	};

	const handleEmailAuth = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			setLoading(true);
			setError('');

			if (authMode === 'reset') {
				await sendPasswordResetEmail(auth, email, {
					url: window.location.href,
				});
				setError('Password reset email sent!');
				return;
			}

			const emailCredential = EmailAuthProvider.credential(email, password);

			if (auth.currentUser?.isAnonymous) {
				try {
					const user = await linkAnonymousAccount(
						auth.currentUser,
						emailCredential
					);
					if (user) {
						onSuccess({
							email: user.email || '',
							token: await user.getIdToken(),
						});
						handleClose();
					}
				} catch (error: any) {
					if (
						error.code === 'auth/email-already-in-use' &&
						authMode === 'signin'
					) {
						const result = await signInWithEmailAndPassword(
							auth,
							email,
							password
						);
						onSuccess({
							email: result.user.email || '',
							token: await result.user.getIdToken(),
						});
						handleClose();
					} else {
						throw error;
					}
				}
			} else {
				let result;
				if (authMode === 'signin') {
					result = await signInWithEmailAndPassword(auth, email, password);
				} else {
					result = await createUserWithEmailAndPassword(auth, email, password);
				}

				onSuccess({
					email: result.user.email || '',
					token: await result.user.getIdToken(),
				});
				handleClose();
			}
		} catch (error: any) {
			webEventsApi.loginError({
				error: error?.message,
				error_code: error?.code,
			});
			if (error.code in errorMap) {
				setError(errorMap[error.code as keyof typeof errorMap]);
			} else {
				setError(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const LoadingSpinner = () => (
		<div className="loading-spinner">
			<div className="spinner"></div>
			<p>Processing authentication...</p>
		</div>
	);

	return (
		<Modal
			className="login-modal"
			open={!forceClose}
			onClose={handleClose}
			style={{
				visibility: openned ? 'visible' : 'hidden',
				zIndex: openned ? 999 : -999,
			}}
		>
			<div className="column login-modal__inner">
				{loading && <LoadingSpinner />}

				<h2 className="login-modal__title">Log In / Sign Up</h2>

				<div className="login-modal__buttons">
					<button
						onClick={() => handleProviderSignIn(googleProvider)}
						disabled={loading}
						className="provider-button google-button"
					>
						<img
							className="provider-button__icon"
							src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
							alt="Google logo"
						/>
						Continue with Google
					</button>
					<button
						onClick={() => handleProviderSignIn(appleProvider)}
						disabled={loading}
						className="provider-button apple-button"
					>
						<img
							className="provider-button__icon"
							src={appleLogo}
							alt="Apple logo"
						/>
						Continue with Apple
					</button>
				</div>

				<form onSubmit={handleEmailAuth} className="email-form">
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						disabled={loading}
						required
					/>
					{authMode !== 'reset' && (
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							disabled={loading}
							required
							minLength={6}
						/>
					)}

					{error && <div className="error-message">{error}</div>}

					<button type="submit" disabled={loading}>
						{authMode === 'signin'
							? 'Sign In'
							: authMode === 'signup'
							? 'Sign Up'
							: 'Reset Password'}
					</button>
				</form>

				<div className="auth-links">
					{authMode === 'signin' ? (
						<>
							<button onClick={() => setAuthMode('reset')} disabled={loading}>
								Forgot password?
							</button>
							<button onClick={() => setAuthMode('signup')} disabled={loading}>
								Create account
							</button>
						</>
					) : authMode === 'signup' ? (
						<button onClick={() => setAuthMode('signin')} disabled={loading}>
							Already have an account?
						</button>
					) : (
						<button onClick={() => setAuthMode('signin')} disabled={loading}>
							Back to sign in
						</button>
					)}
				</div>

				<div className="login-modal__terms">
					By signing in, you agree to our{' '}
					<a
						href="https://api.exh.ai/botify-web/doc/terms"
						className="paywall-modal__terms-text"
						title="Terms of Service"
					>
						Terms of Service
					</a>{' '}
					and you have read our{' '}
					<a
						href="https://api.exh.ai/botify-web/doc/privacy"
						className="paywall-modal__terms-text"
						title="Privacy Policy"
					>
						Privacy Policy
					</a>
					.
				</div>

				<CloseModalBtn onClose={handleClose} />
			</div>
		</Modal>
	);
};
