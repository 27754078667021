import {useRef} from 'react';
import styles from './AvatarSelector.module.css';
import avatarPlaceholder from './images/avatarPlaceholder.svg';
import {Button} from '@mui/material';
import pics from './images/pics.svg';
import stick from './images/stick.svg';
import cn from 'classnames';
import {Spinner} from '../../../common-lib/src/components/Spinner/Spinner';
import {ChatPhotoStyle} from '../../../common-lib/src/api/Api.types';

export type AvatarSelectorProps = {
	showError?: boolean;
	onGenerateClick: () => void;
	value: string | null;
	onChange: (file: File, value: string | null, style: ChatPhotoStyle) => void;
	loading: boolean;
	avatarData: {
		idle_url?: string;
		image_url: string;
	} | null;
	className?: string;
};

export const useAvatarSelector = ({
	onChange,
	avatarData,
}: AvatarSelectorProps) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleAvatarSelect = (file: File) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = (readerEvent) => {
			const content = readerEvent?.target?.result;
			onChange(file, content as string, 'realistic');
		};
	};

	const handleAvatarEditClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		const file = e?.dataTransfer?.files[0];
		if (!file) {
			return;
		}

		if (fileInputRef.current) {
			handleAvatarSelect(file);
		}
	};

	const handleAvatrInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0];

		if (!file) {
			return;
		}

		handleAvatarSelect(file);
	};

	const idleAvatar = avatarData?.idle_url ? (
		<video autoPlay muted={true} loop playsInline className={styles.avatar}>
			<source src={avatarData.idle_url} type="video/mp4" />
		</video>
	) : null;

	return {
		handleDrop,
		handleAvatarEditClick,
		handleAvatrInputChange,
		idleAvatar,
		fileInputRef,
	};
};

export const AvatarSelector = ({
	showError,
	onGenerateClick,
	value,
	onChange,
	loading,
	avatarData,
	className,
}: AvatarSelectorProps) => {
	const {
		handleDrop,
		handleAvatarEditClick,
		handleAvatrInputChange,
		idleAvatar,
		fileInputRef,
	} = useAvatarSelector({
		showError,
		onGenerateClick,
		value,
		onChange,
		loading,
		avatarData,
	});

	if (loading) {
		return (
			<div className={styles.avatarSelector}>
				<Spinner withLayout={false} />
			</div>
		);
	}

	return (
		<div className={cn(styles.avatarSelector, className)}>
			<div
				className="upload-photo-modal__outer-container"
				onDrop={handleDrop}
				// onDropCapture={handleDrop}
				onDragOver={(e) => e.preventDefault()}
			>
				<div className="upload-photo-modal__inner-container">
					{idleAvatar || (
						<img
							src={value || avatarPlaceholder}
							alt="avatar"
							className={cn(styles.avatar, {[styles.avatarError]: showError})}
						/>
					)}
				</div>
			</div>
			<div className={styles.right}>
				<div className={styles.row}>
					<img src={pics} alt="chose a file" className={styles.img} />
					<Button
						variant="text"
						id="browse-files"
						className={styles.btn}
						onClick={handleAvatarEditClick}
					>
						Choose a file
					</Button>
					<input
						type="file"
						className={styles.fileInput}
						ref={fileInputRef}
						accept="image/png, image/jpeg"
						onChange={handleAvatrInputChange}
					/>
				</div>
				<div className={styles.row}>
					<img src={stick} alt="generate-with-ai" className={styles.img} />
					<Button
						variant="text"
						id="generate-with-ai"
						className={styles.btn}
						onClick={onGenerateClick}
					>
						Generate with AI
					</Button>
					<input
						type="file"
						className={styles.fileInput}
						ref={fileInputRef}
						accept="image/png, image/jpeg"
						onChange={handleAvatrInputChange}
					/>
				</div>
			</div>
		</div>
	);
};
