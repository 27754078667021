import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './BotPageContainer.css';
import {chatStorage} from '../../chatStore/ChatStorage';
import {BotPage} from './components/BotPage/BotPage';
import {webApi} from '../../api/webApi';
import {BotData} from '../../api/types';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {webEventsApi} from '../../amplitude/webEvents';
import {BotProfile} from './components/BotPage/BotProfile/BotProfile';

export const BotPageContainer = () => {
	const {pathname} = useLocation();
	const isChat = pathname.endsWith('/chat');
	const botId = isChat
		? pathname.split('_')[1]?.replace('/chat', '')
		: pathname.split('_')[1];
	const [bot, setBot] = useState<BotData | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const botData = await webApi.getBot(botId);
				if (!botData?.data) {
					navigate('404');
					return;
				}
				chatStorage.setChatVisited(botId);
				setBot(botData.data);
			} catch (e) {
				console.log(e);
				navigate('404');
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [botId]);

	useEffect(() => {
		if (!bot) {
			return;
		}

		webEventsApi.chatAppear({
			chat_id: bot.id,
			bot_name: bot.attributes.name,
			chat_type: bot.attributes.firebaseUserId ? 'custom_shared' : 'default',
		});
	}, [bot]);

	if (isLoading) {
		return <Spinner withLayout={false} />;
	}

	if (bot && isChat) {
		return <BotPage bot={bot} />;
	}

	if (bot && !isChat) {
		return <BotProfile bot={bot} />;
	}

	return null;
};
