import {useContext, useEffect, useState} from 'react';
import styles from './ProfileOptionsList.module.css';
import {AppContext} from '../../App';
import userImg from './images/user.svg';
import logoutImg from './images/logout.svg';
import manageImg from './images/manage.svg';
import {Link, useNavigate} from 'react-router-dom';
import {usersApi} from '../../api/usersApi/usersApi';

export const ProfileOptionsList = () => {
	const {user, handleLogout} = useContext(AppContext);
	const [isOpened, setIsOpened] = useState(false);
	const [manageSubscriptionUrl, setManageSubscriptionUrl] = useState('');
	const navigate = useNavigate();

	const handleLogoutClick = () => {
		handleLogout(true);
		close();
	};

	const close = () => {
		setIsOpened(false);
		document.removeEventListener('click', handleClose);
	};
	const handleClose = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`#profile`)) {
			return;
		}

		setIsOpened(false);
	};

	const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		const isNewOpened = !isOpened;
		setIsOpened(isNewOpened);
		if (isNewOpened) {
			document.addEventListener('click', handleClose);
		} else {
			document.removeEventListener('click', handleClose);
		}
	};

	const handleProfileClick = () => {
		navigate('/profile');
	};

	useEffect(() => {
		return () => {
			document.removeEventListener('click', handleClose);
		};
	}, []);

	useEffect(() => {
		if (!user?.isPaid) {
			return;
		}

		usersApi.getSubscriptionManagementUrl().then((data) => {
			if (data?.billingPortalUrl) {
				setManageSubscriptionUrl(data.billingPortalUrl);
			}
		});
	}, [user]);

	if (!user || user.isAnon) {
		return null;
	}

	return (
		<div className={styles.profile} id="profile" onClick={handleOpen}>
			<img
				className={styles.userIcon}
				src={user.avatar || userImg}
				alt="user icon"
			/>
			{isOpened && (
				<div className={styles.popover}>
					{user.isPaid && manageSubscriptionUrl && (
						<Link to={manageSubscriptionUrl} className={styles.row}>
							<img className={styles.icon} src={manageImg} alt="subscription" />
							<p className={styles.text}>Manage subscription</p>
						</Link>
					)}
					{/* <div className={styles.row} onClick={handleDeleteClick}>
						<img className={styles.icon} src={deleteImg} alt="delete" />
						<p className={`${styles.text} ${styles.deleteText}`}>Delete user</p>
					</div> */}
					<div className={styles.row} onClick={handleProfileClick}>
						<img className={styles.icon} src={userImg} alt="profile" />
						<p className={styles.text}>Profile</p>
					</div>
					<div className={styles.row} onClick={handleLogoutClick}>
						<img className={styles.icon} src={logoutImg} alt="logout" />
						<p className={styles.text}>Logout</p>
					</div>
				</div>
			)}
		</div>
	);
};
